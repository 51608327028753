import React from 'react'
import { Layout } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'

import UserMenu from '../UserMenu'

import { getAppDataFromStorage } from 'utils/app'

import type { IAuthenticatedHeaderMenuProps } from './types'

const { Header } = Layout

function AuthenticatedHeaderMenu({
  collapsed,
  toggle,
  isInnerOrganization,
  roleName,
  organizationName,
}: IAuthenticatedHeaderMenuProps): JSX.Element {
  return (
    <Header
      className="authenticated-header"
      style={{ position: 'fixed', zIndex: 1, width: `calc(100% - ${collapsed ? 80 : 200}px)` }}
    >
      <div className="authenticated-header-menu-container">
        <div className="left-component">
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })}
          {renderHeaderName()}
        </div>
        <div className="right-component">
          <UserMenu />
        </div>
      </div>
    </Header>
  )

  function renderHeaderName() {
    let name
    let role

    if (isInnerOrganization) {
      role = roleName
      name = organizationName
    } else {
      name = getAppDataFromStorage()?.app.name
    }

    return (
      <div className="organization-name line-height-20">
        <strong>{name}</strong>
        <div className="long-text gray-color">{role && `${role}`}</div>
      </div>
    )
  }
}

export default AuthenticatedHeaderMenu
