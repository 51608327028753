import type { FC } from 'react'

import { useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { message } from 'antd'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import ContentCard from 'components/Card/Content'
import NewOrganizationForm from 'components/Form/Organization/NewOrganization'
import PageTitle from 'components/PageTitle'
import FullWidthSpace from 'components/FullWidthSpace'

import { CREATE_ORGANIZATION } from 'graphQL/schemas/organization'
import useUpdateOrgStatus from 'graphQL/useUpdateOrgStatus'

import { onDefaultErrorMessage } from 'utils/apollo'
import { checkPermissionCanEditData } from 'utils/permission'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import usePermission from 'hooks/usePermission'

import { paths } from 'setup/PageRouter'

const CreateOrganizationPage: FC = () => {
  const { t } = useTranslation()

  const [orgKey, setOrgKey] = useState('')

  const [attachFiles, setAttachFiles] = useState<any[]>([])

  const permissionOrgManagement = usePermission({
    permissionKey: 'ORG_MANAGEMENT',
    code: ['READ', 'EDIT'],
  })

  const isPermissionOrgManagement = checkPermissionCanEditData({
    code: 'EDIT',
    permissionKey: 'ORG_MANAGEMENT',
    permissionsList: permissionOrgManagement?.myPermissions,
  })

  const [updateOrganization, updateOrganizationResp] = useUpdateOrgStatus({
    onCompleted() {
      navigate(
        generatePath(paths.organizationDetail, {
          orgKey,
          slug: 'organization-setting',
        })
      )
    },
  })

  const [createOrganization, createOrganizationResp] = useMutation(CREATE_ORGANIZATION, {
    onCompleted(resp) {
      const key = resp.createOrganization.payload.orgKey

      message.success('Organization created.')

      setOrgKey(key)

      updateOrganization({
        variables: {
          orgKey: key,
          status: 'REVIEWING',
        },
      })
    },
    onError(error) {
      onDefaultErrorMessage(error)
    },
  })

  const navigate = useNavigate()

  return (
    <AuthenticatedLayout>
      <div className="admin-content-container">
        <PageTitle title={t('organization:new.NewOrganization')} onBack={() => navigate(-1)} />
        {isPermissionOrgManagement > 0 ? (
          <ContentCard title={t('organization:new.createNewOrganization')}>
            <NewOrganizationForm
              loading={createOrganizationResp.loading || updateOrganizationResp.loading}
              onFinish={(values) => {
                createOrganization({
                  variables: {
                    input: values,
                  },
                })
              }}
              attachFiles={attachFiles}
              setAttachFiles={setAttachFiles}
            />
          </ContentCard>
        ) : (
          <FullWidthSpace style={{ justifyContent: 'center' }}>
            <h1>Permission Required!</h1>
          </FullWidthSpace>
        )}
      </div>
    </AuthenticatedLayout>
  )
}

export default CreateOrganizationPage
