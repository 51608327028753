import { gql } from '@apollo/client'

const UPDATE_ORG_STATUS = gql`
  mutation updateOrgStatus($orgKey: String!, $status: ENUM_ORGANIZATION_STATUS!) {
    updateOrgStatus(orgKey: $orgKey, status: $status) {
      message
      code
      payload {
        orgKey
      }
    }
  }
`

export default UPDATE_ORG_STATUS
