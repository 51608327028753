import React, { useContext, useState } from 'react'

import type { GetMyPermissionData } from 'graphQL/queryResponseTypes'

interface PermissionContextData {
  myPermissions?: GetMyPermissionData[]
  setPermissions: (permissions: GetMyPermissionData[]) => void
}

const defaultPermissionContext: PermissionContextData = {
  setPermissions() {},
}

const PermissionContext = React.createContext<PermissionContextData>(defaultPermissionContext)

const PermissionProvider: React.FC = ({ children }) => {
  const [myPermissions, setMyPermissions] = useState<GetMyPermissionData[]>()

  return (
    <PermissionContext.Provider
      value={{
        myPermissions,
        setPermissions: setMyPermissions,
      }}
    >
      {children}
    </PermissionContext.Provider>
  )
}

export default PermissionProvider

export const usePermissionContext = () => useContext(PermissionContext)
