// import VerificationForm from 'components/Form/Verification'
import { Modal, Form } from 'antd'
import RoleFrom from 'components/Form/AppRole/RoleFrom'
import useCreateRole from 'graphQL/useCreateRole/useCreateRole'
import { IAddRoleProps } from './types'

function AddAppRole({ isShowDialog, onCancel, refetchRole }: IAddRoleProps): JSX.Element {
  const [form] = Form.useForm()
  const [createRole] = useCreateRole({
    onCompleted() {
      refetchRole()
      onCancel()
      form.resetFields()
    },
  })

  const onFinish = (values: any) => {
    createRole({
      variables: {
        roleInput: {
          name: values.name,
          roleKey: values.roleKey,
          roleType: values.roleType,
          status: values.status,
          regisable: values.regisable,
          isDefault: values.isDefault,
          // needApproval: values.name,
        },
      },
    })
  }

  return (
    <Modal title="Add Role" visible={isShowDialog} onOk={() => null} onCancel={onCancel} footer={false} width={700}>
      <RoleFrom onFinish={onFinish} form={form} />
    </Modal>
  )
}

export default AddAppRole
