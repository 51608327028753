import { gql } from '@apollo/client'
import { appQuery, serviceQuery } from 'graphQL/query'

const APP_SERVICE = gql`
  query {
    getAppService {
      message
      code
      payload {
        app {
          ${appQuery}
        }
        serviceList {
          ${serviceQuery}
        }
      }
    }
  }
`

export default APP_SERVICE
