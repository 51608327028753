import { useState } from 'react'

import { defaultPagination } from 'graphQL/config'

import type { TablePaginationConfig } from 'antd'
import type { PaginationFromAPI } from 'graphQL/types'

interface AntdTablePaginationResponse {
  pagination: PaginationFromAPI
  createAntdTablePagination: (
    paginationFromAPI?: PaginationFromAPI,
    paginationOptions?: TablePaginationConfig
  ) => TablePaginationConfig
  onChange: (antdPagination: TablePaginationConfig) => void
  changeToFirstPage: VoidFunction
}

const useAntdTablePagination = (): AntdTablePaginationResponse => {
  const [pagination, setPagination] = useState<PaginationFromAPI>(defaultPagination)

  const createAntdTablePagination = (
    paginationFromAPI?: PaginationFromAPI,
    paginationOptions?: TablePaginationConfig
  ): TablePaginationConfig => {
    const current = paginationFromAPI?.page || pagination.page
    const pageSize = paginationFromAPI?.limit || pagination.limit

    return {
      showSizeChanger: false,
      hideOnSinglePage: true,
      ...paginationOptions,
      current,
      pageSize,
      total: paginationFromAPI?.totalItems,
    }
  }

  const changeToFirstPage = () => {
    setPagination({
      ...pagination,
      page: 1,
    })
  }

  const onChange = (antdPagination: TablePaginationConfig) => {
    const limit = antdPagination.pageSize || pagination.limit
    const page = antdPagination.current || pagination.page

    setPagination({
      ...pagination,
      limit,
      page,
    })
  }

  return {
    pagination,
    changeToFirstPage,
    createAntdTablePagination,
    onChange,
  }
}

export default useAntdTablePagination
