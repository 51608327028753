import { useQuery } from '@apollo/client'

import { onDefaultErrorMessage } from 'utils/apollo'

import QUERY_DOCUMENT from './getInviteRole'

import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { InviteRoleAPIPayload } from './interface'

interface InviteRoleData {
  getInviteRole: APIPayloadResponse<InviteRoleAPIPayload>
}

interface InviteRoleVars {
  orgKey: string
}

const useGetInviteRole: GraphQLServiceQueryHook<InviteRoleData, InviteRoleVars> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetInviteRole
