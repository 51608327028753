import type { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import SystemAdminLayout from 'layouts/SystemAdminLayout'

import PageTitle from 'components/PageTitle'
import ContentCard from 'components/Card/Content'
import { NewServiceForm } from 'components/Form/Service'

const CreateSystemAdminServicePage: FC = () => {
  const navigate = useNavigate()

  return (
    <SystemAdminLayout>
      <div className="admin-content-container">
        <>
          <PageTitle title="New Service" onBack={() => navigate(-1)} />
          <ContentCard title="New Service">
            <NewServiceForm onFinish={(values) => console.log(values)} />
          </ContentCard>
        </>
      </div>
    </SystemAdminLayout>
  )
}

export default CreateSystemAdminServicePage
