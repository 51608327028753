import { Table } from 'antd'

import type { ITableProps } from '../types'
import type { IUserFieldData } from './types'

function UserField({ columns, dataSource, rowKey }: ITableProps<IUserFieldData>): JSX.Element {
  return (
    <Table
      className="organization-table"
      dataSource={dataSource}
      columns={columns}
      rowKey={rowKey}
      pagination={false}
    />
  )
}

export default UserField
