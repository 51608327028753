import { useNavigate } from 'react-router-dom'
import { Space, Empty, Button, Table } from 'antd'
import { ColumnType } from 'antd/lib/table'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

import TableColumnFormat from 'components/Table/ColumnFormat'
import Status from 'components/Status'

import { paths } from 'setup/PageRouter'

import FontAwesomeIcon from 'components/FontawesomeIcon'

import { routeTo } from 'helpers/utils'

import type { IOrganizationData } from 'components/Table/Organization'
import type { OrgProps, OrgFieldobj, Field, OrgColumn, organizationsobj } from './types'

function OrganizationDashboard(props: OrgProps): JSX.Element {
  const { organizations, orgField } = props
  const { t } = useTranslation()

  const navigate = useNavigate()

  function goToManageOrganization(organizationKey: string) {
    navigate(
      routeTo(paths.organizationDetail, {
        params: {
          orgKey: organizationKey,
          slug: 'organization-setting',
        },
      }),
      {
        state: {
          backPath: paths.organization,
        },
      }
    )
  }

  if (!organizations) return <div />

  if (organizations?.length === 0) {
    return <Empty description="No Organization" />
  }

  if (!orgField) return <div />

  const columnsList: OrgColumn[] = []
  orgField.forEach((element: OrgFieldobj) => {
    element.fieldList.forEach((elementField: Field) => {
      const temcolumns: OrgColumn = {
        fieldKey: elementField.fieldKey,
        title: elementField.title,
        description: elementField.description,
        showInList: elementField.showInList,
        order: elementField.order,
      }
      const iscolumns = columnsList.find(
        (column: OrgColumn) =>
          column.fieldKey === temcolumns.fieldKey &&
          column.title === temcolumns.title &&
          column.description === temcolumns.description &&
          column.showInList === temcolumns.showInList &&
          column.order === temcolumns.order
      )
      if (!iscolumns) {
        columnsList.push(temcolumns)
      }
    })
  })

  columnsList.sort((a: OrgColumn, b: OrgColumn) => a.order - b.order)

  const columns: ColumnType<IOrganizationData>[] = []

  const iscolumnsName = columnsList.find((column: OrgColumn) => column.fieldKey === 'PROPERTY_NAME')
  if (!iscolumnsName) {
    columns.push({
      dataIndex: 'PROPERTY_NAME',
      title: () => (
        <TableColumnFormat
          title={t('organization:table.dashboard.name.title')}
          subtitle={t('organization:table.dashboard.name.subtitle')}
        />
      ),
      key: 'PROPERTY_NAME',
      render: (_text, record) => (
        <Space size={12} key="PROPERTY_NAME_attriName">
          {record.name}
        </Space>
      ),
    })
  }

  columnsList.forEach((element: OrgColumn) => {
    columns.push({
      title: () => <TableColumnFormat title={element.title} subtitle={element.description} />,
      dataIndex: element.fieldKey,
      key: element.fieldKey,
      render: (_text) => {
        if (_text) {
          return (
            <Space size={12} key={`${element.fieldKey}_attriName`}>
              {parse(_text)}
            </Space>
          )
        }

        return (
          <Space size={12} key={`${element.fieldKey}_attriName`}>
            -
          </Space>
        )
      },
    })
  })
  columns.push({
    title: () => (
      <TableColumnFormat
        title={t('organization:table.dashboard.status.title')}
        subtitle={t('organization:table.dashboard.status.subtitle')}
      />
    ),
    key: 'status',
    render: (_status, record) => (
      <Space size={12}>
        <Status key="status" status={record.status} />
      </Space>
    ),
  })
  columns.push({
    dataIndex: 'action',
    key: 'action',
    render: (_text, record) => (
      <Button
        type="ghost"
        onClick={() => goToManageOrganization(record.orgKey)}
        icon={<FontAwesomeIcon iconName="chevron-right" style={{ marginRight: '1rem' }} />}
      >
        {t('organization:table.action.goToOrganization')}
      </Button>
    ),
  })
  const dataSource: any = []

  organizations.forEach((org: organizationsobj, index: number) => {
    const temOrgData: any = {
      key: index,
      name: org.name,
      orgKey: org.orgKey,
      status: org.status,
    }
    const orgFieldobj: any = orgField.find((element: OrgFieldobj) => element.orgKey === org.orgKey)
    if (orgFieldobj) {
      orgFieldobj.fieldList.forEach((elementField: Field) => {
        temOrgData[elementField.fieldKey] = elementField.content
      })
    }

    dataSource.push(temOrgData)
  })

  return (
    <Table
      className="organization-table"
      dataSource={dataSource}
      columns={columns}
      rowKey="orgKey"
      pagination={false}
    />
  )
}

export default OrganizationDashboard
