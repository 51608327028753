import { useMutation } from '@apollo/client'

import { onDefaultErrorMessage } from 'utils/apollo'

import MUTATION_DOCUMENT from './deleteInvite'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'

interface DeleteInviteData {
  deleteInvite: APIPayloadResponse<{ _id: string }>
}

interface DeleteInviteVars {
  inviteId: string
  orgKey: string
}

const useDeleteInvite: GraphQLServiceMutationHook<DeleteInviteData, DeleteInviteVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useDeleteInvite
