import { useMutation } from '@apollo/client'

import MUTATION_DOCUMENT from './getTokenAuthCode'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import type { TokenAuthCodeAPIPayload } from './interface'

interface TokenAuthCodeData {
  getTokenAuthCode: APIPayloadResponse<TokenAuthCodeAPIPayload>
}

interface TokenAuthCodeVars {
  code: string
}

const useGetTokenAuthCode: GraphQLServiceMutationHook<TokenAuthCodeData, TokenAuthCodeVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    ...options,
  })
}

export default useGetTokenAuthCode
