import type { FC } from 'react'

import { useTranslation } from 'react-i18next'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import PageTitle from 'components/PageTitle'
import { NotificationList } from 'components/Notification'

const NotificationPage: FC = () => {
  const { t } = useTranslation()

  return (
    <AuthenticatedLayout>
      <div className="admin-content-container">
        <PageTitle title={t('notification:title')} />

        <NotificationList />
      </div>
    </AuthenticatedLayout>
  )
}

export default NotificationPage
