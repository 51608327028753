import { gql } from '@apollo/client'

const GET_LOG = gql`
  query getLog($input: INPUT_FIND_DATA) {
    getLog(input: $input) {
      pagination {
        limit
        page
        totalItems
        totalPages
      }
      message
      code
      payload {
        action
        eventKey
        eventName
        nextData
        createdAt
        updatedAt
      }
    }
  }
`

export default GET_LOG
