import { gql } from '@apollo/client'
import { ROLE } from 'graphQL/schemas/role'

const GET_ROLE = gql`
  query {
    getRole {
      message
      code
      payload {${ROLE}}
    }
  }
`

export default GET_ROLE
