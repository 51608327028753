import type { FC } from 'react'

import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button, Space } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import ContentCard from 'components/Card/Content'
import PageTitle from 'components/PageTitle'
import SearchInput from 'components/Input/Search'
import AdministratorDashboard from 'components/Dashboard/SystemAdmin/SystemAdministrator'

import SystemAdminApplicationPage from './SystemAdminApplicationPage'
import SystemAdminConfigurationPage from './SystemAdminConfigurationPage'
import SystemAdminServicePage from './SystemAdminServicePage'
import SystemAdminDashboardPage from './SystemAdminDashboardPage'

import SystemAdminLayout from 'layouts/SystemAdminLayout'

import { paths } from 'setup/PageRouter'
import { useTranslation } from 'react-i18next'

type PageKey = 'dashboard' | 'application' | 'service' | 'administrator' | 'configuration'
type SystemAdminParamsPage = {
  readonly page: PageKey
}

const SystemAdminPage: FC = () => {
  const params = useParams<SystemAdminParamsPage>()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [searchVal, setSearchVal] = useState('')

  function goToCreateNewService() {
    navigate(paths.systemAdminServiceCreate)
  }
  function goToNewAdministrator() {
    navigate(paths.systemAdminAdministratorCreate)
  }

  function goToNewAppplication() {
    navigate(paths.systemAdminApplicationCreate)
  }

  function onServiceSearch(searchValue: string) {
    setSearchVal(searchValue)
    console.log(searchVal)
  }

  function renderPanelByPageKey(pageKey: PageKey) {
    switch (pageKey) {
      case 'administrator':
        return (
          <>
            <PageTitle
              title={t('administrator:titleAll')}
              extra={[
                <Button type="primary" key="create" onClick={goToNewAdministrator}>
                  <Space size={12}>
                    <PlusCircleOutlined /> {t('administrator:newAdmin')}
                  </Space>
                </Button>,
              ]}
            />
            <ContentCard title={t('administrator:titleAll')}>
              <AdministratorDashboard />
            </ContentCard>
          </>
        )

      case 'application':
        return (
          <>
            <PageTitle
              title="Application"
              extra={[
                <Button type="primary" key="create" onClick={goToNewAppplication}>
                  <Space size={12}>
                    <PlusCircleOutlined /> New Application
                  </Space>
                </Button>,
              ]}
            />
            <ContentCard
              title="Application"
              rightComponent={<SearchInput onSearch={onServiceSearch} placeholder="Search" />}
            >
              <SystemAdminApplicationPage />
            </ContentCard>
          </>
        )
      case 'configuration':
        return (
          <>
            <PageTitle title="Configuration" />
            <ContentCard title="Configuration">
              <SystemAdminConfigurationPage />
            </ContentCard>
          </>
        )
      case 'service':
        return (
          <>
            <PageTitle
              title="Service"
              extra={[
                <Button type="primary" key="create" onClick={goToCreateNewService}>
                  <Space size={12}>
                    <PlusCircleOutlined /> New Service
                  </Space>
                </Button>,
              ]}
            />
            <ContentCard
              title="Service"
              rightComponent={<SearchInput onSearch={onServiceSearch} placeholder="Search" />}
            >
              <SystemAdminServicePage />
            </ContentCard>
          </>
        )
      default:
      case 'dashboard':
        return (
          <>
            <PageTitle title="Dashboard" />
            <ContentCard title="Dashboard">
              <SystemAdminDashboardPage />
            </ContentCard>
          </>
        )
    }
  }

  return (
    <SystemAdminLayout>
      <div className="admin-content-container">{renderPanelByPageKey(params?.page || 'administrator')}</div>
    </SystemAdminLayout>
  )
}

export default SystemAdminPage
