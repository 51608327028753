import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Col, List, Row, Typography } from 'antd'
import dayjs from 'dayjs'
import { generatePath, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import FullWidthSpace from 'components/FullWidthSpace'
import NotificationIcon from './NotificationIcon'

import { paths } from 'setup/PageRouter'

import { useReadNotificationMutation } from 'graphQL/useReadNotification'

import type { MyNotificationAPIPayload } from 'graphQL/useGetMyNotification/interface'
import type { NotificationListProps } from './interface'

type notificationStatus = 'YES' | 'NO' | string

const NotificationDropdownList: React.FC<NotificationListProps> = ({ data }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [readNotification] = useReadNotificationMutation({
    fetchPolicy: 'network-only',
    onCompleted(resp) {
      const data = resp.readNotification
      navigate(
        generatePath(paths.notificationDetail, {
          id: data.payload[0]._id,
        })
      )
    },
  })
  const handleReadNotificaiton = (notification: MyNotificationAPIPayload) => {
    if (notification.read === 'NO') {
      readNotification({
        variables: {
          notificationIdList: [notification._id],
        },
      })
    } else {
      navigate(
        generatePath(paths.notificationDetail, {
          id: notification._id,
        })
      )
    }
  }

  const readStatus = (notification: MyNotificationAPIPayload): string => {
    let textDate = dayjs(notification.updatedAt).fromNow()
    textDate = notification.read === 'YES' ? t('notification:read') + ' ' + textDate : textDate
    return textDate
  }

  return (
    <Row gutter={32}>
      <Col span={24}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <ListItemStyle read={item.read} key={item._id} onClick={() => handleReadNotificaiton(item)}>
              <List.Item.Meta
                avatar={
                  <NotificationIcon
                    type={item.attribute?.icon?.type || 'info'}
                    iconName={item.attribute?.icon?.iconName || 'info-circle'}
                  />
                }
                title={
                  <Typography.Text strong type={item.read === 'YES' ? 'secondary' : undefined} ellipsis>
                    {item.title}
                  </Typography.Text>
                }
                description={
                  <FullWidthSpace direction="vertical">
                    <Typography.Paragraph type={item.read === 'YES' ? 'secondary' : undefined} ellipsis={{ rows: 2 }}>
                      {/* {item.content} */}
                      <div dangerouslySetInnerHTML={{ __html: item.content }} />
                    </Typography.Paragraph>

                    <DateTimeTitle>{readStatus(item)}</DateTimeTitle>
                  </FullWidthSpace>
                }
              />
              {item.read === 'NO' && <Badge color="blue" />}
            </ListItemStyle>
          )}
        />
      </Col>
    </Row>
  )
}

export default NotificationDropdownList

const DateTimeTitle = styled.span`
  color: #2699fb;
`
const ListItemStyle = styled(List.Item)<{ read?: notificationStatus }>`
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  &:hover {
    box-shadow: 0 0 4px #c1b7b7;
  }
`
