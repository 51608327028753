import type { FC } from 'react'

import { useContext } from 'react'
import { Layout } from 'antd'

import { OrganizationSidebar } from 'components/Sidebar/Organization'
import AuthenticatedHeaderMenu from 'components/HeaderMenu/AuthenticatedHeaderMenu'

import { ThemeContext } from 'contexts/ThemeContext'

const { Sider, Content } = Layout

const AuthenticatedLayout: FC = ({ children }) => {
  const theme = useContext(ThemeContext)

  function toggle() {
    theme.collapseHandler(!theme.collapsed)
  }

  function getNavLogo() {
    if (theme.collapsed && theme.admin.image?.mainNavLogoCollapsed) {
      return <img className="logo" alt={theme.admin.text?.webTitle} src={theme.admin.image.mainNavLogoCollapsed} />
    }

    if (!theme.collapsed && theme.admin.image?.navAuthLogo) {
      return <img className="logo" alt={theme.admin.text?.webTitle} src={theme.admin.image.navAuthLogo} />
    }

    return (
      <div
        style={{
          padding: 10,
          height: 64,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {theme.admin.text?.navTitle}
      </div>
    )
  }
  return (
    <Layout style={{ minHeight: '100vh' }} className="authenticated-layout">
      <Sider
        trigger={null}
        collapsible
        collapsed={theme.collapsed}
        theme="light"
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
      >
        {getNavLogo()}

        <OrganizationSidebar />
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: theme.collapsed ? 80 : 200 }}>
        <AuthenticatedHeaderMenu collapsed={theme.collapsed} toggle={toggle} />

        <Content style={{ marginTop: 64 }}>
          <div className="authenticated-content-container">{children}</div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default AuthenticatedLayout
