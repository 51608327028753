import type { FC } from 'react'

import { Menu, Spin } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useQueryParams from 'hooks/useQueryParams'
import useQueryString from 'hooks/useQueryString'
import useServiceMenuQuery from 'hooks/useServiceMenuQuery'

import System from './System'
import { ServiceOrganizationSidebar } from '.'

import { getDefaultServiceKeyWithOtherQueryString, routeTo } from 'helpers/utils'
import { sortMenu } from 'utils/sortMenu'

import type { IInnerOrganizationSidebar } from '../types'
import type { AppServiceMenu } from 'hooks/useServiceMenuQuery/types'

const InnerOrganization: FC<IInnerOrganizationSidebar> = ({ isCollapsed, orgKey }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const location = useLocation() as any

  const query = useQueryString()

  const { loading, organizationMenuList = [] } = useServiceMenuQuery({
    orgKey,
  })
  const { state } = location

  const serviceKey = useQueryParams('serviceKey')

  if ((!serviceKey || serviceKey === 'undefined') && organizationMenuList.length > 0) {
    navigate(
      routeTo(window.location.pathname, {
        query: getDefaultServiceKeyWithOtherQueryString(query, organizationMenuList),
      })
    )
  }

  const findServiceMenuByPathname = organizationMenuList
    .find((service) => service?.service?.serviceKey === serviceKey)
    ?.menuList.find((menu) => window.location.pathname === menu.linkTo)

  const currentRoute = findServiceMenuByPathname?.key || ''

  const allMenuLength = getTotalMenuLengthWithSubMenuList(organizationMenuList)

  return (
    <Menu
      theme="light"
      className="organization-menu-container"
      mode="inline"
      defaultSelectedKeys={['0']}
      selectedKeys={[currentRoute]}
    >
      {!isCollapsed && (
        <Menu.Item key="back-to-organize">
          <LeftOutlined className="primary-color" />
          <span className="primary-color font-medium">{t('button:BacktoMainPage')}</span>
          <Link to={{ pathname: state?.backPath || '/' }} />
        </Menu.Item>
      )}

      {loading ? (
        <Menu.Item icon={<Spin />} key="loading-organize-menu">
          <small className="primary-color">{t('loading:menu')}</small>
        </Menu.Item>
      ) : (
        sortMenu(organizationMenuList).map(({ service, menuList }) => {
          const serviceKey = service.serviceKey

          return (
            <ServiceOrganizationSidebar
              key={serviceKey}
              menus={menuList}
              service={service}
              serviceKey={serviceKey}
              currentRoute={currentRoute}
            />
          )
        })
      )}

      <System allMenuLength={allMenuLength} orgKey={orgKey} />
    </Menu>
  )
}

export default InnerOrganization

function getTotalMenuLengthWithSubMenuList(serviceMenuList: AppServiceMenu[]) {
  let temporaryTotalOrganizationMenuItem = 0

  serviceMenuList.forEach((item) => {
    temporaryTotalOrganizationMenuItem += item.menuList.reduce((acc, cur) => {
      if (Array.isArray(cur.subMenus)) {
        return acc + cur.subMenus.length
      }
      return acc + 1
    }, 0)
  })

  return temporaryTotalOrganizationMenuItem
}
