import { useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useGetAppService from 'graphQL/useGetAppService'

import { mappedMenuList } from 'utils/menu'
import { appLocalAccessToken } from 'utils/localService'

import type {
  IUseServiceMenuQueryProps,
  AppServiceMenu,
  MenuPayload,
  MenuWithService,
  IMenuList,
  UseServiceMenuQueryOptions,
} from './types'
import type { ServiceHook } from 'hooks/interface'
import type { Service } from 'graphQL/graphQL-service-hook'

type OrganizationParams = {
  orgKey: string
}

let cacheOrganizationMenuList: AppServiceMenu[]
let cacheAppMenuList: AppServiceMenu[]

const useServiceMenuQuery: ServiceHook<IUseServiceMenuQueryProps, UseServiceMenuQueryOptions> = (options) => {
  const { i18n } = useTranslation()
  const locale = i18n.language === 'enUS' ? 'en' : 'th'

  const orgKey = useParams<OrganizationParams>().orgKey

  const [totalMenuLength, setTotalMenuLength] = useState(0)

  const [loading, setLoading] = useState(true)

  useGetAppService({
    fetchPolicy: 'no-cache',
    onCompleted(resp) {
      const listServices = resp.getAppService.payload.serviceList.filter(
        (item) => item.adminPanelMetaDataUrl != null && item.adminPanelEndpoint != null
      )

      onRequestServiceMetadata(listServices)
    },
  })

  const serviceQueryResponse = {
    loading,
    appMenuList: cacheAppMenuList,
    organizationMenuList: cacheOrganizationMenuList,
    allMenuLength: totalMenuLength,
  }

  function onRequestServiceMetadata(services: Service[]) {
    Promise.all(
      services.map(async (service) => {
        try {
          const fetchResult = await fetch(service.adminPanelMetaDataUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          })

          const panelMetaDataResp = await fetchResult.json()

          return await axios({
            method: 'POST',
            url: service.endpoint.gql.resourceAdmin,
            data: {
              query: `
                  query getMyPermission($orgKey: String){
                    getMyPermission(orgKey: $orgKey) {
                      payload {
                        permissionKey
                        code
                        scopeList
                      }
                    }
                  }
                `,
              variables: {
                orgKey,
              },
            },
            headers: {
              authorization: appLocalAccessToken.get(),
            },
          })
            .then((permission) => {
              const { data, error } = permission.data

              const userPermissions = error == null ? data?.getMyPermission.payload : []

              return {
                service,
                menu: panelMetaDataResp,
                userPermissions,
              }
            })
            .catch(() => ({
              service,
              menu: panelMetaDataResp,
              userPermissions: [],
            }))
        } catch {}
      })
    ).then((groupAppServiceMenuQuery) => {
      const filteredOutServiceDown = groupAppServiceMenuQuery.filter(Boolean)

      let totalOrganizationMenuItem = 0

      const appMenuList: MenuWithService[] = filteredOutServiceDown.map((appServiceQuery) => {
        const menu = appServiceQuery?.menu.app.menuList || []
        totalOrganizationMenuItem += getTotalMenuLengthWithSubMenuList(menu)

        return {
          service: appServiceQuery?.service as Service,
          menu: appServiceQuery?.menu.app as MenuPayload,
          permissions: appServiceQuery?.userPermissions || [],
        }
      })
      const organizationMenuList: MenuWithService[] = filteredOutServiceDown.map((appServiceQuery) => ({
        service: appServiceQuery?.service as Service,
        menu: appServiceQuery?.menu.organization as MenuPayload,
        permissions: appServiceQuery?.userPermissions || [],
      }))

      const mappedAppMenuList = mappedMenuList(appMenuList, {
        locale,
        type: 'APP',
      })
      const mappedOrganizationMenuList = mappedMenuList(organizationMenuList, {
        locale,
        orgKey: options?.orgKey,
        type: 'ORG',
      })

      cacheOrganizationMenuList = mappedOrganizationMenuList
      cacheAppMenuList = mappedAppMenuList
      options?.onCompleted?.(mappedAppMenuList)

      setTotalMenuLength(totalOrganizationMenuItem)
      setLoading(false)
    })
  }

  return serviceQueryResponse
}

export default useServiceMenuQuery

function getTotalMenuLengthWithSubMenuList(menuList: IMenuList[]) {
  const temporaryTotalOrganizationMenuItem = menuList.reduce((prev, cur) => {
    if (Array.isArray(cur.subMenuList)) {
      return prev + cur.subMenuList.length
    }

    return prev + 1
  }, 0)

  return temporaryTotalOrganizationMenuItem
}
