import type { PaginationFromAPI } from 'graphQL/types'

import { fallbackString } from 'helpers/formatter'

export const defaultPagination: PaginationFromAPI = {
  limit: 10,
  page: 1,
}

const API = {
  CORE: {
    HOST: process.env.REACT_APP_API_CORE_HOST || 'http://localhost:5000',
    HOST_WSS: process.env.REACT_APP_API_CORE_HOST_WSS || 'http://localhost:5000',
    PATH: {
      SYSTEM_ADMIN: process.env.REACT_APP_API_CORE_PATH_SYSTEM_ADMIN || '/graphql/system-admin/v1.0',
      ADMIN: process.env.REACT_APP_API_CORE_PATH_ADMIN || '/graphql/admin/v1.0',
      CLIENT: process.env.REACT_APP_API_CORE_PATH_CLIENT || '/graphql/client/v1.0',
      SUBSCRIPTION: process.env.REACT_APP_API_CORE_PATH_SUBSCRIPTION || '/graphql/subscription',
    },
  },
}

const api = {
  CORE: {
    SYSTEM_ADMIN: API.CORE.HOST + API.CORE.PATH.SYSTEM_ADMIN,
    ADMIN: API.CORE.HOST + API.CORE.PATH.ADMIN,
    CLIENT: API.CORE.HOST + API.CORE.PATH.CLIENT,
    SUBSCRIPTION: API.CORE.HOST_WSS + API.CORE.PATH.SUBSCRIPTION,
  },
}

type APIServiceKey = 'core'

interface APIEndpointByUserRole {
  systemAdmin: string
  admin: string
  client: string
  subscription: string
}

type APIPath = {
  [key in APIServiceKey]: APIEndpointByUserRole
}

export const apiPathV2: APIPath = {
  core: {
    systemAdmin: fallbackString(process.env.REACT_APP_API_CORE_PATH_SYSTEM_ADMIN),
    admin: fallbackString(process.env.REACT_APP_API_CORE_ADMIN),
    client: fallbackString(process.env.REACT_APP_API_CORE_CLIENT),
    subscription: fallbackString(process.env.REACT_APP_API_CORE_SUBSCRIPTION),
  },
}

export default api
