import { useQuery } from '@apollo/client'
import { onDefaultErrorMessage } from 'utils/apollo'

import QUERY_DOCUMENT from './getAppService'

import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { AppServiceAPIPayload } from './interface'

interface AppServiceData {
  getAppService: APIPayloadResponse<AppServiceAPIPayload>
}

const useGetAppService: GraphQLServiceQueryHook<AppServiceData> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetAppService
