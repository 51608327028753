import { Card } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface ContentBlockComponent {
  Title: typeof Title
}

interface TitleProps {
  align?: 'left' | 'center' | 'right'
}

const ContentBlock: React.FC & ContentBlockComponent = ({ children }) => {
  return <Card>{children}</Card>
}

export default ContentBlock

const Title = styled.span<TitleProps>`
  display: block;
  font-size: 24px;
  font-weight: 700;
  text-align: ${(p) => p.align || 'left'};
  margin-bottom: 24px;
`

ContentBlock.Title = Title
