import UPDATE_DATA_SECURITY_ROLE from './updateDataSecurity'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import { useMutation } from '@apollo/client'

interface UpdateDataSecurityData {
  updateDataSecurity: APIPayloadResponse<{ role: { name: string } }>
}
export interface DataSecurity {
  name: string
  securityKey: string
  create: string
  read: string
  write: string
  delete: string
  assign: string
  assignToParent: string
}
interface UpdateDataSecurityVars {
  roleKey: string
  securityList: DataSecurity[]
}

const useUpdateDataSecurityRole: GraphQLServiceMutationHook<UpdateDataSecurityData, UpdateDataSecurityVars> = (
  options
) => {
  return useMutation(UPDATE_DATA_SECURITY_ROLE, {
    ...options,
  })
}

export default useUpdateDataSecurityRole
