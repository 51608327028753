import { useQuery } from '@apollo/client'
import API from 'constants/api'
import { Type_Profile_List } from 'graphQL/generated/operations'
import type { FindDataInput, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import { onDefaultErrorMessage } from 'utils/apollo'
import QUERY_DOCUMENT from './getSystemAdminProfile'

interface SystemAdminData {
  getSystemAdminProfile: Type_Profile_List
}

interface SystemAdminVars {
  input?: FindDataInput
}

const useGetSystemAdminProfile: GraphQLServiceQueryHook<SystemAdminData, SystemAdminVars> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    context: {
      uri: API.CORE.SYSTEM_ADMIN,
    },
    ...options,
  })
}

export default useGetSystemAdminProfile
