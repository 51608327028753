import { useState } from 'react'
import { Button, Space, Menu, Dropdown } from 'antd'
import { PlusCircleOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useThemeContext } from 'contexts/ThemeContext'

import useGetRole from 'graphQL/useGetRole/useGetRole'

import ContentCard from 'components/Card/Content'
import AppRoleTable from 'components/Table/AppRole'
import PageTitle from 'components/PageTitle'
import { AddAppRole } from 'components/Modal/AppRole'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import { paths } from 'setup/PageRouter'

import type { Role } from 'graphQL/schemaType/role'
import type { ColumnsType } from 'antd/lib/table'

function AppRolePage(): JSX.Element {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [isShowDialog, setIsShowDialog] = useState(false)

  const onCancelModel = () => {
    setIsShowDialog(false)
  }
  const theme = useThemeContext()
  const getRole = useGetRole({
    fetchPolicy: 'network-only',
  })

  const roleList = getRole.data?.getRole?.payload?.roleList
  const refetchRole = () => {
    getRole.refetch()
  }

  const menu = (role: Role) => (
    <Menu>
      <Menu.Item
        icon={<EditOutlined className="primary-color" />}
        onClick={() => {
          theme.collapseHandler(true)

          navigate(
            generatePath(paths.appRoleSettingPage, {
              page: 'role-setting',
            }) + `?role_key=${role.roleKey}`
          )
        }}
      >
        <span className="primary-color">{t('appRole:table:button:edit')}</span>
      </Menu.Item>
      <Menu.Item icon={<CloseCircleOutlined className="reviewing-color" />}>
        <span className="reviewing-color">{t('appRole:table:button:disable')}</span>
      </Menu.Item>
    </Menu>
  )
  const columns: ColumnsType<Role> = [
    {
      title: 'Role Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Role Key',
      dataIndex: 'roleKey',
      key: 'roleKey',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      dataIndex: '',
      render: (_text, rander) => {
        return (
          <Dropdown overlay={menu(rander)}>
            <Button shape="round" type="primary">
              Action
            </Button>
          </Dropdown>
        )
      },
    },
  ]
  return (
    <AuthenticatedLayout>
      <AddAppRole isShowDialog={isShowDialog} onCancel={onCancelModel} refetchRole={refetchRole} />

      <div className="admin-content-container">
        <PageTitle
          isRootPage
          title={t('appRole:page.home')}
          extra={[
            <Button
              type="primary"
              key="create"
              onClick={() => {
                setIsShowDialog(true)
              }}
            >
              <Space size={12}>
                <PlusCircleOutlined /> {t('appRole:create')}
              </Space>
            </Button>,
          ]}
        />
        <ContentCard title={``}>
          <AppRoleTable columns={columns} dataSource={roleList} rowKey={'appRole'} loading={getRole.loading} />
        </ContentCard>
      </div>
    </AuthenticatedLayout>
  )
}

export default AppRolePage
