import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import FontawesomeIcon from 'components/FontawesomeIcon'
import { ServiceOrganizationSidebar } from '.'

import useQueryParams from 'hooks/useQueryParams'
import usePermission from 'hooks/usePermission'
import useServiceMenuQuery from 'hooks/useServiceMenuQuery'
import useGetCustomMenu from 'graphQL/useGetCustomMenu/useGetCustomMenu'

import { sortMenu } from 'utils/sortMenu'
import { checkUserPermission } from 'utils/permission'

import type { IMenuListConfig, IRoute } from '../types'

const route: IRoute = {
  '/': '0',
  '/dashboard': '0',
  '/organization': '1',
  '/organization/create': '1',
  '/organization/setting': '1',
  '/app-role': '2',
  '/app-role/role-setting': '2',
  '/app-role/user-field': '2',
  '/app-role/security-role': '2',
  '/app-role/permission': '2',
  '/approval': '3',
  '/org-approval': '4',
  '/org-category': '5',
  '/org-category/create': '5',
  '/master-data': '6',
}

function Organization(): JSX.Element {
  const { t, i18n } = useTranslation()

  const location = useLocation() as any

  const customMenuQuery = useGetCustomMenu()

  const { appMenuList = [] } = useServiceMenuQuery()

  const serviceKey = useQueryParams('serviceKey')
  const permission = usePermission({
    permissionKey: 'ORG_MANAGEMENT',
    code: ['READ'],
  })

  const { pathname } = location

  const foundAppMenu = appMenuList.find((appMenu) => appMenu.service.serviceKey === serviceKey)
  const findAppByPathname = foundAppMenu?.menuList.find((appMenu) => pathname === appMenu.linkTo)

  const currentRoute = findAppByPathname != null ? findAppByPathname.key : route[pathname]

  const organizationMenuList: IMenuListConfig[] = [
    {
      key: route['/dashboard'],
      title: t('sidebar:dashboard'),
      linkTo: '/dashboard',
      iconName: 'chart-pie',
      permissionList: [
        {
          permissionKey: 'DASHBOARD_MANAGEMENT',
          code: ['READ'],
        },
      ],
    },
    {
      key: route['/organization'],
      title: t('sidebar:organization'),
      linkTo: '/organization',
      iconName: 'columns',
      permissionList: [
        {
          permissionKey: 'ORG_MANAGEMENT',
          code: ['READ'],
        },
      ],
    },
    {
      key: route['/app-role'],
      title: t('sidebar:appRole'),
      linkTo: '/app-role',
      iconName: 'users-cog',
      permissionList: [
        {
          permissionKey: 'ROLE_MANAGEMENT',
          code: ['READ'],
        },
      ],
    },
    {
      key: route['/org-approval'],
      title: t('sidebar:orgApproval'),
      linkTo: '/org-approval',
      iconName: 'columns',
      permissionList: [
        {
          permissionKey: 'ALL_ORG_MANAGEMENT',
          code: ['READ'],
        },
      ],
    },
    // {
    //   key: route['/org-category'],
    //   title: t('sidebar:orgCategory'),
    //   linkTo: '/org-category',
    //   iconName: 'layer-group',
    //   permissionList: [
    //     {
    //       permissionKey: 'CATEGORY_MANAGEMENT',
    //       code: ['READ'],
    //     },
    //   ],
    // },
  ]

  const fiteredOrganizationAppMenuList = filterOrganizationAppMenuListData()

  return (
    <Menu
      theme="light"
      className="organization-menu-container"
      mode="inline"
      defaultSelectedKeys={['0']}
      defaultOpenKeys={['approvalSubMenu']}
      selectedKeys={[currentRoute]}
    >
      {fiteredOrganizationAppMenuList
        .filter((item) => checkUserPermission(item.permissionList || [], permission.myPermissions))
        .map((menu) => {
          if (menu.subMenus && menu.subMenus.length > 0) {
            return (
              <Menu.SubMenu key={menu.subKey} icon={<FontawesomeIcon iconName={menu.iconName} />} title={menu.title}>
                {menu.subMenus.map((subMenu) => (
                  <Menu.Item key={subMenu.key}>
                    <span>{subMenu.title}</span>
                    <Link to={subMenu.linkTo} />
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            )
          }

          return (
            <Menu.Item key={menu.key} icon={<FontawesomeIcon iconName={menu.iconName} />}>
              <span>{menu.title}</span>
              <Link to={menu.linkTo} />
            </Menu.Item>
          )
        })}

      {renderCustomMenu()}

      {sortMenu(appMenuList).map((appMenu) => (
        <ServiceOrganizationSidebar
          key={appMenu.service.serviceKey}
          menus={appMenu.menuList}
          service={appMenu.service}
          serviceKey={appMenu.service.serviceKey}
          currentRoute={currentRoute}
        />
      ))}
    </Menu>
  )

  function filterOrganizationAppMenuListData() {
    let menuList = organizationMenuList

    // if (process.env.REACT_APP_SHOW_MASTER_DATA_APP_MENU === 'true') {
    //   menuList.push({
    //     key: route['/master-data'],
    //     title: t('sidebar:masterData'),
    //     linkTo: '/master-data',
    //     iconName: 'info',
    //   })
    // }

    return menuList
  }

  function renderCustomMenu() {
    if (!customMenuQuery.loading) {
      const dataCustomMenu: any = customMenuQuery.data?.getCustomMenu?.payload[0]
      if (dataCustomMenu) {
        return (
          <Menu.Item key={dataCustomMenu._id} icon={<FontawesomeIcon iconName={dataCustomMenu.icon} />}>
            <a href={dataCustomMenu.url} target={dataCustomMenu.target} rel="noreferrer">
              <span>{i18n.language === 'thTH' ? dataCustomMenu.title.th : dataCustomMenu.title.en}</span>
            </a>
          </Menu.Item>
        )
      }
    }
    return null
  }
}

export default Organization
