import { CSSProperties, useState } from 'react'
import axios from 'axios'
import sortBy from 'lodash/sortBy'

import useGetAppService from 'graphQL/useGetAppService'

import type { Service } from 'graphQL/graphQL-service-hook'
import type { MenuPermission } from 'components/Sidebar/types'
import type { ServiceHook } from './interface'
import { appLocalAccessToken } from 'utils/localService'
import { MyPermissionAPIPayload } from 'graphQL/useGetMyPermission'
import { checkUserPermission } from 'utils/permission'

interface WidgetDisplay {
  grid: number
  ratio: string
  style: CSSProperties | null
}

export interface Widget {
  name: {
    en: string
    th: string
  }
  path: string
  permissionList: MenuPermission[]
  display: WidgetDisplay
}

interface AppWidgetData {
  widgetOrder: number
  pattern: string
  widgetList: Widget[]
}

interface WidgetWithEndpointURL extends AppWidgetData {
  endpoint: string
  permissions: MyPermissionAPIPayload[]
}

interface PanelWidgetAPIPayload {
  app: AppWidgetData
}

interface WidgetPromiseResultResponse {
  endpoint: string
  widget: AppWidgetData
  userPermissions: MyPermissionAPIPayload[]
}

interface AppServiceWidgetResponse {
  loading: boolean
  listAppWidget: WidgetWithEndpointURL[]
}

const useAppServiceWidget: ServiceHook<AppServiceWidgetResponse> = () => {
  const [loading, setLoading] = useState(true)
  const [listAppWidget, setListAppWidget] = useState<WidgetWithEndpointURL[]>([])

  const appServiceQuery = useGetAppService({
    fetchPolicy: 'network-only',
    onCompleted(resp) {
      const appServiceList: Service[] = resp.getAppService.payload.serviceList

      Promise.all(
        appServiceList.map(async (service) => {
          if (service.adminWidgetMetaDataUrl) {
            const panelWidgetMetaData = await axios.get<PanelWidgetAPIPayload>(service.adminWidgetMetaDataUrl, {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            })

            let userPermissions: MyPermissionAPIPayload[] = []

            if (panelWidgetMetaData.status === 200) {
              try {
                const userPermissionsResp = await axios({
                  method: 'POST',
                  url: service.endpoint.gql.resourceAdmin,
                  data: {
                    query: `
                        {
                          getMyPermission {
                            payload {
                              permissionKey
                              code
                              scopeList
                            }
                          }
                        }
                      `,
                  },
                  headers: {
                    authorization: appLocalAccessToken.get(),
                  },
                })

                userPermissions = userPermissionsResp.data.data.getMyPermission.payload
              } catch (e) {
                console.log('Error', e)
              }

              return {
                endpoint: service.adminPanelEndpoint,
                widget: panelWidgetMetaData.data.app,
                userPermissions,
              }
            }
          }
        })
      ).then((resp) => {
        const filteredWidgetDataList: WidgetPromiseResultResponse[] = sortBy(
          resp.map((item) => item as WidgetPromiseResultResponse).filter(Boolean),
          'widget.widgetOrder'
        )

        const mappedEndpointToEachService: WidgetWithEndpointURL[] = filteredWidgetDataList.map((item) => ({
          ...item.widget,
          endpoint: item.endpoint,
          permissions: item.userPermissions,
        }))

        const filterAccessOnlyWidgetList = mappedEndpointToEachService.map((item) => ({
          ...item,
          widgetList: item.widgetList.filter((widget) => checkUserPermission(widget.permissionList, item.permissions)),
        }))

        console.log('Only access widget list only', filterAccessOnlyWidgetList)

        setListAppWidget(filterAccessOnlyWidgetList)
        setLoading(false)
      })
    },
  })

  return {
    loading: appServiceQuery.loading || loading,
    listAppWidget,
  }
}

export default useAppServiceWidget
