import { useEffect, useState } from 'react'
import { Dropdown, Select, Space, Menu, message } from 'antd'

import { IDataSecurityData } from 'components/Table/AppRole'
import DataSecurityTable from 'components/Table/AppRole/DataSecurity'
import { ColumnsType } from 'antd/lib/table'

import imgNone from 'assets/images/data_security/none.png'
import imgOrganization from 'assets/images/data_security/organization.png'
import imgParent from 'assets/images/data_security/parent.png'
import imgSystem from 'assets/images/data_security/system.png'
import imgUser from 'assets/images/data_security/user.png'
import useGetDataSecurityRole, { DataSecurityRoleAPIPayload } from 'graphQL/useGetDataSecurityRole/useDataSecurityRole'
import useUpdateDataSecurityRole from 'graphQL/useUpdateDataSecurity'

const Option = Select.Option
interface SecurityProps {
  roleKey: string
  serviceEnpoint?: string
}

function DataSecurityService({ roleKey, serviceEnpoint }: SecurityProps): JSX.Element {
  const [dataSource, setDataSource] = useState<DataSecurityRoleAPIPayload[] | any>()
  console.log('roleKey...', roleKey)
  const { data, refetch } = useGetDataSecurityRole({
    fetchPolicy: 'no-cache',
    context: {
      uri: serviceEnpoint,
    },
    variables: {
      roleKey: roleKey,
    },
  })

  console.log('data...', data)

  const [updateDataSecurity] = useUpdateDataSecurityRole({
    onCompleted() {
      refetch()
      message.success('Update security success!')
    },
  })
  const handleUpdateDataSucurity = (key: string, index: number, security: string) => {
    console.log('update data security...', serviceEnpoint)
    let dataSucurityList = [...dataSource]
    let value = { ...dataSucurityList[index] }
    value[security] = key
    dataSucurityList[index] = value
    console.log('dataSucurityList...', dataSucurityList)
    updateDataSecurity({
      context: {
        uri: serviceEnpoint,
      },
      variables: {
        roleKey: roleKey,
        securityList: dataSucurityList,
      },
    })
  }

  const dataSecurityImg = (key: string | undefined, security: string, index: number): JSX.Element => {
    let img = imgNone
    switch (key) {
      case 'ORGANIZATION':
        img = imgOrganization
        break
      case 'USER':
        img = imgUser
        break
      case 'SYSTEM':
        img = imgSystem
        break
      case 'PARENT_CHILD':
        img = imgParent
        break
    }
    const menu = (
      <Menu onClick={(e) => handleUpdateDataSucurity(e.key, index, security)}>
        <Menu.Item key="NONE" icon={<img src={imgNone} width="20" alt=""></img>}>
          None selected
        </Menu.Item>
        <Menu.Item key="USER" icon={<img src={imgUser} width="20" alt=""></img>}>
          User
        </Menu.Item>
        <Menu.Item key="ORGANIZATION" icon={<img src={imgOrganization} width="20" alt=""></img>}>
          Organization
        </Menu.Item>
        <Menu.Item key="PARENT_CHILD" icon={<img src={imgParent} width="20" alt=""></img>}>
          Parent:Child (Org)
        </Menu.Item>
        <Menu.Item key="SYSTEM" icon={<img src={imgSystem} width="20" alt=""></img>}>
          System
        </Menu.Item>
      </Menu>
    )

    return (
      <>
        <Space>
          <Dropdown overlay={menu}>
            <img src={img} alt=""></img>
          </Dropdown>
        </Space>
      </>
    )
  }
  useEffect(() => {
    const dataSecurityList = data?.getDataSecurityRole.payload
    setDataSource(dataSecurityList)
  }, [data])
  const columns: ColumnsType<IDataSecurityData> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Security Key',
      dataIndex: 'securityKey',
      key: 'securityKey',
    },
    {
      title: 'Create',
      dataIndex: 'create',
      key: 'create',
      render: (_text, render, index) => {
        return dataSecurityImg(_text, 'create', index)
      },
    },
    {
      title: 'Read',
      dataIndex: 'read',
      key: 'read',
      render: (_text, render, index) => {
        return dataSecurityImg(_text, 'read', index)
      },
    },
    {
      title: 'Write',
      dataIndex: 'write',
      key: 'write',
      render: (_text, render, index) => {
        return dataSecurityImg(_text, 'write', index)
      },
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      render: (_text, render, index) => {
        return dataSecurityImg(_text, 'delete', index)
      },
    },
    {
      title: 'Assign',
      dataIndex: 'assign',
      key: 'assign',
      render: (_text, render, index) => {
        return dataSecurityImg(_text, 'assign', index)
      },
    },
    {
      title: 'Assign To Parent',
      dataIndex: 'assignToParent',
      key: 'assignToParent',
      render: (_text, render, index) => {
        return (
          <Select
            defaultValue={_text || 'NO'}
            onChange={(value) => handleUpdateDataSucurity(value, index, 'assignToParent')}
          >
            <Option value="YES">Yes</Option>
            <Option value="NO">No</Option>
          </Select>
        )
      },
    },
  ]
  return (
    <>
      <DataSecurityTable dataSource={dataSource} columns={columns} rowKey={'permissionCoreService'} />
    </>
  )
}

export default DataSecurityService
