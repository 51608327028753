export const convertRatioToNumber = (ratio: string | number = 0): number => {
  let finalRatio = ratio

  if (typeof ratio === 'string') {
    const [a, b] = ratio.split('/').map((item) => Number(item))

    finalRatio = a / b
  }

  return finalRatio as number
}

export const getIFrameHeightWithRatio = (width: number, ratio: string | number) => {
  return width * convertRatioToNumber(ratio)
}
