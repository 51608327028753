import { gql } from '@apollo/client'

const UPDATE_DATA_SECURITY_ROLE = gql`
  mutation updateDataSecurity($roleKey: String!, $securityList: [INPUT_DATA_SECURITY_FORM]) {
    updateDataSecurity(roleKey: $roleKey, securityList: $securityList) {
      payload {
        role {
          name
        }
      }
    }
  }
`

export default UPDATE_DATA_SECURITY_ROLE
