import { gql } from '@apollo/client'
import { ROLE } from 'graphQL/schemas/role'

const CREATE_ROLE = gql`
  mutation createRole( $roleInput: INPUT_ROLE_FROM){
      createRole(roleInput: $roleInput) {
        message
        code
        payload {${ROLE}}
      }
    }
`

export default CREATE_ROLE
