import { ApolloClient, createHttpLink, from, fromPromise, InMemoryCache, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

import { apiPathV2 } from 'constants/api'

import { appLocalAccessToken, appLocalLocale } from 'utils/localService'
import { getHeaders, getToken } from './helpers'

const getApolloClientEndpointContext = (uri?: string) => {
  if (uri == null) {
    return apiPathV2.core.admin
  }

  return uri
}

const getSubscriptionConnectionParams = () => {
  const token = appLocalAccessToken.get()
  const locale = appLocalLocale.get()

  return {
    authorization: token ? `Bearer ${token}` : '',
    'content-language': locale,
  }
}

const wsLink = new WebSocketLink({
  uri: apiPathV2.core.subscription,
  options: {
    reconnect: true,
    connectionParams: getSubscriptionConnectionParams(),
  },
})

const httpLink = createHttpLink({
  uri: (options) => getApolloClientEndpointContext(options.getContext().uri),
  headers: {
    'content-language': appLocalLocale.get() === 'thTH' ? 'th' : 'en',
  },
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions?.code) {
        case 'TOKEN_IS_EXPIRED':
          console.log('Token is expired, try to call refresh token')

          return fromPromise(
            getToken().then((newToken) => {
              operation.setContext({
                headers: getHeaders(operation.getContext().headers, newToken?.accessToken),
              })
            })
          )
            .filter((values) => Boolean(values))
            .flatMap(() => {
              console.log('Retry the request!')

              return forward(operation)
            })
      }
    }
  }

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const authLink = setContext((_, { headers }) =>
  getToken().then((newToken) => {
    return {
      headers: getHeaders(headers, newToken?.accessToken),
    }
  })
)

const link = from([authLink, errorLink, httpLink])

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  link
)

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

export default client
