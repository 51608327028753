import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useQueryString from 'hooks/useQueryString'

import FullScreenLoading from 'components/FullScreenLoading'

import useAuth from 'contexts/useAuthContext'

import useGetTokenAuthCode from 'graphQL/useGetTokenAuthCode'
import { useGetAppByCredentialQuery } from 'graphQL/generated/operations'

import useGenerateOAuthLink from 'hooks/useGenerateOAuthLink'

import { authorizationKey, goToSignInPage } from 'config'
import { paths } from 'setup/PageRouter'
import { appLocalApp } from 'utils/localService'

const OAuth: React.FC = () => {
  const { t } = useTranslation()

  const auth = useAuth()
  const query = useQueryString()
  const navigate = useNavigate()

  const oauth = useGenerateOAuthLink()

  const code = query.get(authorizationKey.code)
  const credentialKey = query.get(authorizationKey.credential)

  useGetAppByCredentialQuery({
    skip: credentialKey == null,
    variables: {
      credentialKey: credentialKey || '',
    },
    onCompleted({ getAppByCredential }) {
      const data = getAppByCredential.payload

      appLocalApp.set(JSON.stringify(data))
    },
  })

  const [tokenAuthCode] = useGetTokenAuthCode({
    onCompleted(resp) {
      const { token } = resp.getTokenAuthCode.payload

      auth.signIn(token)
    },
    onError() {
      navigate(`${paths.oauth}?permission=no`)
    },
  })

  useEffect(() => {
    if (oauth.url) {
      if (code && credentialKey) {
        tokenAuthCode({
          variables: {
            code,
          },
          context: {
            headers: {
              credentialKey: credentialKey,
            },
          },
        })
      } else {
        goToSignInPage(oauth.url)
      }
    }
  }, [code, credentialKey, oauth.url, tokenAuthCode])

  return <FullScreenLoading>{t('auth:signingIn')}</FullScreenLoading>
}

export default OAuth
