import { Space, Switch } from 'antd'
import { SystemServiceTable } from 'components/Table/Service/index'
import { ArrowRightOutlined } from '@ant-design/icons'
import { LogDetailModal } from 'components/Modal/Administrator'
import { useState } from 'react'

import dayjs from 'dayjs'

import { CoreServiceProps } from './types'

function CoreServiceDashBoard({ dataSource, dataIndex, title, keyForm }: CoreServiceProps): JSX.Element {
  function onChange(key: any) {
    console.log(key)
  }
  const [isLogVisible, setLogVisible] = useState(false)
  const [logKey, setLogKey] = useState('')

  function logDetail(key: string) {
    setLogVisible(!isLogVisible)
    setLogKey(key)
  }

  function onCancel() {
    setLogVisible(false)
  }
  const column = [
    {
      title: () => <strong>{title}</strong>,
      dataIndex,
      key: dataIndex,
    },
    {
      title: () => <strong>Description</strong>,
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: () => <strong>Action</strong>,
      dataIndex: 'action',
      key: 'action',
      render: () => (
        <span key="manage" className="service-action-link">
          <Space size={16} style={{ fontSize: '15px' }}>
            <Switch defaultChecked onChange={onChange} />
          </Space>
        </span>
      ),
    },
  ]

  const logsColumns = [
    {
      title: () => <strong>Function</strong>,
      dataIndex: 'function',
    },
    {
      title: () => <strong>Action</strong>,
      dataIndex: 'action',
    },
    {
      title: () => <strong>IP Address</strong>,
      dataIndex: 'ip',
    },
    {
      title: () => <strong>Timestamp</strong>,
      dataIndex: 'date',
      render: (date: any) => <span>{dayjs(date).format('DD/MM/YYYY | HH:mm:ss')}</span>,
    },
    {
      title: () => <strong>User</strong>,
      dataIndex: 'user',
      render: (user: any) => <a href="#service-user">{user}</a>,
    },
    {
      title: () => <strong>Action</strong>,
      dataIndex: 'action',
      key: 'action',
      render: (_text: any, record: any) => {
        const isLogDetailShow = logKey === record._id && isLogVisible
        return (
          <span key="manage" className="service-action-link">
            <Space size={16} style={{ fontSize: '15px' }}>
              <ArrowRightOutlined onClick={() => logDetail(record._id)} />
              <LogDetailModal isVisible={isLogDetailShow} onCancel={onCancel} />
            </Space>
          </span>
        )
      },
    },
  ]
  function columnsCheck() {
    if (keyForm === 'Logs') {
      return logsColumns
    }
    return column
  }

  return <SystemServiceTable dataSource={dataSource} columns={columnsCheck()} rowKey="_id" />
}
export default CoreServiceDashBoard
