import { gql } from '@apollo/client'

const LOGOUT_SYSTEM = gql`
  mutation logOutUser($userId: String!) {
    logOutUser(userId: $userId) {
      message
    }
  }
`

export default LOGOUT_SYSTEM
