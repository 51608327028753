import { useState } from 'react'
import { Space, Table } from 'antd'
import { ArrowRightOutlined, CheckCircleOutlined, FormOutlined } from '@ant-design/icons'
import { generatePath, useNavigate } from 'react-router-dom'

import useGetSystemAdminProfile from 'graphQL/useGetSystemAdminProfile'

import { paths } from 'setup/PageRouter'

import type { SystemAdminProfileTable } from './types'

function AdministratorDashboard() {
  const navigate = useNavigate()

  const [, setTableData] = useState<Array<SystemAdminProfileTable>>([])

  const goToAdministratorDetail = () => {
    navigate(generatePath(paths.systemAdminAdministratorDetail))
  }

  const getSystemAdminProfile = useGetSystemAdminProfile({
    onCompleted: (resp) => {
      const systemProfileList = resp.getSystemAdminProfile.payload.map(
        ({ attribute, _id, email }): SystemAdminProfileTable => {
          return {
            key: _id,
            email: email?.[0].value,
            name: `${attribute?.firstName || ''} ${attribute?.lastName}`,
            status: email?.[0].verifyStatus,
          }
        }
      )
      setTableData(systemProfileList)
    },
  })

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_text: any, render: any) => (
        <span key="status-render" className="service-status">
          <Space size={5} style={{ color: 'green', fontSize: '15px' }}>
            <CheckCircleOutlined />
            {render.status}
          </Space>
        </span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: () => (
        <span key="manage" className="service-action-link">
          <Space size={16} style={{ fontSize: '15px' }}>
            <FormOutlined onClick={goToAdministratorDetail} />
            <ArrowRightOutlined onClick={goToAdministratorDetail} />
          </Space>
        </span>
      ),
    },
  ]

  const data = [
    {
      key: '1',
      name: 'Khomphet Meesub',
      email: 'komphet.me@lattesoft.co.th',
      status: 'Active',
    },
    {
      key: '2',
      name: 'Jim Green',
      email: 'komphet.me@lattesoft.co.th',
      status: 'Active',
    },
    {
      key: '3',
      name: 'Joe Black',
      email: 'komphet.me@lattesoft.co.th',
      status: 'Active',
    },
  ]

  return <Table dataSource={data} columns={columns} loading={getSystemAdminProfile.loading} />
}

export default AdministratorDashboard
