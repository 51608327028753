import type { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import ContentCard from 'components/Card/Content'
import PageTitle from 'components/PageTitle'
import { ApplocationForm } from 'components/Form/Application'

import SystemAdminLayout from 'layouts/SystemAdminLayout'

const CreateSystemAdminApplicationPage: FC = () => {
  const navigate = useNavigate()

  return (
    <SystemAdminLayout>
      <div className="admin-content-container">
        <>
          <PageTitle title="New Application" onBack={() => navigate(-1)} />
          <ContentCard title="New Application">
            <ApplocationForm onFinish={(values) => console.log(values)} />
          </ContentCard>
        </>
      </div>
    </SystemAdminLayout>
  )
}

export default CreateSystemAdminApplicationPage
