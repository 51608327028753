import { Card } from 'antd'
import type { IContentCardProps } from './types'

function ContentCard({ title, rightComponent, loading, children }: IContentCardProps): JSX.Element {
  return (
    <Card loading={loading} title={<span className="content-card-title">{title}</span>} extra={rightComponent}>
      {children}
    </Card>
  )
}

export default ContentCard
