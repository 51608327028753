import { useState } from 'react'

import { defaultPagination } from 'graphQL/config'

import type { PaginationFromAPI } from 'graphQL/types'
import type { ServiceHook } from './interface'

type PaginationHandler = (page: number, limit: number) => void

interface AntdPaginationOptions {
  pageSizeOptions: string[]
}

interface AntdPaginationResponse {
  pageSizeOptions: string[]
  pagination: PaginationFromAPI
  changeToFirstPage: VoidFunction
  createPagination: (paginationFromAPI: PaginationFromAPI) => void
  onChange: PaginationHandler
}

const defaultOptions = {
  pageSizeOptions: ['5', '10', '20', '50', '100'],
}

const useAntdPagination: ServiceHook<AntdPaginationResponse, AntdPaginationOptions> = (options) => {
  const op = {
    ...defaultOptions,
    ...(options ?? {}),
  }

  const [pagination, setPagination] = useState<PaginationFromAPI>(defaultPagination)

  const changeToFirstPage = () =>
    setPagination({
      ...pagination,
      page: 1,
    })

  const onChange = (page: number, limit: number) => {
    const isSizeChange = limit !== pagination.limit

    setPagination({
      ...pagination,
      page: isSizeChange ? 1 : page,
      limit,
    })
  }

  const createPagination = (paginationFromAPI: PaginationFromAPI) => setPagination(paginationFromAPI)

  return {
    pageSizeOptions: op.pageSizeOptions,
    pagination,
    changeToFirstPage,
    createPagination,
    onChange,
  }
}

export default useAntdPagination
