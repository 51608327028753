import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, List } from 'antd'

import ConfirmationDeleteAccountModal from 'components/ConfirmationDeleteOrgModal'

import { paths } from 'setup/PageRouter'

import useDeleteOrg from 'graphQL/useDeleteOrg'

import type { IDangerZoneProps } from './interafce'

const DeleteOrganization: React.FC<IDangerZoneProps> = ({ orgKey }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const navgiate = useNavigate()

  const { t } = useTranslation('organization')

  const [deleteOrg, deleteOrgResp] = useDeleteOrg({
    onCompleted() {
      navgiate(paths.organization)
    },
  })

  return (
    <>
      <List.Item actions={[renderDeleteOrgButton()]}>
        <List.Item.Meta
          title={<span className="bold">{t('setting.deleteOrg.title')}</span>}
          description={t('setting.deleteOrg.description')}
        />
      </List.Item>

      <ConfirmationDeleteAccountModal
        visible={modalVisible}
        loading={deleteOrgResp.loading}
        onClose={() => setModalVisible(false)}
        onOk={() => {
          deleteOrg({ variables: { orgKey } })
        }}
      />
    </>
  )

  function renderDeleteOrgButton() {
    return (
      <Button type="primary" danger onClick={() => setModalVisible(true)}>
        {t('setting.deleteOrg.button')}
      </Button>
    )
  }
}

export default DeleteOrganization
