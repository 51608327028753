// import VerificationForm from 'components/Form/Verification'
import { FormInstance, Modal } from 'antd'
import UserFieldFrom from 'components/Form/AppRole/UserFieldFrom'
type UserFieldProps = {
  isShowDialog: boolean
  onCancel: () => void
  onFinish: (values: any) => void
  data?: any
  form?: FormInstance<any>
}
function UserField({ isShowDialog, onCancel, onFinish, data, form }: UserFieldProps): JSX.Element {
  return (
    <Modal title="User Field" visible={isShowDialog} onOk={() => null} onCancel={onCancel} footer={false} width={700}>
      <UserFieldFrom onFinish={onFinish} data={data} form={form} />
    </Modal>
  )
}

export default UserField
