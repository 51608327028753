import { message } from 'antd'
import RoleFrom from 'components/Form/AppRole/RoleFrom'
import { Role } from 'graphQL/schemaType/role'
import useUpdateRole, { RoleInput } from 'graphQL/useUpdateRole/useUpdateRole'

function RoleSettingPage({ role }: { role: Role }): JSX.Element {
  const [updateRole] = useUpdateRole({
    onCompleted() {
      message.success('Update role success!')
    },
  })
  const onFinish = (values: Role) => {
    let roleInput: RoleInput = values
    if (role.roleKey === values.roleKey)
      roleInput = {
        name: values.name,
        status: values.status,
        regisable: values.regisable,
        roleType: values.roleType,
        isDefault: values.isDefault,
        isReplaceable: values.isReplaceable,
        isInvite: values.isInvite,
      }
    updateRole({
      variables: {
        roleKey: role.roleKey,
        roleInput: roleInput,
      },
    })
  }
  return (
    <>
      <RoleFrom onFinish={onFinish} data={role} />
    </>
  )
}

export default RoleSettingPage
