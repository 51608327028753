import type { GetPermissionRoleData } from 'graphQL/queryResponseTypes'

export type PermissionCode = 'READ' | 'EDIT'

export type PermissionFormInput = Record<string, PermissionCode[]>

interface PermissionInput {
  code?: PermissionCode[]
  permissionKey: string
}

export const createPermissionInputForUpdateToAPI = (permissionFormInput: PermissionFormInput) => {
  const listPermissionKeys = Object.keys(permissionFormInput)

  const listPermissionsInput: PermissionInput[] = listPermissionKeys.map((permissionKey) => {
    const codes = permissionFormInput[permissionKey]

    return {
      permissionKey,
      code: codes,
    }
  })

  return listPermissionsInput
}

export const createInitialPermissionFormInput = (listPermissions: GetPermissionRoleData[]) => {
  const permissionInput: PermissionFormInput = {}

  listPermissions.forEach(({ permissionKey, code }) => {
    const filteredOutNonePermissionCode = code.filter((item) => item !== 'NONE') as PermissionCode[]

    permissionInput[permissionKey] = filteredOutNonePermissionCode
  })

  return permissionInput
}
