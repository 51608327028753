import type { FC } from 'react'
import type { ColumnsType } from 'antd/lib/table'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Input, Menu, Table, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

import ContentCard from 'components/Card/Content'
import ApprovalStatus from 'components/Status/ApprovalStatus'
import TableColumnFormat from 'components/Table/ColumnFormat'
import TableSummary from 'components/TableSummary'

import useAntdTablePagination from 'hooks/useAntdTablePagination'

import useGetOrganizationApproval from 'graphQL/useGetOrganizationApproval'
import useUpdateOrgStatus from 'graphQL/useUpdateOrgStatus'

import { antdSorter } from 'helpers/antdUtils'
import { formatDate } from 'helpers/formatter'
import { routeTo } from 'helpers/utils'

import { paths } from 'setup/PageRouter'

import type { PaginationFromAPI, QueryInputVars } from 'graphQL/types'
import type { OrganizationAPIPayload } from 'graphQL/useGetOrganization/interface'
import type { OrganizationStatus } from 'graphQL/graphQL-service-hook'

const { Search } = Input

const OrganizationApproval: FC = () => {
  const { t } = useTranslation('approval')

  const contentCardLocaleKey = 'organization.contentCard'
  const tableLocaleKey = `${contentCardLocaleKey}.table`

  const navigate = useNavigate()

  const [searchString, setSearchString] = useState<string>()

  const { pagination, changeToFirstPage, createAntdTablePagination, onChange } = useAntdTablePagination()

  const queryVariables: QueryInputVars = {
    input: {
      search: {
        name: searchString,
      },
      pagination,
      sort: {
        createdAt: -1,
      },
      query: {
        status: { $ne: 'PREPARING' },
      },
    },
  }

  const organizationApprovalQuery = useGetOrganizationApproval({
    fetchPolicy: 'network-only',
    variables: queryVariables,
  })

  const [updateOrgStatus, updateOrgStatusResp] = useUpdateOrgStatus({
    onCompleted() {
      organizationApprovalQuery.refetch()
    },
  })

  const organizationApprovalData: OrganizationAPIPayload[] =
    organizationApprovalQuery.data?.getOrganizationApproval.payload || []
  const organizationApprovalPagination: PaginationFromAPI | undefined =
    organizationApprovalQuery.data?.getOrganizationApproval.pagination

  const columns: ColumnsType<OrganizationAPIPayload> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: () => (
        <TableColumnFormat title={t(`${tableLocaleKey}.name.title`)} subtitle={t(`${tableLocaleKey}.name.subtitle`)} />
      ),
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: () => (
        <TableColumnFormat
          title={t(`${tableLocaleKey}.createdAt.title`)}
          subtitle={t(`${tableLocaleKey}.createdAt.subtitle`)}
        />
      ),
      sorter: (a, b) => antdSorter(a.createdAt, b.createdAt),
      render: (createdAt) => formatDate(createdAt),
    },
    {
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      title: () => (
        <TableColumnFormat
          title={t(`${tableLocaleKey}.updatedAt.title`)}
          subtitle={t(`${tableLocaleKey}.updatedAt.subtitle`)}
        />
      ),
      sorter: (a, b) => antdSorter(a.updatedAt, b.updatedAt),
      render: (updatedAt) => formatDate(updatedAt),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: () => (
        <TableColumnFormat
          title={t(`${tableLocaleKey}.status.title`)}
          subtitle={t(`${tableLocaleKey}.status.subtitle`)}
        />
      ),
      render: (status) => <ApprovalStatus key="status" status={status} />,
    },
    {
      key: 'action',
      dataIndex: 'orgKey',
      render: (orgKey) => (
        <Dropdown.Button
          type="ghost"
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item key="APPROVED" onClick={() => onUpdateOrganizationStatus('APPROVED', orgKey)}>
                {t('status.APPROVED')}
              </Menu.Item>
              <Menu.Item
                key="NEED_MORE_INFORMATION"
                onClick={() => onUpdateOrganizationStatus('NEED_MORE_INFORMATION', orgKey)}
              >
                {t('status.NEED_MORE_INFORMATION')}
              </Menu.Item>
              <Menu.Item key="REJECTED" onClick={() => onUpdateOrganizationStatus('REJECTED', orgKey)}>
                {t('status.REJECTED')}
              </Menu.Item>
              <Menu.Item key="SUSPENDED" onClick={() => onUpdateOrganizationStatus('SUSPENDED', orgKey)}>
                {t('status.SUSPENDED')}
              </Menu.Item>
            </Menu>
          }
          onClick={() => {
            navigate(
              routeTo(paths.organizationDetail, {
                params: {
                  orgKey,
                  slug: 'organization-setting',
                },
              }),
              {
                state: { backPath: paths.orgApproval },
              }
            )
          }}
        >
          <Typography.Text>{t(`${contentCardLocaleKey}.action.goToOrganization`)}</Typography.Text>
        </Dropdown.Button>
      ),
    },
  ]

  return (
    <ContentCard
      title="Organization Approval"
      rightComponent={
        <Search
          placeholder={t('organization.contentCard.search.placeholder')}
          onSearch={(value) => {
            setSearchString(value)
            changeToFirstPage()
          }}
        />
      }
    >
      <Table
        loading={organizationApprovalQuery.loading || updateOrgStatusResp.loading}
        dataSource={organizationApprovalData}
        rowKey="orgKey"
        columns={columns}
        pagination={createAntdTablePagination(organizationApprovalPagination)}
        onChange={onChange}
        footer={() => <TableSummary pagination={organizationApprovalPagination} />}
      />
    </ContentCard>
  )

  function onUpdateOrganizationStatus(status: OrganizationStatus, orgKey: string) {
    updateOrgStatus({
      variables: {
        status,
        orgKey,
      },
    })
  }
}

export default OrganizationApproval
