import { ArrowRightOutlined, FormOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import { generatePath, useNavigate } from 'react-router-dom'

import { SystemServiceTable } from 'components/Table/Service/index'

import { paths } from 'setup/PageRouter'

import { SystemServiceProps } from './types'

function SystemServiceDashBoard({ dataSource }: SystemServiceProps): JSX.Element {
  const navigate = useNavigate()

  function goToHoorayCoreService(key: string) {
    navigate(
      generatePath(paths.systemAdminServiceDetail, {
        key,
      })
    )
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_text: any, render: any) => (
        <span key="status-render" className="service-status">
          <Space size={5} style={{ color: 'green', fontSize: '15px' }}>
            <CheckCircleOutlined />
            {render.status}
          </Space>
        </span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: () => (
        <span key="manage" className="service-action-link">
          <Space size={16} style={{ fontSize: '15px' }}>
            <FormOutlined onClick={() => goToHoorayCoreService('KEY')} />
            <ArrowRightOutlined onClick={() => goToHoorayCoreService('KEY')} />
          </Space>
        </span>
      ),
    },
  ]
  return <SystemServiceTable dataSource={dataSource} columns={columns} rowKey="orgKey" />
}
export default SystemServiceDashBoard
