import { useQuery } from '@apollo/client'

import { onDefaultErrorMessage } from 'utils/apollo'

import apolloClient from 'setup/apolloClient'

import QUERY_DOCUMENT from './getAllOrganization'

import type {
  APIPayloadResponseWithPagination,
  FindDataInput,
  GraphQLServiceQueryHook,
} from 'graphQL/graphQL-service-hook'
import type { AllOrganizationForSearchAPIPayload } from './interface'

interface AllOrganizationForSearchData {
  getAllOrganization: APIPayloadResponseWithPagination<AllOrganizationForSearchAPIPayload[]>
}

export interface AllOrganizationForSearchInput {
  input: FindDataInput
}

const useGetAllOrganizationForSearch: GraphQLServiceQueryHook<
  AllOrganizationForSearchData,
  AllOrganizationForSearchInput
> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetAllOrganizationForSearch

export const fetchAllOrganizations = async (variables: AllOrganizationForSearchInput) =>
  apolloClient.query<AllOrganizationForSearchData, AllOrganizationForSearchInput>({
    query: QUERY_DOCUMENT,
    variables,
    fetchPolicy: 'no-cache',
  })
