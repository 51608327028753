import { useMutation } from '@apollo/client'

import { getUserTypeEndpoint } from 'utils/user'
import { appLocalAccessToken } from 'utils/localService'
import { onDefaultErrorMessage } from 'utils/apollo'

import MUTATION_DOCUMENT from './updateOrganization'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import type { UpdateOrganizationInput } from './interface'

interface UpdateOrganizationData {
  updateOrganization: APIPayloadResponse<{ orgKey: string }>
}

interface UpdateOrganizationVars {
  orgKey?: string
  parentOrgKey?: string
  input?: UpdateOrganizationInput
}

const useUpdateOrganization: GraphQLServiceMutationHook<UpdateOrganizationData, UpdateOrganizationVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    context: {
      uri: getUserTypeEndpoint(appLocalAccessToken.get()),
    },
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useUpdateOrganization
