import { gql } from '@apollo/client'

const INVITE_ORGANIZATION_CODE = gql`
  mutation inviteOrganizationCode($inviteCode: String!) {
    inviteOrganizationCode(inviteCode: $inviteCode) {
      message
      code
      payload {
        orgKey
      }
    }
  }
`

export default INVITE_ORGANIZATION_CODE
