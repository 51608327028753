import React, { useState } from 'react'
import useSound from 'use-sound'
import { Avatar, Badge, Card, Dropdown, Menu, Space, Spin, Typography } from 'antd'
import { BellOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'

import NotificationDropdownList from './NotificationDropdownList'

import { paths } from 'setup/PageRouter'

import { useReadAllNotification } from 'graphQL/useReadAllNotification'
import { useGetMyNotificationQuery, useGetMyNotificationSubScription } from 'graphQL/useGetMyNotification'

import notiSound from '../../assets/sound/notification.mp3'

import type { MyNotificationAPIPayload } from 'graphQL/useGetMyNotification/interface'

const { Title, Link } = Typography

const NotificationDropdown: React.FC = () => {
  const navigate = useNavigate()

  const [play] = useSound(notiSound)

  const [notificationList, setNotificationList] = useState<MyNotificationAPIPayload[]>()
  const [notificationCount, setNotificaitonCount] = useState<number | undefined>()

  const myNotificationRespQuery = useGetMyNotificationQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pagination: {
          limit: 4,
          page: 1,
        },
      },
    },
    onCompleted(resp) {
      const data = resp.getMyNotification
      setNotificationList(data?.payload)
      setNotificaitonCount(data?.unread)
    },
  })
  useGetMyNotificationSubScription({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pagination: {
          limit: 4,
          page: 1,
        },
      },
    },
    onSubscriptionData(resp) {
      const data = resp.subscriptionData.data?.getMyNotification
      setNotificationList(data?.payload)
      console.log(data)
      setNotificaitonCount(data?.unread)
      play()
    },
  })

  const [readAllNotification] = useReadAllNotification({
    fetchPolicy: 'network-only',
    onCompleted() {
      navigate(paths.notification)
    },
  })

  return (
    <>
      <GlobalStyle />

      {/* <Dropdown
        placement="bottomRight"
        overlayClassName="notification-dropdown-menu"
        overlay={renderMemu()}
        trigger={['click']}
      >
        <a href="#open-notificaiton-menu" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <Space size={8}>
            <Badge count={notificationCount}>
              <Avatar
                style={notificationCount ? { backgroundColor: '#ffbf00' } : {}}
                shape="circle"
                icon={<BellOutlined />}
              />
            </Badge>
          </Space>
        </a>
      </Dropdown> */}
    </>
  )

  function renderMemu() {
    return myNotificationRespQuery.loading ? (
      <Menu selectable>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Spin />
        </div>
      </Menu>
    ) : (
      <Card>
        <Title level={4}>การแจ้งเตือน</Title>
        <LinkStyle underline onClick={() => readAllNotification()}>
          ดูทั้งหมด
        </LinkStyle>
        <NotificationDropdownList data={notificationList} />
      </Card>
    )
  }
}

export default NotificationDropdown

const GlobalStyle = createGlobalStyle`
  .notification-dropdown-menu {
    position: fixed; 
    z-index: 1; 
    top: 50px !important;
    width: 700px;
    > .ant-dropdown-menu {
      margin-top: 8px;
      padding: 10px
    }
    width: 700px;
  }
`
const LinkStyle = styled(Link)`
  display: flex;
  justify-content: end;
`
