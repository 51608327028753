import { Form, Input, Modal, Switch } from 'antd'
import React, { FC } from 'react'

interface ManageCredentialModalProps {
  visible: boolean
  data?: unknown
  onClose: () => void
}

function getModalTitle(data: unknown): string {
  switch (data) {
    case undefined:
    case null:
      return 'Create Credential'
    default:
      return 'Credential' // naming in future
  }
}

const ManageCredentialModal: FC<ManageCredentialModalProps> = ({ visible, data, onClose }) => {
  return (
    <Modal visible={visible} title={getModalTitle(data)} width="50%" onCancel={onClose}>
      <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="left">
        <Form.Item label="Credential Name">
          <Input />
        </Form.Item>
        <Form.Item label="Credential Key">
          <Input />
        </Form.Item>
        <Form.Item label="Access Token Expire">
          <Input placeholder="Millisecond" />
        </Form.Item>
        <Form.Item label="Refresh Token Expire">
          <Input placeholder="Millisecond" />
        </Form.Item>
        <Form.Item label="Secret Key">
          <Input />
        </Form.Item>
        <Form.Item label="Status" help="Active or Disabled">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ManageCredentialModal
