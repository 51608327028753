import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'

import useAuth from 'contexts/useAuthContext'
import { usePermissionContext } from 'contexts/PermissionContext'

import { GetMyProfileData } from 'graphQL/queryResponseTypes'

import withAuthV2 from 'middlewares/withAuthV2'

import NotFoundPage from 'pages/notFound/NotFoundPage'

import {
  adminPrivateRoutes,
  filterRoutePermission,
  paths,
  publicRoutes,
  systemAdminPrivateRoutes,
} from './routesConfig'
import { getUserType } from 'utils/token/token'
import { UserType } from 'utils/token/interface'
import { routeTo } from 'helpers/utils'

const PageRouter = () => {
  const { user, token } = useAuth()
  const userType = getUserType(token?.accessToken)

  const { myPermissions } = usePermissionContext()

  const redirectTo = user == null ? paths.signIn : paths.organization

  return (
    <BrowserRouter>
      <Routes>
        <Route path={paths.root} element={<Navigate to={redirectTo} replace />} />

        <Route element={<SignInRoute user={user} />}>
          {publicRoutes.map((route) => (
            <Route {...route} />
          ))}
        </Route>

        <Route element={<AdminAuthenticatedRoute user={user} userType={userType} />}>
          {adminPrivateRoutes.map((route) => {
            const canAccess = filterRoutePermission(route, myPermissions)

            if (!canAccess) {
              return <NotFoundRoute key={route.path} />
            }

            return <Route {...route} />
          })}
        </Route>

        <Route element={<SystemAdminAuthenticatedRoute user={user} userType={userType} />}>
          {systemAdminPrivateRoutes.map((route) => {
            const canAccess = filterRoutePermission(route, myPermissions)

            if (!canAccess) {
              return <NotFoundRoute key={route.path} />
            }

            return <Route {...route} />
          })}
        </Route>

        <Route element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default PageRouter

export interface AuthenticateUserProps {
  user?: GetMyProfileData
}

export interface AuthenticateUserRoleProps extends AuthenticateUserProps {
  userType?: UserType | null
}

const SignInRoute = ({ user }: AuthenticateUserProps) => {
  if (user != null) {
    return <Navigate to={paths.root} replace />
  }

  return <Outlet />
}

const AdminAuthenticatedRoute = withAuthV2(({ user, userType }: AuthenticateUserRoleProps) => {
  if (user == null) {
    return <Navigate to={paths.signIn} replace />
  }

  if (userType === 'SYSTEM_ADMIN') {
    return (
      <Navigate
        to={routeTo(paths.systemAdmin, {
          params: {
            page: 'dashboard',
          },
        })}
      />
    )
  }

  return <Outlet />
})

const SystemAdminAuthenticatedRoute = withAuthV2(({ user, userType }: AuthenticateUserRoleProps) => {
  if (user == null) {
    return <Navigate to={paths.signIn} replace />
  }

  if (userType === 'RESOURCE_ADMIN') {
    return <Navigate to={paths.root} />
  }

  return <Outlet />
})

const NotFoundRoute = () => {
  return <Route element={<NotFoundPage />} />
}
