import { Input, Space, Form, Switch, Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { NewServiceFormProps } from './types'

function NewServiceForm({ onFinish, loading }: NewServiceFormProps): JSX.Element {
  return (
    <Form
      name="newservice"
      onFinish={onFinish}
      labelAlign="left"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 12 }}
      className="new-service-form"
    >
      <Space direction="vertical" size={16}>
        <Form.Item label="Service Endpoint" style={{ overflow: 'auto' }} name="serviceendpoint">
          <Input />
        </Form.Item>

        <Form.Item label="Service Key" style={{ overflow: 'auto' }} name="servicekey">
          <Input />
        </Form.Item>

        <Form.Item label="Status" help="Active or Disabled" style={{ overflow: 'auto' }} name="switch">
          <Switch defaultChecked />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" loading={loading} icon={<PlusCircleOutlined />}>
            Add Service
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}

export default NewServiceForm
