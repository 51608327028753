import type { FC } from 'react'

import styled from 'styled-components'
import { Select, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import type { OrganizationStatus } from 'graphQL/graphQL-service-hook'

const statusList: OrganizationStatus[] = ['APPROVED', 'NEED_MORE_INFORMATION', 'REJECTED', 'REVIEWING', 'SUSPENDED']

const { Option } = Select

type OrganizationStatusFilterLabelDirection = 'horizontal' | 'vertical'
type CustomOrganizationStatus = 'ALL' | OrganizationStatus

interface OrganizationStatusFilterButtonProps {
  labelDirection?: OrganizationStatusFilterLabelDirection
  onChange?: (value: CustomOrganizationStatus) => void
}

const OrganizationStatusFilterButton: FC<OrganizationStatusFilterButtonProps> = ({
  labelDirection = 'horizontal',
  onChange,
}) => {
  const { t } = useTranslation('organizationStatus')
  const statusLocaleKey = 'status'

  const [searchParams, setSearchParams] = useSearchParams({
    status: 'APPROVED',
  })

  const status = searchParams.get('status')

  return (
    <Space size="large" direction={labelDirection}>
      <Label direction={labelDirection}>{t('title')}</Label>

      <Select
        style={{ minWidth: 150 }}
        value={status}
        onChange={(value) => {
          const status = value as CustomOrganizationStatus

          setSearchParams({
            status,
          })

          onChange?.(status)
        }}
      >
        <Option value="ALL">{t(`${statusLocaleKey}.ALL`)}</Option>

        {statusList.map((status) => (
          <Option key={status} value={status}>
            {t(`${statusLocaleKey}.${status}`)}
          </Option>
        ))}
      </Select>
    </Space>
  )
}

export default OrganizationStatusFilterButton

const Label = styled.small<{ direction?: OrganizationStatusFilterLabelDirection }>`
  color: #999;
`
