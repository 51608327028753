import React, { useState } from 'react'
import { Button, message } from 'antd'
import { Marker, useMapEvents } from 'react-leaflet'
import FontAwesomeIcon from 'components/FontawesomeIcon'
import styled from 'styled-components'
import type { LocationMarkerProps } from './interface'
import { useTranslation } from 'react-i18next'

const LocationMarker: React.FC<LocationMarkerProps> = ({ currentPosition, positionHandler }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const map = useMapEvents({
    moveend: () => {
      positionHandler?.(map.getCenter())
    },
    locationfound: (event: any) => {
      positionHandler?.(event.latlng)

      map.flyTo(event.latlng, map.getZoom())

      setLoading(false)
    },
    locationerror: (event: any) => {
      console.log('Error location event', event)

      message.error('ไม่สามารถเรียกข้อมูลแผนที่ได้ในตอนนี้ กรุณาลองรีเฟรชหน้าจอแล้วลองอีกครั้ง')

      setLoading(false)
    },
  })

  return (
    <>
      {currentPosition === null ? null : (
        <Marker
          position={currentPosition}
          draggable
          eventHandlers={{
            dragend: (event: any) => {
              const latlng = event.target._latlng

              map.panTo(latlng)
            },
          }}
        />
      )}
      <GetCurrentPositionButton
        type="primary"
        onClick={() => {
          setLoading(true)
          map.locate()
        }}
        icon={<FontAwesomeIcon iconName="map-marker-alt" />}
        loading={loading}
      >
        {t('organization:new.selectCurrentLocation')}
      </GetCurrentPositionButton>
    </>
  )
}

export default LocationMarker

const GetCurrentPositionButton = styled(Button)`
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 999;

  &.ant-btn.ant-btn-loading {
    position: absolute;
  }
`
