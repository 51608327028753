import type { CustomGetMyPermissionData } from 'graphQL/queryResponseTypes'

interface CheckPermissionCanEditDataOptions {
  code: string
  permissionKey: string
  permissionsList?: CustomGetMyPermissionData[]
}

export const checkUserPermission = (
  permissions: CustomGetMyPermissionData | CustomGetMyPermissionData[],
  userPermissions?: CustomGetMyPermissionData[]
) => {
  const isNoPermissionsRequired = Array.isArray(permissions) && permissions.length === 0
  const isUserHaveNoPermissions = userPermissions == null

  if (isNoPermissionsRequired || (isNoPermissionsRequired && isUserHaveNoPermissions) || permissions == null) {
    return true
  }

  if (Array.isArray(permissions)) {
    const checkMultiplePermissions = permissions
      .map((requiredPermission) => {
        return userPermissions?.find((permission) => permission.permissionKey === requiredPermission.permissionKey)
      })
      .filter((permission) => permission)

    if (checkMultiplePermissions.length === 0) {
      return false
    }

    return checkMultiplePermissions.every((permission) => {
      const requiredPermission = permissions.find((item) => item.permissionKey === permission?.permissionKey)

      return checkPermissionWithPermissionCode(requiredPermission?.code, permission)
    })
  }

  const foundPermission = userPermissions?.find((permission) => permission.permissionKey === permissions.permissionKey)

  return checkPermissionWithPermissionCode(permissions.code, foundPermission)
}

const checkPermissionWithPermissionCode = (permissionCode: string[] = [], permission?: CustomGetMyPermissionData) => {
  const code = permission?.code || []

  const isReadOnly = permissionCode?.[0] === 'READ'

  if (code.length > 0) {
    if (isReadOnly) {
      return code.includes('READ')
    }

    return code.includes('EDIT')
  }

  return false
}

export function checkPermissionCanEditData({
  code,
  permissionKey,
  permissionsList,
}: CheckPermissionCanEditDataOptions) {
  if (permissionsList == null) {
    return 0
  }

  return permissionsList.filter(
    (pms) => pms.permissionKey === permissionKey && pms.code.filter((item) => item === code).length
  ).length
}
