import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, List, Row } from 'antd'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import ContentBlock from 'components/ContentBlock'
import FullWidthSpace from 'components/FullWidthSpace'
import NotificationIcon from './NotificationIcon'

import { useGetMyNotificationQuery } from 'graphQL/useGetMyNotification'

import type { MyNotificationAPIPayload } from 'graphQL/useGetMyNotification/interface'
import type { NotificationDataSource } from './interface'

type NotificaitonParams = {
  id: string
}

const Notification: React.FC = () => {
  const { t } = useTranslation()

  const params = useParams<NotificaitonParams>() as NotificaitonParams

  const [notificactions, setNotifications] = useState<NotificationDataSource[]>([])
  useGetMyNotificationQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        findOneById: params.id,
      },
    },
    onCompleted(resp) {
      const data = resp.getMyNotification

      setNotifications(data.payload)
    },
  })

  const readStatus = (notification: MyNotificationAPIPayload): string => {
    let textDate = dayjs(notification.updatedAt).fromNow()
    textDate = notification.read === 'YES' ? t('notification:read') + ' ' + textDate : textDate
    return textDate
  }

  return (
    <ContentBlock>
      <Row gutter={32}>
        <Col span={24}>
          <List
            dataSource={notificactions}
            renderItem={(item) => (
              <List.Item key={item._id}>
                <List.Item.Meta
                  avatar={
                    <NotificationIcon
                      type={item.attribute?.icon?.type || 'info'}
                      iconName={item.attribute?.icon?.iconName || 'info-circle'}
                    />
                  }
                  title={item.title}
                  description={
                    <FullWidthSpace direction="vertical">
                      {/* <span>{item.content}</span> */}
                      <div dangerouslySetInnerHTML={{ __html: item.content }} />
                      <DateTimeTitle>{readStatus(item)}</DateTimeTitle>
                    </FullWidthSpace>
                  }
                />
              </List.Item>
            )}
          ></List>
        </Col>
      </Row>
    </ContentBlock>
  )
}

export default Notification

const DateTimeTitle = styled.span`
  color: #2699fb;
`
