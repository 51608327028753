import { gql } from '@apollo/client'

const GET_DATA_SECURITY_ROLE = gql`
  query getDataSecurityRole($roleKey: String!) {
    getDataSecurityRole(roleKey: $roleKey) {
      message
      code
      payload {
        name
        securityKey
        create
        read
        write
        delete
        assign
        assignToParent
      }
    }
  }
`

export default GET_DATA_SECURITY_ROLE
