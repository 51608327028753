import { CoreServiceDashBoard } from 'components/Dashboard/Service/index'

function ServiceLogsForm(): JSX.Element {
  const dataSource = [
    {
      _id: '1',
      action: 'Update',
      date: '2021-06-08T15:11:43.181Z',
      function: 'getApplication (Version 1.1)',
      ip: '192.168.1.1',
      user: 'Komphet Meesab',
    },
    {
      _id: '2',
      action: 'Update',
      date: '2021-06-02T10:01:25.181Z',
      function: 'getApplication (Version 1.1)',
      ip: '192.168.1.1',
      user: 'Komphet Meesab',
    },
    {
      _id: '3',
      action: 'Update',
      date: '2021-06-01T01:10:13.581Z',
      function: 'getApplication (Version 1.1)',
      ip: '192.168.1.1',
      user: 'Komphet Meesab',
    },
  ]
  return (
    <CoreServiceDashBoard dataSource={dataSource} dataIndex="permissionkey" title="Permission Key" keyForm="Logs" />
  )
}

export default ServiceLogsForm
