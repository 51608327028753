import { useState } from 'react'
import { DropDownFitlerType, FilterValue, getSorterNumber, Table } from 'hooray-table'
import get from 'lodash/get'
import { Card } from 'antd'
import { useTranslation } from 'react-i18next'

import useGetMasterData from 'graphQL/useGetMasterData'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import { getColumnSearchPropsByDataIndex } from 'utils/antdUtils'

import type { Pagination } from 'graphQL/graphQL-service-hook'
import type { MasterDataAPIPayload } from 'graphQL/useGetMasterData/interface'
import type { ColumnsType } from 'antd/lib/table'
import type { LocaleType } from 'hooray-table/dist/helpers/locale'
import type { SorterResult } from 'antd/lib/table/interface'

const defaultPagination = {
  limit: 10,
  page: 1,
}

const MasterDataPage: React.FC = () => {
  const { i18n } = useTranslation()
  const locale = i18n.language as LocaleType

  const [pagination, setPagination] = useState<Pagination>(defaultPagination)
  const [filterDropDownInfo, setFilterDropDownInfo] = useState<Record<string, FilterValue | null>>()
  const [filterDropDownType, setFilterDropDownType] = useState<DropDownFitlerType>('SEARCH')
  const [sortInfo, setSortInfo] = useState<SorterResult<any>>()

  const masterDataQuery = useGetMasterData({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        pagination,
        search: filterDropDownType === 'SEARCH' ? filterDropDownInfo : {},
        filter: filterDropDownType === 'FILTER' ? filterDropDownInfo : {},
        sort: {
          [sortInfo?.field as string]: getSorterNumber(sortInfo?.order),
        },
      },
    },
  })

  const masterDataList: MasterDataAPIPayload[] = get(masterDataQuery, 'data.getMasterData.payload', [])
  const masterDataPagination: Pagination = get(masterDataQuery, 'data.getMasterData.pagination')

  const columns: ColumnsType<MasterDataAPIPayload> = [
    {
      ...getColumnSearchPropsByDataIndex('parentKey', setFilterDropDownType),
      title: 'Parent Key',
      dataIndex: 'parentKey',
    },
    {
      ...getColumnSearchPropsByDataIndex('dataKey', setFilterDropDownType),
      title: 'Data Key',
      dataIndex: 'dataKey',
    },
    {
      ...getColumnSearchPropsByDataIndex('text', setFilterDropDownType),
      title: 'Text',
      dataIndex: 'text',
    },
  ]

  return (
    <AuthenticatedLayout>
      <div className="admin-content-container">
        <Card>
          <Table
            locale={locale}
            pagination={{
              total: masterDataPagination?.totalItems,
            }}
            loading={masterDataQuery.loading}
            title="Master data table"
            columns={columns}
            dataSource={masterDataList}
            setFilterDropDownInfo={setFilterDropDownInfo}
            setPaginationInfo={(value) => {
              setPagination({
                ...pagination,
                page: value.current,
              })
            }}
            setSortInfo={setSortInfo}
          />
        </Card>
      </div>
    </AuthenticatedLayout>
  )
}

export default MasterDataPage
