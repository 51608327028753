import type { FC } from 'react'

import { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'
import { useApolloClient } from '@apollo/client'

import FullScreenLoading from 'components/FullScreenLoading'

import { appLocalAccessToken, appLocalRefreshToken, clearAppLocal } from 'utils/localService'

import { useGetMyProfileLazyQuery } from 'graphQL/generated/operations'

import type { GetMyProfileData } from 'graphQL/queryResponseTypes'

export interface AuthToken {
  accessToken: string
  refreshToken: string
}

interface AuthContextType {
  token?: AuthToken
  user?: GetMyProfileData
  signIn: (authToken: AuthToken, callback?: VoidFunction) => void
  signOut: VoidFunction
}

const AuthContext = createContext<any>(null)

export const AuthProvider: FC = ({ children }) => {
  const { t } = useTranslation('auth')

  const client = useApolloClient()

  const accessToken = appLocalAccessToken.get()

  const [authLoading, setAuthLoading] = useState(accessToken != null)
  const [token, setToken] = useState<AuthToken>()
  const [user, setUser] = useState<GetMyProfileData>()

  const [getMyProfile, getMyProfileResp] = useGetMyProfileLazyQuery({
    onCompleted({ getMyProfile }) {
      const profile = getMyProfile.payload

      setUser(profile)
      setAuthLoading(false)
    },
  })

  const signIn = (authToken: AuthToken, callback?: VoidFunction) => {
    setToken(authToken)

    appLocalAccessToken.set(authToken.accessToken)
    appLocalRefreshToken.set(authToken.refreshToken)

    getMyProfile().then(callback)
  }

  const signOut = () => {
    clearAppLocal()
    client.resetStore()
  }

  const value: AuthContextType = {
    token,
    user,
    signIn,
    signOut,
  }

  useEffectOnce(onFirstSignIn)

  return (
    <AuthContext.Provider value={value}>
      {getMyProfileResp.loading || authLoading ? (
        <FullScreenLoading>{t('prepareUserData')}</FullScreenLoading>
      ) : (
        children
      )}
    </AuthContext.Provider>
  )

  function onFirstSignIn() {
    if (accessToken == null) {
      return
    }

    getMyProfile()
  }
}

export default function useAuth() {
  return useContext<AuthContextType>(AuthContext)
}
