import { Space } from 'antd'

import type { IActionNameDashboardProps } from './types'

function ActionNameDashboard({ img, name }: IActionNameDashboardProps): JSX.Element {
  return (
    <Space size={24} align="center" className="organization-name-container">
      <img src={img} alt={name} className="organization-image" />
      <p className="organization-name">{name}</p>
    </Space>
  )
}

export default ActionNameDashboard
