import type { FC } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import LoadingIcon from './LoadingIcon'

const FullScreenLoading: FC = ({ children }) => {
  return createPortal(
    <FullScreenLoadingWrapper>
      <LoadingIcon>{children}</LoadingIcon>
    </FullScreenLoadingWrapper>,
    document.body
  )
}

export default FullScreenLoading

const FullScreenLoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`
