import type { FC } from 'react'

import { useTranslation } from 'react-i18next'

import useAuth from 'contexts/useAuthContext'

import useGenerateOAuthLink from 'hooks/useGenerateOAuthLink'

import FullScreenLoading from 'components/FullScreenLoading'

import { goToSignInPage } from 'config'

const SignOutPage: FC = () => {
  const { t } = useTranslation()
  const auth = useAuth()

  const oauth = useGenerateOAuthLink()

  if (oauth.url) {
    auth.signOut()

    goToSignInPage(oauth.url)
  }

  return <FullScreenLoading>{t('auth:signingOut')}</FullScreenLoading>
}

export default SignOutPage
