import { CoreServiceDashBoard } from 'components/Dashboard/Service/index'

function ServiceFeartureForm(): JSX.Element {
  const dataSource = [
    {
      key: 'feature',
      featurekey: 'REGISTER',
      description: 'User must register',
      status: 'false',
    },
    {
      key: 'elearning',
      featurekey: 'EMAIL_LOGIN',
      description: 'User can login with email',
      status: 'true',
    },
  ]
  return <CoreServiceDashBoard dataSource={dataSource} dataIndex="featurekey" title="Feature Key" />
}

export default ServiceFeartureForm
