import React from 'react'
import { Avatar } from 'antd'
import styled from 'styled-components'
import { NotificationIconProps, NotificationIconType } from './interface'
import FontAwesomeIcon from 'components/FontawesomeIcon'

const NotificationIcon: React.FC<NotificationIconProps> = ({ type, iconName }) => {
  return <StyledNotificationIcon type={type} size={50} icon={<FontAwesomeIcon iconName={iconName} size="lg" />} />
}

function getNotificationIconColor(type: NotificationIconType) {
  switch (type) {
    case 'success':
      return '#84D600'
    case 'info':
      return '#2699FB'
    case 'warning':
      return '#ffcc00'
    case 'error':
    default:
      return '#ff0000'
  }
}

export default NotificationIcon

const StyledNotificationIcon = styled(Avatar)<{ type: NotificationIconType }>`
  background-color: ${(p) => getNotificationIconColor(p.type)};
  vertical-align: middle;
`
