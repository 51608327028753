import { gql } from '@apollo/client'

const INVITE_ROLE = gql`
  query getInviteRole($orgKey: String!) {
    getInviteRole(orgKey: $orgKey) {
      payload {
        roleList {
          name
          roleKey
        }
      }
    }
  }
`

export default INVITE_ROLE
