import { OrganizationStatus } from 'graphQL/schemaType/organization'
import { Space } from 'antd'

import FontawesomeIcon from '../FontawesomeIcon'

import type { ApprovalStatusProps } from './types'

function ApprovalStatus({ status }: ApprovalStatusProps): JSX.Element {
  function renderIcon(approveStatus: OrganizationStatus): string {
    switch (approveStatus) {
      case 'APPROVED':
        return 'user-check'
      case 'NEED_MORE_INFORMATION':
      case 'REVIEWING':
        return 'user-clock'
      default:
      case 'REJECTED':
      case 'SUSPENDED':
        return 'times-circle'
    }
  }

  function renderApprovalTitle(approveStatus: OrganizationStatus): string {
    switch (approveStatus) {
      case 'APPROVED':
        return 'Verified'
      case 'REJECTED':
        return 'Rejected'
      case 'SUSPENDED':
        return 'Suspended'
      case 'REVIEWING':
        return 'Reviewing'
      default:
      case 'NEED_MORE_INFORMATION':
        return 'Need more information'
    }
  }

  function renderColor(approveStatus: OrganizationStatus): string {
    switch (approveStatus) {
      case 'APPROVED':
        return '#5cb85c'
      case 'NEED_MORE_INFORMATION':
      case 'REVIEWING':
        return '#f0ad4e'
      default:
      case 'REJECTED':
        return '#d9534f'
      case 'SUSPENDED':
        return '#d9534f'
    }
  }

  return (
    <div className="status-table-container">
      <Space direction="horizontal" size={8}>
        <FontawesomeIcon
          iconName={renderIcon(status)}
          style={{
            color: renderColor(status),
            fontSize: '1.5rem',
          }}
        />
        <Space direction="vertical" size={0}>
          <h1
            style={{
              color: renderColor(status),
            }}
          >
            {renderApprovalTitle(status)}
          </h1>
        </Space>
      </Space>
    </div>
  )
}

export default ApprovalStatus
