import { Button, Popconfirm, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import AdministratorTable from 'components/Table/Administrator'
import TableColumnFormat from 'components/Table/ColumnFormat'
import TotalDataSourceText from 'components/Table/TotalDataSourceText'
import Status from 'components/Status'

import person from 'assets/images/person.png'

import useAuth from 'contexts/useAuthContext'

import ActionNameDashboard from '../utils/ActionNameDashboard'

import type { ColumnType } from 'antd/lib/table'
import type { MemberOrganizationAPIPayload } from 'graphQL/useGetMemberOrganization/interface'
import type { MemberProps } from './interface'

type OrganizationMemberParams = {
  orgKey: string
}

const Member: React.FC<MemberProps> = ({ memberList, canDelete, onDeleteUserOrganization }) => {
  const auth = useAuth()

  console.log('memberList: ', memberList)

  const noDataText = '- ไม่มีข้อมูล -'

  const { t } = useTranslation()

  const params = useParams<OrganizationMemberParams>() as OrganizationMemberParams

  const columns: ColumnType<MemberOrganizationAPIPayload>[] = [
    {
      title: () => <TableColumnFormat title={t('administrator:name')} subtitle="Display name" />,
      dataIndex: 'administratorName',
      render: (_text, record, index) => {
        const fullName =
          record.attribute?.firstName && record.attribute?.lastName
            ? `${record.attribute?.firstName} ${record.attribute?.lastName}`
            : noDataText
        return (
          <span key="manage" className="organization-action-link">
            <ActionNameDashboard
              key={`AdministratorName-${index}`}
              img={record.attribute?.picture || person}
              name={fullName}
            />
          </span>
        )
      },
    },
    {
      title: () => <TableColumnFormat title={t('administrator:email')} subtitle="Email address" />,
      dataIndex: 'administratorEmail',
      render: (_text, record) => <Space size={12}>{record.email[0]?.value || noDataText}</Space>,
    },
    {
      title: () => <TableColumnFormat title={t('administrator:status')} subtitle="Active / Deactive" />,
      dataIndex: 'administratorStatus',
      render: (_text, record) => (
        <Space size={12}>
          <Status key="status" status={record.email[0]?.verifyStatus || noDataText} />
        </Space>
      ),
    },
    {
      dataIndex: 'administratorAction',
      render: (_text, record) => {
        if (!canDelete || auth.user?._id === record._id) {
          return null
        }

        return (
          <Popconfirm title="คุณแน่ใจที่จะลบไหม?" onConfirm={() => onDeleteUserOrganization(record._id, params.orgKey)}>
            <Button type="primary" danger>
              {t('administrator:DeleMember')}
            </Button>
          </Popconfirm>
        )
      },
    },
  ]

  return (
    <>
      <AdministratorTable<MemberOrganizationAPIPayload> dataSource={memberList || []} columns={columns} rowKey="_id" />
      <TotalDataSourceText total={(memberList || []).length} />
    </>
  )
}

export default Member
