import { useQuery } from '@apollo/client'

import GET_CUSTOM_MENU from './getCustomMenu'

import { onDefaultErrorMessage } from 'utils/apollo'

import type { APIPayloadResponse, FindDataInput, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'

import type { CustomMenuAPIPayload } from './interface'

interface CustomMenuData {
  getCustomMenu: APIPayloadResponse<CustomMenuAPIPayload[]>
}

interface OrganizationVars {
  input?: FindDataInput
}

const useGetCustomMenu: GraphQLServiceQueryHook<CustomMenuData, OrganizationVars> = (options) => {
  return useQuery(GET_CUSTOM_MENU, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetCustomMenu
