import { useQuery } from '@apollo/client'

import { onDefaultErrorMessage } from 'utils/apollo'

import QUERY_DOCUMENT from './getOrganizationApproval'

import type {
  APIPayloadResponseWithPagination,
  FindDataInput,
  GraphQLServiceQueryHook,
} from 'graphQL/graphQL-service-hook'
import type { OrganizationAPIPayload } from 'graphQL/useGetOrganization/interface'

interface OrganizationApprovalData {
  getOrganizationApproval: APIPayloadResponseWithPagination<OrganizationAPIPayload[]>
}

interface OrganizationApprovalVars {
  input: FindDataInput
}

const useGetOrganizationApproval: GraphQLServiceQueryHook<OrganizationApprovalData, OrganizationApprovalVars> = (
  options
) => {
  return useQuery(QUERY_DOCUMENT, {
    onError(error) {
      console.log(error)
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetOrganizationApproval
