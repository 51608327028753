import { useQuery, useSubscription } from '@apollo/client'
import { getUserTypeEndpoint } from 'utils/user'
import { appLocalAccessToken } from 'utils/localService/app'
import { MY_NOTIFICATION_QUERY, MY_NOTIFICATION_SUBSCRIPTION } from './getMyNotification'
import { GetMyNotificationAPIData, MyNotificactionVars } from './interface.d'
import type { GraphQLServiceQueryHook, GraphQLServiceSubscriptionHook } from 'graphQL/graphQL-service-hook'

export const useGetMyNotificationSubScription: GraphQLServiceSubscriptionHook<
  GetMyNotificationAPIData,
  MyNotificactionVars
> = (options) => {
  return useSubscription(MY_NOTIFICATION_SUBSCRIPTION, {
    context: {
      uri: getUserTypeEndpoint(appLocalAccessToken.get()),
    },
    ...options,
  })
}

export const useGetMyNotificationQuery: GraphQLServiceQueryHook<GetMyNotificationAPIData, MyNotificactionVars> = (
  options
) => {
  return useQuery(MY_NOTIFICATION_QUERY, {
    context: {
      uri: getUserTypeEndpoint(appLocalAccessToken.get()),
    },
    ...options,
  })
}
