import { useQuery } from '@apollo/client'

import QUERY_DOCUMENT from './getLog'

import { onDefaultErrorMessage } from 'utils/apollo'

import type {
  APIPayloadResponseWithPagination,
  FindDataInput,
  GraphQLServiceQueryHook,
} from 'graphQL/graphQL-service-hook'
import type { LogAPIPayload } from './interface'

interface OrganizationData {
  getLog: APIPayloadResponseWithPagination<LogAPIPayload[]>
}

interface LogVars {
  find?: FindDataInput
}

const useGetLog: GraphQLServiceQueryHook<OrganizationData, LogVars> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetLog
