import { useTranslation } from 'react-i18next'
import { Space } from 'antd'

import type { IStatusProps } from './types'
import FontAwesomeIcon from 'components/FontawesomeIcon'

function Status({ status }: IStatusProps): JSX.Element {
  const { t } = useTranslation()

  function renderStatusText(nextStatus: string) {
    switch (nextStatus) {
      case 'VERIFIED':
      case 'ACTIVE':
        return t('organization:status.active')
      case 'APPROVED':
        return t('organization:status.approved')
      case 'DEACTIVE':
        return t('organization:status.deactive')
      case 'BLOCKED':
        return t('organization:status.blocked')
      case 'CLOSED':
        return t('organization:status.closed')
      case 'PREPARING':
        return t('organization:status.preparing')
      case 'NEED_MORE_INFORMATION':
        return t('organization:status.needMoreInformation')
      case 'REJECTED':
        return t('organization:status.declined')
      case 'REVIEWING':
        return t('organization:status.reviewing')
      case 'SUSPENDED':
        return t('organization:status.suspended')
      default:
        return t('organization:status.deactive')
    }
  }
  function renderIcon(status: string): string {
    switch (status) {
      case 'APPROVED':
      case 'VERIFIED':
        return 'user-check'
      case 'NEED_MORE_INFORMATION':
      case 'REVIEWING':
      case 'PREPARING':
        return 'user-clock'
      default:
      case 'REJECTED':
      case 'SUSPENDED':
        return 'times-circle'
    }
  }

  function renderColor(status: string): string {
    switch (status) {
      case 'APPROVED':
      case 'VERIFIED':
        return '#5cb85c'
      case 'NEED_MORE_INFORMATION':
      case 'REVIEWING':
      case 'PREPARING':
        return '#f0ad4e'
      default:
      case 'REJECTED':
        return '#d9534f'
      case 'SUSPENDED':
        return '#d9534f'
    }
  }

  return (
    <div className="status-table-container">
      <FontAwesomeIcon
        iconName={renderIcon(status)}
        style={{
          color: renderColor(status),
          fontSize: '1.5rem',
        }}
      />
      <Space direction="vertical" size={12}>
        <h1
          style={{
            paddingLeft: '1rem',
            color: renderColor(status),
          }}
        >
          {renderStatusText(status)}
        </h1>
      </Space>
    </div>
  )
}

export default Status
