import { Form, FormInstance, Input, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

interface OrganizationInvitationModalProps {
  form: FormInstance<any>
  loading?: boolean
  visible?: boolean
  onClose?: () => void
  onFinish?: (code: string) => void
}

const OrganizationInvitationModal: React.FC<OrganizationInvitationModalProps> = ({
  form,
  loading,
  visible,
  onClose,
  onFinish,
}) => {
  const { t } = useTranslation('organization')
  const invitationCodeTranslateKey = 'inviteOrganizationCode'

  const requiredRule = {
    required: true,
    message: t('global:requiredText'),
  }

  return (
    <Modal
      title={t(`${invitationCodeTranslateKey}.title`)}
      visible={visible}
      onCancel={onClose}
      confirmLoading={loading}
      onOk={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        colon={false}
        onFinish={(values) => onFinish?.(values.code)}
        onFinishFailed={() => message.error(t(`${invitationCodeTranslateKey}.inviteFailedMessage`))}
      >
        <Form.Item label={t(`${invitationCodeTranslateKey}.label`)} name="code" rules={[requiredRule]}>
          <Input placeholder={t(`${invitationCodeTranslateKey}.placeholder`)} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OrganizationInvitationModal
