import { Space, Form, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

function AdminDetailsActionForm(): JSX.Element {
  return (
    <Form
      name="coreservicedetails"
      labelAlign="left"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 12 }}
      className="core-servicedetails-form"
    >
      <Space direction="vertical" size={16}>
        <div>
          Remove this administrator’s data if he requested that, if not please be aware that what has been deleted can
          never brough back
        </div>

        <Form.Item>
          <Button type="primary" danger icon={<DeleteOutlined />}>
            DELETE ACCOUNT
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}

export default AdminDetailsActionForm
