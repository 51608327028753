import { generatePath, Params } from 'react-router-dom'
import { parse, ParsedUrlQueryInput, stringify } from 'querystring'

import { sortMenu } from 'utils/sortMenu'

import type { Role } from 'graphQL/schemaType/role'
import type { AppServiceMenu } from 'hooks/useServiceMenuQuery/types'

const systemPaths = ['/permission/member', '/permissions/invitation', '/organization-setting']

interface RouteToOptions {
  params?: Params<string>
  query?: ParsedUrlQueryInput
}

export const routeTo = (path: string, options?: RouteToOptions) => {
  const url = generatePath(path, options?.params)

  return options?.query ? `${url}?${stringify(options.query)}` : url
}

export const skipGenerateOrgTokenRefByPathname = (pathname: string): boolean => {
  const toRegex = systemPaths.map((item) => item.replaceAll('/', '\\/')).join('|')
  const regex = new RegExp(toRegex)

  const match = pathname.match(regex)

  return Boolean(match?.[0])
}

export const toRoleName = (roleList: Role[] = []) =>
  roleList
    .map((item) => item.name)
    .filter((value) => Boolean(value))
    .join(', ')

export const getDefaultServiceKeyWithOtherQueryString = (query: URLSearchParams, menuList: AppServiceMenu[]) => {
  const newServiceKey = sortMenu(menuList)[0]?.service.serviceKey

  query.set('serviceKey', newServiceKey)

  return parse(query.toString())
}

export const getLocationSearch = (url?: string): string => {
  if (url == null) {
    return ''
  }

  const matches = /\?(.*)/.exec(url)

  return matches?.[0] ? matches[0] : ''
}
