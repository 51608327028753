import { getAppDataFromStorage } from 'utils/app'

import useMasterData from './useMasterData'

const useOrganizationFields = () => {
  const app = getAppDataFromStorage()
  const fieldList = app?.app?.orgFieldList || []

  const listParentKeys = fieldList
    .filter((item) => item.type === 'OPTION' && item.dataKey)
    .map((item) => item.dataKey as string)

  const masterData = useMasterData({
    skip: listParentKeys.length === 0,
    parentKeys: listParentKeys,
  })

  return {
    ...masterData,
    fieldList,
  }
}

export default useOrganizationFields
