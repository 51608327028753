import { gql } from '@apollo/client'

const GET_MY_APP_ROLE = gql`
  query GetMyAppRole {
    getMyAppRole {
      message
      code
      payload {
        name
        roleKey
      }
    }
  }
`

export default GET_MY_APP_ROLE
