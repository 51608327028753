import { useContext } from 'react'
import { Layout } from 'antd'

import AuthenticatedHeaderMenu from 'components/HeaderMenu/AuthenticatedHeaderMenu'
import { SystemAdminSidebar } from 'components/Sidebar/SystemAdmin'

import { ThemeContext } from 'contexts/ThemeContext'

import type { ILayoutProps } from '../types'

const { Sider, Header, Content } = Layout

function SystemAdminLayout({ children }: ILayoutProps): JSX.Element {
  const theme = useContext(ThemeContext)

  function toggle() {
    theme.collapseHandler(!theme.collapsed)
  }

  function getNavLogo() {
    if (theme.collapsed && theme.admin.image?.mainNavLogoCollapsed) {
      return <img className="logo" alt={theme.admin.text?.webTitle} src={theme.admin.image?.mainNavLogoCollapsed} />
    }

    if (!theme.collapsed && theme.admin.image?.navAuthLogo) {
      return <img className="logo" alt={theme.admin.text?.webTitle} src={theme.admin.image.navAuthLogo} />
    }

    return (
      <div
        style={{
          padding: 10,
          height: 64,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {theme.admin.text?.navTitle}
      </div>
    )
  }

  return (
    <Layout style={{ minHeight: '100vh' }} className="authenticated-layout">
      <Sider
        trigger={null}
        collapsible
        collapsed={theme.collapsed}
        theme="light"
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
      >
        {getNavLogo()}

        <SystemAdminSidebar />
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: theme.collapsed ? 80 : 200 }}>
        <Header
          className="authenticated-header"
          style={{ position: 'fixed', zIndex: 1, width: `calc(100% - ${theme.collapsed ? 80 : 200}px)` }}
        >
          <AuthenticatedHeaderMenu collapsed={theme.collapsed} toggle={toggle} />
        </Header>
        <Content style={{ marginTop: 64 }}>
          <div className="authenticated-content-container">{children}</div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default SystemAdminLayout
