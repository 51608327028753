import { useQuery } from '@apollo/client'
import { onDefaultErrorMessage } from 'utils/apollo'

import QUERY_DOCUMENT from './getOrgChildren'

import type { APIPayloadResponse, FindDataInput, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { OrganizationTreePayload } from './interface'

interface OrgChildrenData {
  getOrgChildren: APIPayloadResponse<OrganizationTreePayload[]>
}

interface OrganizationVars {
  input?: FindDataInput
}

const useGetOrgChildren: GraphQLServiceQueryHook<OrgChildrenData, OrganizationVars> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetOrgChildren
