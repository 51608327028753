import { gql } from '@apollo/client'

const CREDENTIAL_BY_HOST = gql`
  query getCredentialByHost($host: String!) {
    getCredentialByHost(host: $host) {
      payload {
        app {
          name
          appKey
        }
        credential {
          credentialKey
        }
      }
    }
  }
`

export default CREDENTIAL_BY_HOST
