import { useState } from 'react'
import { Tree, Spin } from 'antd'

import useGetOrgChildren from 'graphQL/useGetOrgChildren'

interface OrgTreeProp {
  orgKey: string
  name: string
  path: string
  title: string
  key: string
  children: any[]
}

/**
 * Temporary Comment
 */

// interface TreeProp {
//   title: string
//   key: string
//   children: any[]
// }

function OrganizationTree(): JSX.Element {
  const [treeData, setTreeData] = useState<any>([])

  /**
   * Temporary Comment
   */

  // const checkDataTitle = (value: OrgTreeProp) => {
  //   if (value.children.length) {
  //     return (
  //       value.title +
  //       ` (${new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(value.children.length || 0)})`
  //     )
  //   } else {
  //     return value.title
  //   }
  // }

  const orgTree = useGetOrgChildren({
    variables: {
      input: {
        query: {
          orgKey: '',
        },
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted(resp) {
      setTreeData(
        resp.getOrgChildren.payload.map((item: OrgTreeProp) => {
          return {
            title: item.title,
            key: item.key,
            children: item.children,
          }
        })
      )
    },
    onError(error) {
      console.log(error)
    },
  })

  return (
    <>
      {!orgTree.loading ? (
        <Tree showLine treeData={treeData} height={400} defaultExpandAll />
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" className="w-100 text-center" />
        </div>
      )}
    </>
  )
}

export default OrganizationTree
