import type { FC } from 'react'

import { useState } from 'react'
import { Button, Pagination, Spin, message, Form, Radio, Tooltip, Space } from 'antd'
import {
  PlusCircleOutlined,
  UsergroupAddOutlined,
  UnorderedListOutlined,
  FolderOutlined,
  ApartmentOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ContentCard from 'components/Card/Content'
import OrganizationField from 'components/Dashboard/Organization/OrganizationField'
import SearchInput from 'components/Input/Search'
import PageTitle from 'components/PageTitle'
import OrganizationInvitationModal from 'components/OrganizationInvitationModal'
import FullScreenLoading from 'components/FullScreenLoading'
import OrganizationDirectory from 'components/Organization/OrganizationDirectory'
import OrganizationTree from 'components/Organization/OrganizationTree'
import OrganizationStatusFilterButton from 'components/OrganizationStatusFilterButton'

import usePermission from 'hooks/usePermission'
import useCheckFeatureKeyListEnable from 'hooks/useCheckFeatureKeyListEnable'
import useAntdPagination from 'hooks/useAntdPagination'
import useQueryString from 'hooks/useQueryString'

import { checkPermissionCanEditData } from 'utils/permission'
import { numberWithComma } from 'utils/formatter'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import useGetOrganization from 'graphQL/useGetOrganization'
import useInviteOrganizationCode from 'graphQL/useInviteOrganizationCode'

import { paths } from 'setup/PageRouter'

const OrganizationPage: FC = () => {
  const { t } = useTranslation()

  const checkFeatureKeyEnable = useCheckFeatureKeyListEnable({
    featureKeyList: ['ORG_INVITE_CODE', 'PARENT_ORGANIZATION'],
  })

  const permissionOrgTree = usePermission({
    permissionKey: 'TREE_ORGANIZATION',
    code: ['READ'],
  })

  const permissionOrgManagement = usePermission({
    permissionKey: 'ORG_MANAGEMENT',
    code: ['READ', 'EDIT'],
  })

  const permissionOrgDirectory = usePermission({
    permissionKey: 'ORGANIZATION_DIRECTORY',
    code: ['READ'],
  })

  const [form] = Form.useForm()

  const loading = false

  const navigate = useNavigate()

  const [searchVal, setSearchVal] = useState('')

  const [defaultFlatList, setDefaultFlatList] = useState(true)
  const [isShowOrgTree, setIsShowOrgTree] = useState(false)

  const query = useQueryString()

  const status = query.get('status') || 'APPROVED'

  const { pagination, pageSizeOptions, createPagination, changeToFirstPage, onChange } = useAntdPagination()

  const organizationQuery = useGetOrganization({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        search: {
          name: searchVal,
        },
        pagination: {
          limit: pagination.limit,
          page: pagination.page,
        },
        sort: {
          updatedAt: -1,
        },
        filter: {
          status: status === 'ALL' ? undefined : status,
        },
      },
    },
    onCompleted(resp) {
      createPagination(resp.getOrganization.pagination)
    },
    onError(error) {
      console.log(error)
    },
  })

  const isLoading = organizationQuery.loading
  const organizationData = organizationQuery.data?.getOrganization

  const [modalVisible, setModalVisible] = useState(false)

  const [inviteOrganizationCode, inviteOrganizationCodeResp] = useInviteOrganizationCode({
    onCompleted() {
      setModalVisible(false)
      setSearchVal('')

      changeToFirstPage()

      organizationQuery.refetch()

      onInvitationCodeFinish(t('organization:inviteOrganizationCode.inviteSuccess'))

      form.resetFields()
    },
    onError(error) {
      setModalVisible(false)
      message.error(error.message)
      console.log('[Organization]:', error.message)

      form.resetFields()
    },
  })

  const onChangeFlatList = (e: any) => {
    if (e.target.value === 'groupByOrg') {
      setDefaultFlatList(false)
    } else {
      setDefaultFlatList(true)
    }
  }

  const checkTitleName = () => {
    if (isShowOrgTree) {
      return t('organization:page.treeOrganization')
    } else if (!defaultFlatList) {
      return t('organization:page.organizationDirectory')
    } else {
      return t('organization:page.home')
    }
  }

  const checkDataButton = () => {
    const isPermissionOrgTree = checkPermissionCanEditData({
      code: 'READ',
      permissionKey: 'TREE_ORGANIZATION',
      permissionsList: permissionOrgTree.myPermissions,
    })

    const isPermissionOrgDirectory = checkPermissionCanEditData({
      code: 'READ',
      permissionKey: 'ORGANIZATION_DIRECTORY',
      permissionsList: permissionOrgDirectory?.myPermissions,
    })

    const isPermissionOrgManagement = checkPermissionCanEditData({
      code: 'EDIT',
      permissionKey: 'ORG_MANAGEMENT',
      permissionsList: permissionOrgManagement?.myPermissions,
    })

    const buttonArr = []

    if (isPermissionOrgTree) {
      buttonArr.push(
        <Tooltip key="treeOrganization" title={t('organization:page.treeOrganization')}>
          <Button
            icon={<ApartmentOutlined />}
            hidden={!checkFeatureKeyEnable.ORG_INVITE_CODE}
            onClick={() => setIsShowOrgTree(true)}
          >
            {/* {t('organization:page.inviteOrganizationCode')} */}
          </Button>
        </Tooltip>
      )
    }

    if (isPermissionOrgDirectory) {
      buttonArr.push(
        <Radio.Group key="orgDirectory" onChange={onChangeFlatList}>
          <Radio.Button value="orgList" style={{ zIndex: 0 }} >
            <Tooltip title={t('organization:page.flatList')}>
              <UnorderedListOutlined />
            </Tooltip>
          </Radio.Button>
          <Radio.Button value="groupByOrg"  style={{ zIndex: 0 }} >
            <Tooltip title={t('organization:page.groupByOrganization')}>
              <FolderOutlined />
            </Tooltip>
          </Radio.Button>
        </Radio.Group>
      )
    }

    buttonArr.push(
      <Button
        key="inviteOrganization"
        icon={<UsergroupAddOutlined />}
        hidden={!checkFeatureKeyEnable.ORG_INVITE_CODE}
        onClick={() => setModalVisible(true)}
      >
        {t('organization:page.inviteOrganizationCode')}
      </Button>
    )

    if (isPermissionOrgManagement) {
      buttonArr.push(
        <Button
          key="createOrganization"
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => navigate(paths.organizationCreate)}
        >
          {t('organization:create')}
        </Button>
      )
    }

    return buttonArr
  }

  return (
    <AuthenticatedLayout>
      {loading ? (
        <FullScreenLoading />
      ) : (
        <>
          <div className="admin-content-container">
            <PageTitle isRootPage title={checkTitleName()} extra={checkDataButton()} />

            {isShowOrgTree ? (
              <OrganizationTree />
            ) : !defaultFlatList ? (
              <OrganizationDirectory></OrganizationDirectory>
            ) : (
              <ContentCard
                loading={isLoading}
                title={`${t('organization:contentTitle.dashboard')} `}
                rightComponent={
                  <Space size="large">
                    <OrganizationStatusFilterButton onChange={changeToFirstPage} />

                    <SearchInput
                      onSearch={(value) => {
                        changeToFirstPage()

                        setSearchVal(value)
                      }}
                      placeholder={t('organization:search:placeholder')}
                    />
                  </Space>
                }
              >
                {!isLoading && organizationData ? (
                  <>
                    <OrganizationField organizations={organizationData?.payload} />
                    <div>
                      <Pagination
                        className="admin-content-container-pagination"
                        pageSize={pagination.limit}
                        current={pagination.page}
                        total={pagination?.totalItems}
                        showTotal={(total) => `${t('organization:table.record')}: ${numberWithComma(total)}`}
                        onChange={onChange}
                        hideOnSinglePage
                        pageSizeOptions={pageSizeOptions}
                      />
                    </div>
                  </>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <Spin size="large" className="w-100 text-center" />
                  </div>
                )}
              </ContentCard>
            )}
          </div>

          <OrganizationInvitationModal
            form={form}
            loading={inviteOrganizationCodeResp.loading}
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
            onFinish={(inviteCode) => {
              inviteOrganizationCode({
                variables: {
                  inviteCode,
                },
              })
            }}
          />
        </>
      )}
    </AuthenticatedLayout>
  )

  function onInvitationCodeFinish(messageKey: string) {
    setTimeout(() => {
      message.success(messageKey)
    }, 350)
  }
}

export default OrganizationPage
