import { gql } from '@apollo/client'

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($orgKey: String!, $parentOrgKey: String, $input: INPUT_ORGANIZATION_UPDATE) {
    updateOrganization(orgKey: $orgKey, parentOrgKey: $parentOrgKey, input: $input) {
      message
    }
  }
`

export default UPDATE_ORGANIZATION
