import dayjs from 'dayjs'

type SorterValue = string | number | Date

export const antdSorter = (a: SorterValue, b: SorterValue): number => {
  if (a instanceof Date && b instanceof Date) {
    return dayjs(a).unix() - dayjs(b).unix()
  }

  return -1
}
