import MUTATION_DOCUMENT from './deleteUserOrganizationByUserId'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import { useMutation } from '@apollo/client'

interface DeleteUserOrganizationData {
  deleteUserOrganizationByUserId: APIPayloadResponse<{ _id: string }>
}

interface DeleteUserOrganizationVars {
  userId: string
  orgKey: string
}

const useDeleteUserOrganization: GraphQLServiceMutationHook<DeleteUserOrganizationData, DeleteUserOrganizationVars> = (
  options
) => {
  return useMutation(MUTATION_DOCUMENT, {
    ...options,
  })
}

export default useDeleteUserOrganization
