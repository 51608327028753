import { useMutation } from '@apollo/client'

import { getUserTypeEndpoint } from 'utils/user'
import { appLocalAccessToken } from 'utils/localService'
import { onDefaultErrorMessage } from 'utils/apollo'

import MUTATION_DOCUMENT from './updateOrgStatus'

import { APIPayloadResponse, GraphQLServiceMutationHook, OrganizationStatus } from 'graphQL/graphQL-service-hook'

interface UpdateOrgStatusData {
  updateOrgStatus: APIPayloadResponse<{ orgKey: string }>
}

interface UpdateOrgStatusVars {
  orgKey: string
  status?: OrganizationStatus
}

const useUpdateOrgStatus: GraphQLServiceMutationHook<UpdateOrgStatusData, UpdateOrgStatusVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    context: {
      uri: getUserTypeEndpoint(appLocalAccessToken.get()),
    },
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useUpdateOrgStatus
