import { useQuery } from '@apollo/client'
import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import { Role } from 'graphQL/schemaType/role'
import { onDefaultErrorMessage } from 'utils/apollo'
import GET_MY_ORG_ROLE from './getMyOrgRole'

interface RoleData {
  getMyOrgRole: APIPayloadResponse<Array<Role>>
}

interface OrganizationVars {
  orgKey: string
}

const useGetMyOrgRole: GraphQLServiceQueryHook<RoleData, OrganizationVars> = (options) => {
  return useQuery(GET_MY_ORG_ROLE, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetMyOrgRole
