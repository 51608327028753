import type { FC } from 'react'

import { useState } from 'react'
import { Tabs, Row, Col, Card } from 'antd'
import { FormOutlined } from '@ant-design/icons'

import SystemAdminLayout from 'layouts/SystemAdminLayout'

import {
  ServiceDetailsInfoForm,
  ServiceDetailsActionForm,
  ServiceFeartureForm,
  ServicePermissionForm,
  ServiceLogsForm,
} from 'components/Form/Service/index'
import PageTitle from 'components/PageTitle'
import ContentCard from 'components/Card/Content/Content'
import SearchInput from 'components/Input/Search'

const { TabPane } = Tabs

const SystemAdminCoreServicePage: FC = () => {
  const [searchVal, setSearchVal] = useState('')

  function onServiceSearch(searchValue: string) {
    setSearchVal(searchValue)
    console.log(searchVal)
  }

  return (
    <SystemAdminLayout>
      <div className="admin-content-container">
        <>
          <PageTitle title="Hooray Core Service" />

          <Tabs defaultActiveKey="details" size="large" tabBarGutter={50}>
            <TabPane tab="DETAILS" key="details" style={{}}>
              <Row gutter={20}>
                <Col span={10}>
                  <Card title="Information" extra={<FormOutlined />}>
                    <ServiceDetailsInfoForm />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Other actions">
                    <ServiceDetailsActionForm />
                  </Card>
                </Col>
              </Row>
            </TabPane>

            <TabPane tab="FEARTURES" key="feartures">
              <ContentCard title="FEARTURES">
                <ServiceFeartureForm />
              </ContentCard>
            </TabPane>

            <TabPane tab="PERMISSIONS" key="permission">
              <ContentCard title="PERMISSIONS">
                <ServicePermissionForm />
              </ContentCard>
            </TabPane>

            <TabPane tab="LOGS" key="log">
              <ContentCard
                title="LOGS"
                rightComponent={<SearchInput onSearch={onServiceSearch} placeholder="Search" />}
              >
                <ServiceLogsForm />
              </ContentCard>
            </TabPane>
          </Tabs>
        </>
      </div>
    </SystemAdminLayout>
  )
}

export default SystemAdminCoreServicePage
