import { checkUserPermission } from './permission'

import type { IMenuListConfig, SubMenuItem } from 'components/Sidebar/types'
import type { MenuWithService } from 'hooks/useServiceMenuQuery/types'

type MenuType = 'ORG' | 'APP'

interface MappedMenuListOptions {
  locale?: string
  type?: MenuType
  orgKey?: string
}

export const mappedMenuList = (
  menuList: MenuWithService[],
  { locale = 'th', type = 'ORG', orgKey }: MappedMenuListOptions
) => {
  const listMenus = menuList.map(({ menu, service, permissions }) => {
    const menuList: IMenuListConfig[] = (menu?.menuList || [])
      .filter((item) => checkUserPermission(item.permissionList, permissions))
      .map((item) => {
        const linkTo = getLinkToPathname(item.path, type, orgKey)
        const menuKey = item.path ? item.path : item.name[locale]
        const subMenus: SubMenuItem[] | undefined = item.subMenuList
          ?.map((subMenuItem) => ({
            key: subMenuItem.path,
            linkTo: getLinkToPathname(subMenuItem.path, type, orgKey),
            title: subMenuItem.name[locale],
            permissionList: subMenuItem.permissionList,
          }))
          .filter((item) => checkUserPermission(item.permissionList, permissions))

        return {
          key: menuKey,
          subKey: menuKey,
          iconName: item.icon,
          linkTo,
          title: item.name[locale],
          permissionList: item.permissionList,
          subMenus,
        }
      })

    return {
      ...menu,
      service,
      menuList,
    }
  })

  return listMenus
}

const getLinkToPathname = (path: string, type = 'ORG', orgKey?: string) => {
  return type === 'ORG' && orgKey != null ? `/organization/${orgKey}/${path}` : `/app/${path}`
}
