import type { MasterDataSelection } from 'hooks/useMasterData/interface'

interface SelectOption {
  label: string
  value: string
}

export const toSelectOptions = (masterData: MasterDataSelection): SelectOption[] => {
  return Object.values(masterData).map((item) => ({
    label: item.text,
    value: item.dataKey,
  }))
}
