import { useState } from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Select, Space, Table } from 'antd'
import styled from 'styled-components'

import SearchInput from 'components/Input/Search'
import ManageCredentialModal from './ManageCredentialModal'

const { Option } = Select

function ManageServices() {
  const [openManage, setOpenManage] = useState(false)

  const onCloseManage = () => {
    setOpenManage(false)
  }

  const columns = [
    {
      title: 'Function',
      dataIndex: 'functionName',
      key: 'functionName',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'IP Address',
      dataIndex: 'ipAddress',
      key: 'ipAddress',
    },
    {
      title: 'Timestamp',
      dataIndex: 'timeStamp',
      key: 'timeStamp',
    },
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_text: any, render: any) => (
        <span key="status-render" className="service-status">
          <Space size={5} style={{ color: 'green', fontSize: '15px' }}>
            <CheckCircleOutlined />
            {render.status}
          </Space>
        </span>
      ),
    },
  ]

  const data = [
    {
      key: '1',
      name: 'Hooray Core Service',

      status: 'Active',
      credentialKey: 'GMI7iBbvQ9BNXRST4fZOMVOIR2lBadUu',
      request: 367,

      functionName: 'getApplcation V1.0',
      action: 'GET',
      ipAddress: '130.145.10.214',
      timeStamp: '16/09/2020 | 22.21.20',
      service: 'Hooray Core Service',
    },
    {
      key: '2',
      name: 'Production',

      status: 'Active',
      credentialKey: 'GRAh2xOmCL4o8obsFDHTXvJ8byTksZPk',
      request: 20,

      functionName: 'createPermission V1.0',
      action: 'CREATE',
      ipAddress: '191.67.127.230',
      timeStamp: '16/09/2020 | 22.21.20',
      service: 'Hooray Core Service',
    },
    {
      key: '2',
      name: 'Production',
      status: 'Active',
      credentialKey: 'GRAh2xOmCL4o8obsFDHTXvJ8byTksZPk',
      request: 20,

      functionName: 'updateProfile V1.0',
      action: 'UPDATE',
      ipAddress: '233.174.65.97',
      timeStamp: '16/09/2020 | 22.21.20',
      service: 'Hooray Core Service',
    },
  ]
  return (
    <>
      <TopTableWrapper>
        <SearchWrapper>
          <SearchInput onSearch={() => {}} placeholder="Search" />
        </SearchWrapper>
        <Select defaultValue="ALL" style={{ width: 200, marginLeft: '0.5rem' }} onChange={() => {}}>
          <Option value="ALL">All Service</Option>
          <Option value="HOORAY-CORE">Hooray Core Service</Option>
          <Option value="HOORAY-CRM">Hooray Crm Service</Option>
        </Select>
      </TopTableWrapper>
      <Table dataSource={data} columns={columns} />
      <ManageCredentialModal visible={openManage} onClose={onCloseManage} />
    </>
  )
}

const TopTableWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`

const SearchWrapper = styled.div`
  width: 40%;
`

export default ManageServices
