import { useQuery } from '@apollo/client'

import { getUserTypeEndpoint } from 'utils/user'
import { appLocalAccessToken } from 'utils/localService'
import { onDefaultErrorMessage } from 'utils/apollo'

import QUERY_DOCUMENT from './getMasterData'

import type {
  APIPayloadResponseWithPagination,
  FindDataInput,
  GraphQLServiceQueryHook,
} from 'graphQL/graphQL-service-hook'
import type { MasterDataAPIPayload } from './interface'

interface MasterData {
  getMasterData: APIPayloadResponseWithPagination<MasterDataAPIPayload[]>
}

interface MasterDataVars {
  input?: FindDataInput
}

const useGetMasterData: GraphQLServiceQueryHook<MasterData, MasterDataVars> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    context: {
      uri: getUserTypeEndpoint(appLocalAccessToken.get()),
    },
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetMasterData
