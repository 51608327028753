import { useEffect, useState } from 'react'

import { GET_ORG_FIELD } from 'graphQL/schemas/organization'

import apolloClient from 'constants/initializeApolloClient'

import useServiceMenuQuery from 'hooks/useServiceMenuQuery'

import OrganizationDirectoryDataList from './OrganizationDirectoryDataList'

import { AppServiceMenu } from 'hooks/useServiceMenuQuery/types'

import type { IOrganizationProps } from 'components/Dashboard/Organization/types'

const OrganizationFieldDirectory: React.FC<IOrganizationProps> = ({ organizations, setPathOrg, setOrgName }) => {
  const orgKeys: string[] | undefined = organizations?.map((e: { orgKey: string }): string => e.orgKey)

  const { appMenuList = [] } = useServiceMenuQuery()

  const [orgField, setOrgField] = useState([])
  const [isFetchCompleted, setFetchCompleted] = useState(false)

  useEffect(() => {
    async function loopQuery() {
      Promise.all(
        appMenuList.map(async (serviceMenu: AppServiceMenu) => {
          try {
            const query = await apolloClient.query({
              query: GET_ORG_FIELD,
              variables: {
                orgKeyList: orgKeys,
              },
              fetchPolicy: 'network-only',
              context: {
                uri: `${serviceMenu.service.endpoint.gql.resourceAdmin}`,
              },
            })
            return query.data.getOrgField.payload
          } catch (e) {
            return null
          }
        })
      ).then((res) => {
        const orgFieldArray: any = []
        res.forEach((element: any[]) => {
          if (element) orgFieldArray.push(...element)
        })

        setOrgField(orgFieldArray)
        setFetchCompleted(true)
      })
    }

    if (orgKeys && orgKeys.length > 0 && !isFetchCompleted) {
      loopQuery()
    }
  }, [orgKeys, appMenuList, isFetchCompleted])

  if (!organizations) {
    return null
  }

  return (
    <OrganizationDirectoryDataList
      organizations={organizations}
      orgField={orgField}
      setPathOrg={setPathOrg}
      setOrgName={setOrgName}
    />
  )
}

export default OrganizationFieldDirectory
