import {
  CheckCircleOutlined,
  UserOutlined,
  BlockOutlined,
  CloseOutlined,
  CloseCircleFilled,
  ClockCircleOutlined,
} from '@ant-design/icons'
import { Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import type { OrganizationSettingStatusProps } from './interface'

const { Text } = Typography

function OrganizationSettingStatus({ status, date, approver }: OrganizationSettingStatusProps): JSX.Element {
  const { t } = useTranslation()

  function renderIconAccountStatus(accountStatus?: string) {
    switch (accountStatus) {
      case 'APPROVED':
        return <CheckCircleOutlined className="primary-color" />
      case 'REJECTED':
        return <CloseCircleFilled className="danger-color" />
      case 'BLOCKED':
        return <BlockOutlined className="danger-color" />
      case 'CLOSED':
        return <CloseOutlined className="danger-color" />
      case 'PREPARING':
        return <ClockCircleOutlined className="reviewing-color" />
      default:
      case 'REVIEWING':
        return <UserOutlined className="reviewing-color" />
    }
  }

  function renderTextAccountStatus(accountStatus?: string) {
    switch (accountStatus) {
      case 'APPROVED':
        return <Text className="primary-color">{t('organization:status.approved')}</Text>
      case 'REJECTED':
        return <Text className="danger-color">{t('organization:status.rejected')}</Text>
      case 'SUSPENDED':
        return <Text className="danger-color">{t('organization:status.suspended')}</Text>
      case 'CLOSED':
        return <Text className="danger-color">{t('organization:status.closed')}</Text>
      case 'PREPARING':
        return <Text className="reviewing-color">{t('organization:status.preparing')}</Text>
      case 'NEED_MORE_INFORMATION':
        return <Text className="reviewing-color">{t('organization:status.needMoreInformation')}</Text>
      default:
      case 'REVIEWING':
        return <Text className="reviewing-color">{t('organization:status.reviewing')}</Text>
    }
  }

  return (
    <Space direction="vertical" size={0}>
      <Space size={16} align="center">
        {renderTextAccountStatus(status)}
        {renderIconAccountStatus(status)}
      </Space>
    </Space>
  )
}

export default OrganizationSettingStatus
