import { useState } from 'react'
import { CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Space, Table } from 'antd'
import styled from 'styled-components'

import SearchInput from 'components/Input/Search'
import ManageCredentialModal from './ManageCredentialModal'

function ManageSetting() {
  const [openManage, setOpenManage] = useState(false)

  const onCloseManage = () => {
    setOpenManage(false)
  }

  const columns = [
    {
      title: 'Setting Key',
      dataIndex: 'settingKey',
      key: 'settingKey',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_text: any, render: any) => (
        <span key="status-render" className="service-status">
          <Space size={5} style={{ color: 'green', fontSize: '15px' }}>
            <CheckCircleOutlined />
            {render.status}
          </Space>
        </span>
      ),
    },
  ]

  const data = [
    {
      key: '1',
      settingKey: 'AWS_SMS',
      description: 'AWS Credential for SNS',
      status: 'Active',
    },
    {
      key: '2',
      settingKey: 'AWS_S3',
      description: 'AWS Credential for S3',
      status: 'Active',
    },
  ]
  return (
    <>
      <TopTableWrapper>
        <SearchWrapper>
          <SearchInput onSearch={() => {}} placeholder="Search" />
        </SearchWrapper>
        <Button
          type="primary"
          key="create"
          onClick={() => {
            setOpenManage(true)
          }}
        >
          <Space size={12}>
            <PlusCircleOutlined /> New Setting
          </Space>
        </Button>
      </TopTableWrapper>
      <Table dataSource={data} columns={columns} />
      <ManageCredentialModal visible={openManage} onClose={onCloseManage} />
    </>
  )
}

const TopTableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const SearchWrapper = styled.div`
  width: 40%;
`

export default ManageSetting
