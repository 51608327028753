import React, { CSSProperties, useState } from 'react'
import L from 'leaflet'
import { Col, Form, Input, Row } from 'antd'
import { MapContainer, TileLayer } from 'react-leaflet'
import { useTranslation } from 'react-i18next'

import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import LocationMarker from './LocationMarker'
import { defaultPosition } from 'config'

import type { MapForm, Position } from './interface'
import styled from 'styled-components'
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

const mapContainerStyle: CSSProperties = {
  width: '100%',
  height: '400px',
  position: 'relative',
}

const Map: React.FC<MapForm> = ({ form, shouldBeEdit }) => {
  const { t } = useTranslation()

  const [position, setPosition] = useState<Position>(
    form.getFieldValue(['position', 'lng']) && form.getFieldValue(['position', 'lat'])
      ? {
          lng: form.getFieldValue(['position', 'lng']),
          lat: form.getFieldValue(['position', 'lat']),
        }
      : defaultPosition
  )

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <div style={mapContainerStyle}>
          <MapContainer center={position} zoom={13} style={mapContainerStyle}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker
              currentPosition={position}
              positionHandler={(position) => {
                setPosition(positionWithToFixedValue(position, form))
              }}
            />
          </MapContainer>
          {!shouldBeEdit && <MapOverlayWrapper />}
        </div>
      </Col>

      <Col span={12}>
        <Form.Item name={['position', 'lat']}>
          <Input placeholder={t('organization:new.latitude')} disabled={true} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name={['position', 'lng']}>
          <Input placeholder={t('organization:new.longitude')} disabled={true} />
        </Form.Item>
      </Col>
    </Row>
  )
}
function positionWithToFixedValue(position: Position, form: any): Position {
  const lat = Number(position.lat.toFixed(6))
  const lng = Number(position.lng.toFixed(6))

  form.setFieldsValue({
    position: {
      lat,
      lng,
    },
  })

  return {
    lat,
    lng,
  }
}

export default Map

const MapOverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999999;
`
