import type { FC } from 'react'

import { useState } from 'react'
import { Tabs, Row, Col, Card } from 'antd'
import { FormOutlined } from '@ant-design/icons'

import SystemAdminLayout from 'layouts/SystemAdminLayout'

import PageTitle from 'components/PageTitle'
import ContentCard from 'components/Card/Content/Content'
import SearchInput from 'components/Input/Search'
import SystemAdministratorLog from 'components/Dashboard/SystemAdmin/SystemAdministratorLog'
import { AdminDetailsAction, AdminDetailsinfo } from 'components/Form/Administrator'

const { TabPane } = Tabs

const SystemAdminAdministratorDetailPage: FC = () => {
  const [searchVal, setSearchVal] = useState('')

  function onServiceSearch(searchValue: string) {
    setSearchVal(searchValue)
    console.log(searchVal)
  }

  return (
    <SystemAdminLayout>
      <div className="admin-content-container">
        <>
          <PageTitle title="Administrator Details" />

          <Tabs defaultActiveKey="details" size="large" tabBarGutter={50}>
            <TabPane tab="DETAILS" key="details" style={{}}>
              <Row gutter={20}>
                <Col span={10}>
                  <Card title="Information" extra={<FormOutlined />}>
                    <AdminDetailsinfo />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Other actions">
                    <AdminDetailsAction />
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="LOGS" key="log">
              <ContentCard
                title="LOGS"
                rightComponent={<SearchInput onSearch={onServiceSearch} placeholder="Search" />}
              >
                <SystemAdministratorLog />
              </ContentCard>
            </TabPane>
          </Tabs>
        </>
      </div>
    </SystemAdminLayout>
  )
}

export default SystemAdminAdministratorDetailPage
