import type { FC } from 'react'

import PageTitle from 'components/PageTitle'
import OrganizationApproval from 'components/OrganizationApproval'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

const OrgApprovalPage: FC = () => {
  return (
    <AuthenticatedLayout>
      <div className="admin-content-container">
        <PageTitle title="Organization Approval" />
        <OrganizationApproval />
      </div>
    </AuthenticatedLayout>
  )
}

export default OrgApprovalPage
