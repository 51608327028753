import { gql } from '@apollo/client'

export const READ_NOTIFICATION = gql`
  mutation readNotification($notificationIdList: [String!]!) {
    readNotification(notificationIdList: $notificationIdList) {
      message
      code
      unread
      pagination {
        limit
        page
        totalItems
        totalPages
      }
      payload {
        _id
        title
        content
        attribute
        icon
        type
        from
        to
        read
        createdAt
        updatedAt
      }
    }
  }
`
