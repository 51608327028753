import { Table } from 'antd'
import { SystemServiceTableProps } from './types'

function SystemServiceTable({ columns, dataSource, rowKey }: SystemServiceTableProps): JSX.Element {
  return (
    <Table dataSource={dataSource} columns={columns} rowKey={rowKey} className="coreservice-table" pagination={false} />
  )
}

export default SystemServiceTable
