import { Menu } from 'antd'
import FontawesomeIcon from 'components/FontawesomeIcon'
import { usePermissionContext } from 'contexts/PermissionContext'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { checkUserPermission } from 'utils/permission'

import type { IMenuListConfig, IRoute } from '../types'

const route: IRoute = {
  '/app-role/role-setting': '0',
  '/app-role/user-field': '1',
  '/app-role/security-role': '2',
  '/app-role/permission': '3',
}

function AppRole({ roleKey }: { roleKey: string }): JSX.Element {
  const location = useLocation()

  const { pathname } = location

  const currentRoute = route[pathname]

  const { myPermissions } = usePermissionContext()

  const overviewMenuList: IMenuListConfig[] = [
    {
      key: '0',
      iconName: 'cog',
      linkTo: '/app-role/role-setting?role_key=' + roleKey,
      title: 'Role setting',
      permissionList: [
        {
          code: ['READ'],
          permissionKey: 'ROLE_MANAGEMENT',
        },
      ],
    },
    {
      key: '1',
      iconName: 'file-alt',
      linkTo: '/app-role/user-field?role_key=' + roleKey,
      title: 'User field',
      permissionList: [
        {
          code: ['READ'],
          permissionKey: 'ROLE_MANAGEMENT',
        },
      ],
    },
    {
      key: '2',
      iconName: 'shield-alt',
      linkTo: '/app-role/security-role?role_key=' + roleKey,
      title: 'Security role',
      permissionList: [
        {
          code: ['READ'],
          permissionKey: 'DATA_SECURITY_MANAGEMENT',
        },
      ],
    },
    {
      key: '3',
      iconName: 'lock',
      linkTo: '/app-role/permission?role_key=' + roleKey,
      title: 'Permission',
      permissionList: [
        {
          code: ['READ'],
          permissionKey: 'PERMISSION_MANAGEMENT',
        },
      ],
    },
  ]
  return (
    <Menu
      theme="light"
      className="organization-menu-container"
      mode="inline"
      defaultSelectedKeys={['0']}
      selectedKeys={[currentRoute]}
    >
      <Menu.Divider />
      {overviewMenuList
        .filter((item) => checkUserPermission(item.permissionList || [], myPermissions))
        .map((menu) => (
          <Menu.Item key={menu.key} icon={<FontawesomeIcon iconName={menu.iconName} />}>
            <span>{menu.title}</span>
            <Link to={menu.linkTo} />
          </Menu.Item>
        ))}
    </Menu>
  )
}

export default AppRole
