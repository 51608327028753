import { Input } from 'antd'
import { useTranslation } from 'react-i18next'

import type { ISearchInputProps } from './types'

const { Search } = Input

const SearchInput: React.FC<ISearchInputProps> = ({ loading, onSearch, placeholder }) => {
  const { t } = useTranslation()

  return (
    <Search
      placeholder={placeholder || t('button:search').concat('...')}
      allowClear
      enterButton={t('button:search')}
      size="large"
      loading={loading}
      onSearch={onSearch}
    />
  )
}

export default SearchInput
