import { Menu } from 'antd'
import { Link, generatePath, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import FontawesomeIcon from 'components/FontawesomeIcon'

import { usePermissionContext } from 'contexts/PermissionContext'

import { checkUserPermission } from 'utils/permission'

import { paths } from 'setup/PageRouter'

import type { ISystemOrganizationSidebarProps } from './types'
import type { IMenuListConfig, IRoute } from '../types'

function System({ allMenuLength, orgKey }: ISystemOrganizationSidebarProps): JSX.Element {
  const route: IRoute = {
    permission: `${allMenuLength + 1}`,
    member: `${allMenuLength + 2}`,
    invitation: `${allMenuLength + 3}`,
    'organization-setting': `${allMenuLength + 4}`,
    'history-log': `${allMenuLength + 5}`,
  }

  const { state } = useLocation()

  const permission = usePermissionContext()

  const routeAsArray = window.location.pathname.split('/')
  const currentRoute = route[routeAsArray[routeAsArray.length - 1]]

  const { t } = useTranslation('sidebar')

  const systemMenus: IMenuListConfig[] = [
    {
      key: route.permission,
      linkTo: paths.organizationMember,
      title: 'permission',
      iconName: 'user-circle',
      subKey: 'permissionSubMenu',
      subMenus: [
        {
          key: route['member'],
          linkTo: generatePath(paths.organizationMember, {
            orgKey,
          }),
          title: 'member',
          permissionList: [
            {
              permissionKey: 'ORG_MEMEBER',
              code: ['READ'],
            },
          ],
        },
        {
          key: route['invitation'],
          linkTo: generatePath(paths.organizationInvitation, {
            orgKey,
          }),
          title: 'invitation',
          permissionList: [
            {
              permissionKey: 'INVITE_USER_ORGANIZATION',
              code: ['READ', 'EDIT'],
            },
          ],
        },
      ],
    },
    {
      key: route['organization-setting'],
      linkTo: generatePath(paths.organizationSetting, {
        orgKey,
      }),
      title: 'organizationSetting',
      iconName: 'user-circle',
    },
    {
      key: route['history-log'],
      linkTo: generatePath(paths.organizationHistory, {
        orgKey,
      }),
      title: 'logAndHistory',
      iconName: 'clock',
      permissionList: [
        {
          permissionKey: 'USER_LOG_SYSTEM',
          code: ['READ'],
        },
      ],
    },
  ]

  return (
    <Menu
      theme="light"
      className="organization-menu-container"
      defaultSelectedKeys={['0']}
      mode="inline"
      selectedKeys={[currentRoute]}
      defaultOpenKeys={['permissionSubMenu']}
    >
      <Menu.Item disabled key={'system'}>
        System
      </Menu.Item>
      {systemMenus
        .filter((item) => checkUserPermission(item.permissionList || [], permission.myPermissions))
        .map((menu) => {
          if (menu.subMenus && menu.subMenus.length > 0) {
            return (
              <Menu.SubMenu key={menu.subKey} icon={<FontawesomeIcon iconName={menu.iconName} />} title={t(menu.title)}>
                {menu.subMenus
                  .filter((item) => checkUserPermission(item.permissionList || [], permission.myPermissions))
                  .map((subMenu) => (
                    <Menu.Item key={subMenu.key}>
                      <span>{t(subMenu.title)}</span>
                      <Link to={{ pathname: subMenu.linkTo }} state={state} />
                    </Menu.Item>
                  ))}
              </Menu.SubMenu>
            )
          }

          return (
            <Menu.Item key={menu.key} icon={<FontawesomeIcon iconName={menu.iconName} />}>
              <span>{t(menu.title)}</span>
              <Link to={{ pathname: `${menu.linkTo}` }} state={state} />
            </Menu.Item>
          )
        })}
    </Menu>
  )
}

export default System
