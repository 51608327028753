import type { FC } from 'react'

import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Form, Space } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import AppRoleLayout from 'layouts/AppRole'
import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import useQueryParams from 'hooks/useQueryParams'

import ContentCard from 'components/Card/Content'
import PageTitle from 'components/PageTitle'
import UserFieldModel from 'components/Modal/AppRole/UserField'
import RoleSettingPage from './RoleSettingPage'
import DataSecurityRolePage from './DataSecurityRolePage'
import UserFieldPage from './UserFieldPage'

import useUpdateRole from 'graphQL/useUpdateRole/useUpdateRole'
import useGetRole from 'graphQL/useGetRole/useGetRole'

type PageKey = 'role-setting' | 'user-field' | 'security-role' | 'permission'
type AppRoleParamsPage = {
  readonly page: PageKey
}

const AppRoleSettingPage: FC = () => {
  const roleKey = useQueryParams('role_key')

  const [form] = Form.useForm()

  const params = useParams<AppRoleParamsPage>() as AppRoleParamsPage

  const [isShowDialogUserField, setIsShowDialogUserField] = useState(false)

  const onCancelUserField = () => {
    setIsShowDialogUserField(false)
  }

  const [updateRole] = useUpdateRole({
    onCompleted() {
      form.resetFields()
      getRole.refetch()
      setIsShowDialogUserField(false)
    },
  })

  const getRole = useGetRole({
    fetchPolicy: 'network-only',
  })

  const roleList = getRole.data?.getRole?.payload?.roleList || []

  const role = roleList.find((e) => e.roleKey === roleKey)

  const onFinish = (values: any) => {
    const newValues = {
      ...values,
      required: values.required === 'YES' ? true : false,
    }
    let userField = [...(role?.userField || [])]
    if (!userField) userField = [newValues]
    else userField.push(newValues)
    updateRole({
      variables: {
        roleKey: role?.roleKey || '',
        roleInput: {
          userField: userField,
        },
      },
    })
  }

  return (
    <AuthenticatedLayout>
      {roleKey && (
        <AppRoleLayout roleKey={roleKey}>
          <div className="admin-content-container">{renderPanelByPageKey(params.page)}</div>
        </AppRoleLayout>
      )}
    </AuthenticatedLayout>
  )

  function renderPanelByPageKey(pageKey: PageKey) {
    switch (pageKey) {
      case 'user-field':
        return (
          <>
            <PageTitle title="" />
            <UserFieldModel
              onCancel={onCancelUserField}
              isShowDialog={isShowDialogUserField}
              onFinish={onFinish}
              form={form}
            />
            <ContentCard
              title={`User Field ${role?.name}`}
              rightComponent={
                <Button
                  type="primary"
                  key="create"
                  onClick={() => {
                    setIsShowDialogUserField(true)
                  }}
                >
                  <Space size={12}>
                    <PlusCircleOutlined />
                    {/* {t('appRole:create')} */} Add Field
                  </Space>
                </Button>
              }
            >
              {role && <UserFieldPage role={role} refetch={getRole.refetch} />}
            </ContentCard>
          </>
        )
      case 'security-role':
        return (
          <>
            <PageTitle title="" />
            <ContentCard title={`Data Security Role ${role?.name}`}>
              {roleKey && <DataSecurityRolePage roleKey={roleKey} />}
            </ContentCard>
          </>
        )
      default:
      case 'role-setting':
        return (
          <>
            <PageTitle title={''} />
            <ContentCard title={`Role Setting ${role?.name}`}>{role && <RoleSettingPage role={role} />}</ContentCard>
          </>
        )
    }
  }
}

export default AppRoleSettingPage
