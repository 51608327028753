import { useQuery } from '@apollo/client'

import { onDefaultErrorMessage } from 'utils/apollo'

import QUERY_DOCUMENT from './getMemberOrganization'

import type { APIPayloadResponse, FindDataInput, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { MemberOrganizationAPIPayload } from './interface'

interface MemberOrganizationData {
  getMemberOrganization: APIPayloadResponse<MemberOrganizationAPIPayload[]>
}

interface MemberOrganizationVars {
  orgKey: string
  input?: FindDataInput
}

const useGetMemberOrganization: GraphQLServiceQueryHook<MemberOrganizationData, MemberOrganizationVars> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetMemberOrganization
