import { useMutation } from '@apollo/client'

import { onDefaultErrorMessage } from 'utils/apollo'

import UPDATE_ROLE from './updateRole'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import { RoleAPIPayload, RoleStatusType } from 'graphQL/schemaType/role'

interface ReleData {
  createRole: APIPayloadResponse<RoleAPIPayload>
}
export type RoleType = 'RESOURCE_ADMIN' | 'RESOURCE_OWNER' | ''
export interface RoleInput {
  name?: string
  roleKey?: string
  attribute?: any
  userField?: any
  roleType?: RoleType
  status?: RoleStatusType
  regisable?: RoleStatusType
  isDefault?: RoleStatusType
  isReplaceable?: RoleStatusType
  isInvite?: RoleStatusType
}
interface CreateRoleVars {
  roleKey: string | {}
  roleInput: RoleInput
}

const useUpdateRole: GraphQLServiceMutationHook<ReleData, CreateRoleVars> = (options) => {
  return useMutation(UPDATE_ROLE, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useUpdateRole
