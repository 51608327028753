import { useEffect } from 'react'
import { Card } from 'antd'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import FitContentIFrame from 'components/FitContentIFrame'

import useGenerateAuthCode from 'graphQL/useGenerateAuthCode'

import { replacePath } from 'utils/mapText'
import { localeCode } from 'utils/localService'
import { onDefaultErrorMessage } from 'utils/apollo'

import type { Widget as WidgetItem } from 'hooks/useAppServiceWidget'

interface WidgetProps {
  widget: WidgetItem
  pattern: string
  endpoint: string
  startDate: string
  endDate: string
  orgKey: string
}

const Widget: React.FC<WidgetProps> = ({ widget, pattern, endpoint, startDate, endDate, orgKey }) => {
  const { i18n } = useTranslation()
  const localeIsEn = i18n.language === localeCode.enUS

  const location = useLocation()

  const [generateAuthCode, generateAuthCodeResp] = useGenerateAuthCode({
    variables: {
      orgKey,
    },
    onError(error) {
      onDefaultErrorMessage(error)
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const generateAuthCodeData = generateAuthCodeResp.data?.generateAuthCode.payload

  const currentWidgetURL = replacePath(pattern, {
    endpoint,
    authorization_code: generateAuthCodeData?.code,
    path: widget.path,
    credentialKey: generateAuthCodeData?.credentialKey,
    startDate: startDate,
    endDate: endDate,
    locale: localeIsEn ? 'en' : 'th',
  })

  const { ratio } = widget.display

  useEffect(() => {
    generateAuthCode()
  }, [generateAuthCode, location.pathname, startDate, endDate])

  return (
    <WidgetCardContainer style={widget.display.style || undefined}>
      {generateAuthCodeData?.code && (
        <FitContentIFrame src={currentWidgetURL} title={widget.name[localeIsEn ? 'en' : 'th']} ratio={ratio} />
      )}
    </WidgetCardContainer>
  )
}

export default Widget

const WidgetCardContainer = styled(Card)`
  height: 100%:
`
