import { useQuery } from '@apollo/client'
import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import { Role } from 'graphQL/schemaType/role'
import { onDefaultErrorMessage } from 'utils/apollo'
import GET_MY_APP_ROLE from './getMyAppRole'

interface RoleData {
  getMyAppRole: APIPayloadResponse<Array<Role>>
}

const useGetMyAppRole: GraphQLServiceQueryHook<RoleData> = (options) => {
  return useQuery(GET_MY_APP_ROLE, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetMyAppRole
