import { useQuery } from '@apollo/client'

import GET_ROLE from './getRole'

import { onDefaultErrorMessage } from 'utils/apollo'

import type { APIPayloadResponse, FindDataInput, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { RoleAPIPayload } from '../schemaType/role'

interface ReleData {
  getRole: APIPayloadResponse<RoleAPIPayload>
}

interface OrganizationVars {
  input?: FindDataInput
}

const useGetRole: GraphQLServiceQueryHook<ReleData, OrganizationVars> = (options) => {
  return useQuery(GET_ROLE, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetRole
