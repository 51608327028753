import { useMutation } from '@apollo/client'

import { onDefaultErrorMessage } from 'utils/apollo'

import MUTATION_DOCUMENT from './inviteOrganizationCode'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'

interface InviteOrganizationCodeData {
  inviteOrganizationCode: APIPayloadResponse<{ orgKey: string }>
}

interface InviteOrganizationCodeVars {
  inviteCode: string
}

const useInviteOrganizationCode: GraphQLServiceMutationHook<InviteOrganizationCodeData, InviteOrganizationCodeVars> = (
  options
) => {
  return useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useInviteOrganizationCode
