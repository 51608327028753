import { useQuery } from '@apollo/client'

import GET_DATA_SECURITY_ROLE from './getDataSecurityRole'

import { onDefaultErrorMessage } from 'utils/apollo'

import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
type DataSecurityType = 'USER' | 'ORGANIZATION' | 'SYSTEM' | 'PARENT_CHILD' | 'NONE' | ''
export interface DataSecurityRoleAPIPayload {
  name: string
  securityKey: string
  create: DataSecurityType
  read: DataSecurityType
  write: DataSecurityType
  delete: DataSecurityType
  assign: DataSecurityType
  assignToParent: 'YES' | 'NO' | undefined | ''
}
interface DataSecurityRoleData {
  getDataSecurityRole: APIPayloadResponse<DataSecurityRoleAPIPayload>
}

interface DataSecurityRoleVars {
  roleKey: string
}

const useGetDataSecurityRole: GraphQLServiceQueryHook<DataSecurityRoleData, DataSecurityRoleVars> = (options) => {
  return useQuery(GET_DATA_SECURITY_ROLE, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetDataSecurityRole
