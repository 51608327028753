import { gql } from '@apollo/client'

const ORGANIZATION_TOKEN_REF = `
  ref
  organization {
    name
    orgKey
  }
`

export const GENERATE_ORG_TOKEN_REF = gql`
  query generateOrgTokenRef($orgKey: String!) {
    generateOrgTokenRef(orgKey: $orgKey) {
      message
      code
      payload {
        ${ORGANIZATION_TOKEN_REF}
      }
    }
  }
`

export default GENERATE_ORG_TOKEN_REF
