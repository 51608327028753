import { gql } from '@apollo/client'

const DELETE_INVITATION = gql`
  mutation deleteInvite($inviteId: ID!, $orgKey: String!) {
    deleteInvite(inviteId: $inviteId, orgKey: $orgKey) {
      message
      payload {
        _id
      }
    }
  }
`

export default DELETE_INVITATION
