import { message } from 'antd'

import apolloClient from 'setup/apolloClient'

import type { ApolloError } from '@apollo/client'
import type { GraphQLError } from 'graphql'

export const isTokenExpiredError = (graphQLErrors: ReadonlyArray<GraphQLError>) => {
  if (!Array.isArray(graphQLErrors)) {
    return
  }

  return graphQLErrors[0]?.extensions?.code === 'TOKEN_IS_EXPIRED'
}

export const onDefaultErrorMessage = (error: ApolloError) => {
  if (isTokenExpiredError(error.graphQLErrors)) {
    apolloClient.refetchQueries({
      include: 'active',
    })
  } else {
    if (error.graphQLErrors && error.message !== 'Failed to fetch') {
      message.error(`[Default Graphql Error]: ${error.message}`)
    }

    if (error.networkError) {
      console.log(`[Default Network Error]: ${error.networkError}`)
      if (typeof error.networkError === 'string' && /401/.test(error.networkError)) {
        console.log('Default Network Error 401, do something')
      }
    }
  }
}
