import { gql } from '@apollo/client'

const TOKEN_AUTO_CODE = gql`
  mutation getTokenAuthCode($code: String!) {
    getTokenAuthCode(code: $code) {
      payload {
        token {
          accessToken
          refreshToken
        }
      }
    }
  }
`

export default TOKEN_AUTO_CODE
