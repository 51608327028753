import { Select, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

import useMasterData from 'hooks/useMasterData'

import OtherProvinceInput from './OtherProvinceInput'

import { onFilterSelect } from 'utils/antdUtils'
import { toSelectOptions } from './utils'

interface ProvinceInputProps {
  disabled?: boolean
}

const ProvinceInput: React.FC<ProvinceInputProps> = ({ disabled }) => {
  const { t } = useTranslation('province')

  const masterData = useMasterData({
    parentKeys: ['PROVINCE'],
  })

  const ruleRequired = {
    required: true,
    message: t('global:requiredText'),
  }

  const options = masterData.masterDataList.PROVINCE ? toSelectOptions(masterData.masterDataList.PROVINCE) : []

  return (
    <>
      <Form.Item noStyle shouldUpdate={() => true}>
        {(form) => (
          <Form.Item label={t('province')} name="province" rules={[ruleRequired]}>
            <Select
              disabled={disabled}
              style={{ width: '100%' }}
              loading={masterData.loading}
              showSearch
              options={options}
              filterOption={onFilterSelect}
              onSelect={() => {
                form.setFields([
                  {
                    name: 'district',
                    value: undefined,
                  },
                  {
                    name: 'subDistrict',
                    value: undefined,
                  },
                  {
                    name: 'postCode',
                    value: undefined,
                  },
                ])
              }}
            />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.province !== nextValues.province}>
        {(form) => {
          const parentKey = form.getFieldValue('province')

          return (
            <OtherProvinceInput
              selectProps={{
                disabled: parentKey == null || disabled,
                onSelect: () => {
                  form.setFields([
                    {
                      name: 'subDistrict',
                      value: undefined,
                    },
                    {
                      name: 'postCode',
                      value: undefined,
                    },
                  ])
                },
              }}
              parentKey={parentKey}
              name="district"
              label={t('district')}
              rules={[ruleRequired]}
            />
          )
        }}
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.district !== nextValues.district}>
        {(form) => {
          const parentKey = form.getFieldValue('district')

          return (
            <OtherProvinceInput
              selectProps={{
                disabled: parentKey == null || disabled,
              }}
              parentKey={parentKey}
              name="subDistrict"
              label={t('subDistrict')}
              rules={[ruleRequired]}
            />
          )
        }}
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.subDistrict !== nextValues.subDistrict}>
        {(form) => {
          const parentKey = form.getFieldValue('subDistrict')

          return (
            <Form.Item name="postCode" label={t('postCode')} rules={[ruleRequired]}>
              <Input disabled={parentKey == null || disabled} />
            </Form.Item>
          )
        }}
      </Form.Item>
    </>
  )
}

export default ProvinceInput
