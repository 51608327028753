import type { FC } from 'react'

import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ContentCard from 'components/Card/Content'
import SearchInput from 'components/Input/Search'
import PageTitle from 'components/PageTitle'
import { MemberDashboard } from 'components/Dashboard/Member'

import InnerOrganizationLayout from 'layouts/InnerOrganizationLayout'

import useGetMemberOrganization from 'graphQL/useGetMemberOrganization'
import useDeleteUserOrganization from 'graphQL/useDeleteUserOrganization'
import FullWidthSpace from 'components/FullWidthSpace'
import usePermission from 'hooks/usePermission'
import { checkPermissionCanEditData } from 'utils/permission'

type OrganizationMemberPageParams = {
  orgKey: string
}

const OrganizationMemberPage: FC = () => {
  const [searchValue, setSearchValue] = useState('')

  const params = useParams<OrganizationMemberPageParams>() as OrganizationMemberPageParams

  const { t } = useTranslation()

  const permissionOrgTree = usePermission({
    permissionKey: 'TREE_ORGANIZATION',
    code: ['READ'],
  })

  const isPermissionOrgMember = checkPermissionCanEditData({
    code: 'READ',
    permissionKey: 'ORG_MEMEBER',
    permissionsList: permissionOrgTree?.myPermissions,
  })
  const isPermissionOrgMemberEdit = checkPermissionCanEditData({
    code: 'EDIT',
    permissionKey: 'ORG_MEMEBER',
    permissionsList: permissionOrgTree?.myPermissions,
  })

  const memberOrganizationQuery = useGetMemberOrganization({
    variables: {
      orgKey: params.orgKey,
      input: {
        search: {
          'email.value': searchValue,
        },
      },
    },
  })

  const [deleteUserOrganization] = useDeleteUserOrganization({
    onCompleted() {
      memberOrganizationQuery.refetch()
    },
  })

  const onDeleteUserOrganization = (userId: string, orgKey: string) => {
    if (isPermissionOrgMemberEdit) {
      deleteUserOrganization({
        variables: {
          userId,
          orgKey,
        },
      })
    }
  }

  return (
    <InnerOrganizationLayout>
      <div className="admin-content-container">
        <PageTitle title={t('administrator:title')} />
        {isPermissionOrgMember > 0 ? (
          <ContentCard
            loading={memberOrganizationQuery.loading}
            title={t('administrator:contentCardTitle')}
            rightComponent={<SearchInput onSearch={(value) => setSearchValue(value)} />}
          >
            {!memberOrganizationQuery.loading && (
              <MemberDashboard
                memberList={memberOrganizationQuery.data?.getMemberOrganization.payload}
                canDelete={isPermissionOrgMemberEdit > 0}
                onDeleteUserOrganization={onDeleteUserOrganization}
              />
            )}
          </ContentCard>
        ) : (
          <FullWidthSpace style={{ justifyContent: 'center' }}>
            <h1>Permission Required!</h1>
          </FullWidthSpace>
        )}
      </div>
    </InnerOrganizationLayout>
  )
}

export default OrganizationMemberPage
