import { useCallback, useState } from 'react'

import OrganizationDashboard from 'components/Dashboard/Organization'

import { GET_ORG_FIELD } from 'graphQL/schemas/organization'

import apolloClient from 'setup/apolloClient'

import useServiceMenuQuery from 'hooks/useServiceMenuQuery'

import type { AppServiceMenu } from 'hooks/useServiceMenuQuery/types'
import type { IOrganizationProps } from './types'

const OrganizationField: React.FC<IOrganizationProps> = ({ organizations }) => {
  const orgKeys: string[] | undefined = organizations?.map((e: { orgKey: string }): string => e.orgKey)

  useServiceMenuQuery({
    onCompleted(cacheAppMenuList) {
      // getOrgField(cacheAppMenuList)
    },
  })

  const [orgField, setOrgField] = useState([])

  // const getOrgField = useCallback(
  //   async (cacheAppMenuList: AppServiceMenu[]) => {
  //     const orgFieldArray: any = []
  //     for (const serviceMenu of cacheAppMenuList) {
  //       try {
  //         const query = await apolloClient.query({
  //           query: GET_ORG_FIELD,
  //           variables: {
  //             orgKeyList: orgKeys,
  //           },
  //           fetchPolicy: 'network-only',
  //           context: {
  //             uri: `${serviceMenu.service.endpoint.gql.resourceAdmin}`,
  //           },
  //         })
  //         orgFieldArray.push(...query.data.getOrgField.payload)
  //       } catch (e) {
  //         return null
  //       }
  //     }
      // setOrgField(orgFieldArray || [])
  //   },
  //   [orgKeys]
  // )

  if (!organizations) {
    return null
  }

  return <OrganizationDashboard organizations={organizations} orgField={orgField} />
}

export default OrganizationField
