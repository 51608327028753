import { useEffect, useRef, useState } from 'react'

import LoadingIcon from 'components/LoadingIcon'

import { getIFrameHeightWithRatio } from 'utils/display'

interface FitContentIFrameProps {
  title: string
  src: string
  ratio: string | number
}

const FitContentIFrame: React.FC<FitContentIFrameProps> = ({ title, src, ratio }) => {
  const [loading, setLoading] = useState(true)
  const [iframeOffsetWidth, setIFrameOffsetWidth] = useState(0)

  const iframeRef = useRef<HTMLIFrameElement | null>()

  useEffect(() => {
    const handleResize = () => {
      setIFrameOffsetWidth(iframeRef.current?.offsetWidth as number)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <>
      {loading && <LoadingIcon />}
      <iframe
        src={src}
        title={title}
        ref={(node) => (iframeRef.current = node)}
        onLoad={() => {
          setIFrameOffsetWidth(iframeRef.current?.offsetWidth as number)
          setLoading(false)
        }}
        width="100%"
        allowFullScreen
        style={{
          height: getIFrameHeightWithRatio(iframeOffsetWidth, ratio),
          border: 'none',
        }}
      />
    </>
  )
}

export default FitContentIFrame
