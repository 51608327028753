import { ColumnsType } from 'antd/lib/table'
import person from 'assets/images/person.png'
import AdministratorTable from 'components/Table/Administrator'
import TotalDataSourceText from 'components/Table/TotalDataSourceText'
import dayjs from 'dayjs'
import { LogAPIPayload } from 'graphQL/useGetLog/interface'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ActionNameDashboard from '../utils/ActionNameDashboard'

interface historyProps {
  logData: LogAPIPayload[]
}

function LogAndHistory({ logData }: historyProps): JSX.Element {
  const { t } = useTranslation()
  const noDataText = `${t('global:noData')}`
  const renderAction = (value: string) => {
    let dataReturn = ''
    switch (value) {
      case 'LOGIN':
        dataReturn = `${t('historyLog:login')}`
        break
      case 'LOGOUT':
        dataReturn = `${t('historyLog:logout')}`
        break
      default:
        dataReturn = value
    }
    return <span>{dataReturn}</span>
  }

  const columns: ColumnsType<LogAPIPayload> = [
    {
      title: () => <strong>{t('historyLog:action')}</strong>,
      dataIndex: 'action',
      render: (action) => renderAction(action),
    },
    {
      title: () => <strong>{t('historyLog:timestamp')}</strong>,
      dataIndex: 'createdAt',
      render: (_text, record) => <span>{dayjs(record.createdAt).format('DD/MM/YYYY | HH:mm:ss')}</span>,
    },
    {
      title: () => <strong>{t('historyLog:displayName')}</strong>,
      dataIndex: 'administratorName',
      render: (_text, record, index) => {
        const fullName =
          record.nextData?.attribute?.firstName && record.nextData?.attribute?.lastName
            ? `${record.nextData?.attribute?.firstName} ${record.nextData?.attribute?.lastName}`
            : noDataText
        return (
          <span key="manage" className="organization-action-link">
            <ActionNameDashboard
              key={`AdministratorName-${index}`}
              img={record.nextData?.attribute?.picture || person}
              name={fullName}
            />
          </span>
        )
      },
    },
  ]

  return (
    <>
      <AdministratorTable dataSource={logData} columns={columns} rowKey="_id" />
      <TotalDataSourceText total={logData.length} />
    </>
  )
}

export default LogAndHistory
