import { CheckCircleOutlined, CloseCircleOutlined, SaveOutlined, RedoOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Space } from 'antd'
import { useState } from 'react'

import type { IForm } from '../types'

const { Option } = Select

function ApplocationForm({ onFinish, loading }: IForm): JSX.Element {
  const [appKey, setAppKey] = useState('')
  const randomAppKey = () => {
    setAppKey('1234567890-')
  }
  // const [form] = Form.useForm()

  // useEffect(() => {
  //   form.setFieldsValue({
  //     username: 'Bamboo',
  //   })
  // }, [appKey])
  return (
    <Form
      name="login"
      onFinish={onFinish}
      labelAlign="left"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 12 }}
      requiredMark={false}
      // form={form}
    >
      <Space direction="vertical" size={16}>
        <Form.Item label="Logo" help="Display Logo" name="logo" initialValue="John Doe">
          <Input />
        </Form.Item>

        <Form.Item label="Name" help="Application name" name="name" initialValue="name">
          <Input />
        </Form.Item>
        <Form.Item label="App Key" help="App Reference Key" name="appKey" initialValue="">
          <Input
            value={appKey}
            addonAfter={<Button type="link" onClick={() => randomAppKey()} icon={<RedoOutlined />}></Button>}
          />
        </Form.Item>
        <Form.Item label="Status" name="status" initialValue="active" help="Application Status">
          <Select>
            <Option value="active">
              <Space size={4}>
                <CheckCircleOutlined className="primary-color" /> <span className="primary-color">Enable</span>
              </Space>
            </Option>
            <Option value="deactive">
              <Space size={4}>
                <CloseCircleOutlined className="danger-color" /> <span className="danger-color">Disable</span>
              </Space>
            </Option>
          </Select>
        </Form.Item>

        <Form.Item className="mb-0" wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" loading={loading} icon={<SaveOutlined />}>
            Add Application
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}

export default ApplocationForm
