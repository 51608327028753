import { gql } from '@apollo/client'

const DELETE_ORG = gql`
  mutation DeleteOrganization($orgKey: String!) {
    deleteOrganization(orgKey: $orgKey) {
      message
      code
    }
  }
`

export default DELETE_ORG
