import { ObjectPath } from './types'
/* eslint-disable guard-for-in */
export const replacePath = (text: string, obj: ObjectPath | any, params?: ObjectPath) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    text = text.replace(`\${${key}}`, obj[key])
  }
  if (params) {
    if (text.indexOf('?') < 0) {
      text = `${text}?`
    }
    for (const key in params) {
      if (key === '?credentialKey') continue

      text = `${text}&${key}=${params[key]}`
    }
  }
  return text
}
