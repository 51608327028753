import type { FC } from 'react'

import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import querystring from 'querystring'

import FullScreenLoading from 'components/FullScreenLoading'

import useQueryParams from 'hooks/useQueryParams'
import useServiceMenuQuery from 'hooks/useServiceMenuQuery'
import useQueryString from 'hooks/useQueryString'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import { replacePath } from 'utils/mapText'

import useGenerateAuthCode from 'graphQL/useGenerateAuthCode'

import { onDefaultErrorMessage } from 'utils/apollo'
import { getDefaultServiceKeyWithOtherQueryString, routeTo } from 'helpers/utils'

type IFrameApplicationParams = {
  authCode: string
  slug: string
}

const IFrameApplicationPage: FC = () => {
  const { i18n } = useTranslation()

  const serviceKey = useQueryParams('serviceKey')
  const action = useQueryParams('action')
  const id = useQueryParams('id')

  const params = useParams<IFrameApplicationParams>() as IFrameApplicationParams

  const query = useQueryString()

  const navigate = useNavigate()
  const location = useLocation()

  const { loading, appMenuList = [] } = useServiceMenuQuery()

  const [generateAuthCode, generateAuthCodeResp] = useGenerateAuthCode({
    onError(error) {
      onDefaultErrorMessage(error)
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const generateAuthCodeData = generateAuthCodeResp.data?.generateAuthCode.payload

  if ((!serviceKey || serviceKey === 'undefined') && appMenuList.length > 0) {
    navigate(
      routeTo(window.location.pathname, {
        query: getDefaultServiceKeyWithOtherQueryString(query, appMenuList),
      }),
      {
        replace: true,
      }
    )
  }

  useEffect(() => {
    generateAuthCode()
  }, [generateAuthCode, location.pathname])
  

  return (
    <AuthenticatedLayout>
      {generateAuthCodeResp.loading || loading || generateAuthCodeData == null ? (
        <FullScreenLoading />
      ) : (
        <iframe
          title="Configuration"
          src={replacePath(
            appMenuList.find((appMenu) => appMenu.service?.serviceKey === serviceKey)?.pattern || '',
            {
              endpoint: appMenuList.find((appMenu) => appMenu.service?.serviceKey === serviceKey)?.service
                ?.adminPanelEndpoint,
              locale: i18n.language === 'enUS' ? 'en' : 'th',
              authorization_code: generateAuthCodeData.code,
              credentialKey: generateAuthCodeData.credentialKey,
              path:
                action === 'create'
                  ? `${params.slug}/${action}`
                  : action === 'detail'
                  ? `${params.slug}/${id}`
                  : params.slug,
            },
            querystring.parse(window.location.search)
          )}
          style={{ width: '100%', height: 'calc(100vh - 64px)' }}
          frameBorder="0"
        />
      )}
    </AuthenticatedLayout>
  )
}

export default IFrameApplicationPage
