import type { FC } from 'react'

import { useEffectOnce } from 'react-use'

import { useTranslation } from 'react-i18next'

import FullScreenLoading from 'components/FullScreenLoading'

import { useGenerateAuthCodeMutation } from 'graphQL/generated/operations'

import { authorizationKey, oauthUrl } from 'config'

const ProfilePage: FC = () => {
  const { t } = useTranslation('auth')

  const [generateAuthCode] = useGenerateAuthCodeMutation({
    onCompleted({ generateAuthCode }) {
      const { code, credentialKey } = generateAuthCode.payload

      window.location.href = `${oauthUrl}/auth?code=${code}&credential_key=${credentialKey}&${authorizationKey.redirectUrl}=${window.location.origin}`
    },
    onError() {
      window.location.reload()
    },
  })

  useEffectOnce(() => {
    generateAuthCode()
  })

  return <FullScreenLoading>{t('signingIn')}</FullScreenLoading>
}

export default ProfilePage
