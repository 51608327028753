import { Form, FormItemProps, Select, SelectProps } from 'antd'

import useMasterData from 'hooks/useMasterData'

import { onFilterSelect } from 'utils/antdUtils'
import { toSelectOptions } from './utils'

interface OtherInputProps extends FormItemProps {
  parentKey: string
  selectProps?: SelectProps<any>
}

const OtherProvinceInput: React.FC<OtherInputProps> = ({ parentKey, selectProps, ...props }) => {
  const masterData = useMasterData({
    parentKeys: [parentKey],
  })

  const masterDataKey = masterData.masterDataList[parentKey]

  const options = masterDataKey ? toSelectOptions(masterDataKey) : []

  return (
    <Form.Item {...props}>
      <Select
        {...selectProps}
        style={{ width: '100%' }}
        loading={masterData.loading}
        showSearch
        options={options}
        filterOption={onFilterSelect}
      />
    </Form.Item>
  )
}

export default OtherProvinceInput
