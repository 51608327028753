import { gql } from '@apollo/client'

export const MY_NOTIFICATION_SUBSCRIPTION = gql`
  subscription getMyNotificationSubscription($input: INPUT_FIND_DATA) {
    getMyNotification(find: $input) {
      message
      code
      pagination {
        limit
        page
        totalItems
        totalPages
      }
      unread
      payload {
        _id
        title
        content
        attribute
        icon
        type
        from
        to
        read
        createdAt
        updatedAt
      }
    }
  }
`
export const MY_NOTIFICATION_QUERY = gql`
  query getMyNotification($input: INPUT_FIND_DATA) {
    getMyNotification(find: $input) {
      message
      code
      pagination {
        limit
        page
        totalItems
        totalPages
      }
      unread
      payload {
        _id
        title
        content
        attribute
        icon
        type
        from
        to
        read
        createdAt
        updatedAt
      }
    }
  }
`
