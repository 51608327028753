import { gql } from '@apollo/client'

const DELETE_USER_ORGNANIZATION = gql`
  mutation deleteUserOrganizationByUserId($userId: ID!, $orgKey: String!) {
    deleteUserOrganizationByUserId(userId: $userId, orgKey: $orgKey) {
      payload {
        _id
      }
    }
  }
`

export default DELETE_USER_ORGNANIZATION
