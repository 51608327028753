import type { FC } from 'react'

import styled from 'styled-components'
import { SaveOutlined } from '@ant-design/icons'
import { Alert, Button, Form, message } from 'antd'
import { camelCase, sentenceCase } from 'change-case'

import { useGetPermissionRoleQuery, useUpdatePermissionMutation } from 'graphQL/generated/operations'

import ApplicationRolePermissionTable from './ApplicationRolePermissionTable'

import { apiPathV2 } from 'constants/api'

import { createInitialPermissionFormInput, createPermissionInputForUpdateToAPI } from './utils'

import type { Service } from 'graphQL/queryResponseTypes'

interface ApplicationRolePermissionServiceTabProps {
  roleKey: string
  service?: Service
}

const ApplicationRolePermissionServiceTab: FC<ApplicationRolePermissionServiceTabProps> = ({ roleKey, service }) => {
  const serviceName = service?.name || 'Core service'
  const serviceKey = camelCase(service?.serviceKey || '') || 'CORE'
  const serviceEndpoint = getServiceEndpoint()

  const [form] = Form.useForm()

  const permissionRoleQuery = useGetPermissionRoleQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      roleKey,
    },
    context: {
      uri: serviceEndpoint,
    },
    onCompleted({ getPermissionRole }) {
      const listPermissions = getPermissionRole.payload

      const initialPermission = createInitialPermissionFormInput(listPermissions)

      form.setFieldsValue({
        [serviceKey]: initialPermission,
      })
    },
  })

  const [updatePermission, updatePermissionResp] = useUpdatePermissionMutation({
    context: {
      uri: serviceEndpoint,
    },
    onCompleted() {
      message.success(sentenceCase(`${serviceName} permission updated successfully.`))

      permissionRoleQuery.refetch()
    },
    onError(error) {
      message.error(error.message)

      permissionRoleQuery.refetch()
    },
  })

  const listPermissionsRole = permissionRoleQuery.data?.getPermissionRole.payload || []

  if (permissionRoleQuery.error) {
    return <Alert type="error" message={`Cannot retrieve permission data for ${serviceName} right now.`} />
  }

  return (
    <Form
      form={form}
      name={serviceKey}
      onFinish={(values) => {
        const permissionList = createPermissionInputForUpdateToAPI(values[serviceKey])

        updatePermission({
          variables: {
            roleKey,
            permissionList,
          },
        })
      }}
    >
      <PermissionMenuActionContainer>
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {sentenceCase(`Save ${serviceName} permission`)}
        </Button>
      </PermissionMenuActionContainer>

      <Form.Item name={serviceKey}>
        <ApplicationRolePermissionTable
          loading={permissionRoleQuery.loading || updatePermissionResp.loading}
          listPermissionsRole={listPermissionsRole}
        />
      </Form.Item>
    </Form>
  )

  function getServiceEndpoint() {
    if (service == null) {
      return apiPathV2.core.admin
    }

    return service.endpoint.gql.resourceAdmin
  }
}

export default ApplicationRolePermissionServiceTab

const PermissionMenuActionContainer = styled.div`
  text-align: right;
  margin-bottom: 24px;

  h1 {
    font-weight: bold;
    margin: 0;
  }
`
