import { gql } from '@apollo/client'

import { paginationQuery } from 'graphQL/query'

const MASTER_DATA = gql`
  query getMasterData($input: INPUT_FIND_DATA) {
    getMasterData(input: $input) {
      message
      pagination {
        ${paginationQuery}
      }
      payload {
        parentKey
        dataKey
        locale
        text
        attribute
      }
    }
  }
`

export default MASTER_DATA
