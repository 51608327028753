import { Menu } from 'antd'
import FontawesomeIcon from 'components/FontawesomeIcon'
import type { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import type { IServiceOrganizationSidebarProps } from './types'

let __cacheSelectedKey = ''

const ServiceOrganization: FC<IServiceOrganizationSidebarProps> = ({
  menus,
  service,
  serviceKey,
  currentRoute = '',
}) => {
  const { pathname } = useLocation()

  const selectedKey = [currentRoute, __cacheSelectedKey]

  if (menus.length === 0) {
    return null
  }

  return (
    <Menu theme="light" className="organization-menu-container" mode="inline" selectedKeys={selectedKey}>
      <Menu.Item key={serviceKey} disabled>
        {service.name}
      </Menu.Item>

      <Menu.Divider />

      {menus.map((menu) => {
        if (Array.isArray(menu.subMenus) && menu.subMenus.length > 0) {
          const findSubMenuByPathname = menu.subMenus.find((subMenu) => pathname === subMenu.linkTo)

          if (findSubMenuByPathname?.key) {
            __cacheSelectedKey = findSubMenuByPathname?.key
          }

          return (
            <Menu.SubMenu key={menu.subKey} icon={<FontawesomeIcon iconName={menu.iconName} />} title={menu.title}>
              {menu.subMenus.map((subMenu) => (
                <Menu.Item key={subMenu.key}>
                  <span>{subMenu.title}</span>
                  <Link to={subMenu.linkTo} />
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          )
        }

        const findMenuByPathname = menu.linkTo === pathname ? menu : undefined

        if (findMenuByPathname) {
          __cacheSelectedKey = ''
        }

        /*const isCourseCategory = menu.key === 'course-category' || menu.key === 'category'
        if (isCourseCategory) {
          const castPermission = service.permissionList as unknown as ServicePermission[]

          const findPermission = castPermission.find(({ permissionKey }) => permissionKey === 'COURSE_CATEGORY')
          if (!findPermission) {
            return null
          }
        } */

        return (
          <Menu.Item key={menu.key} icon={<FontawesomeIcon iconName={menu.iconName} />}>
            <span>{menu.title}</span>
            <Link to={`${menu.linkTo}?serviceKey=${service.serviceKey}`} />
          </Menu.Item>
        )
      })}
      <Menu.Divider />
    </Menu>
  )
}

export default ServiceOrganization
