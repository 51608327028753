import { useQuery } from '@apollo/client'

import QUERY_DOCUMENT from './getCredentialByHost'

import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { CredentialByHostAPIPayload } from './interface'

interface CredentialByHostData {
  getCredentialByHost: APIPayloadResponse<CredentialByHostAPIPayload>
}

interface CredentialByHostVars {
  host: string
}

const useGetCredentialByHost: GraphQLServiceQueryHook<CredentialByHostData, CredentialByHostVars> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    ...options,
  })
}

export default useGetCredentialByHost
