import { Menu } from 'antd'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import FontawesomeIcon from 'components/FontawesomeIcon'

import type { IMenuListConfig, IRoute } from '../types'

const route: IRoute = {
  '/system-admin/dashboard': '0',
  '/system-admin/administrator': '1',
  '/system-admin/service': '2',
  '/system-admin/application': '3',
  '/system-admin/configuration': '4',
}

function SystemAdmin(): JSX.Element {
  const location = useLocation()

  const { pathname } = location

  const currentRoute = route[pathname]

  const overviewMenuList: IMenuListConfig[] = [
    {
      key: '0',
      iconName: 'chart-pie',
      linkTo: '/system-admin/dashboard',
      title: 'Dashboard',
      permissionList: [
        {
          permissionKey: 'DASHBOARD_MANAGEMENT',
          code: ['READ'],
        },
      ],
    },
  ]

  const managementMenuList: IMenuListConfig[] = [
    {
      key: '1',
      iconName: 'user-friends',
      linkTo: '/system-admin/administrator',
      title: 'Administrator',
    },
    {
      key: '2',
      iconName: 'columns',
      linkTo: '/system-admin/service',
      title: 'Service',
    },
    {
      key: '3',
      iconName: 'th-large',
      linkTo: '/system-admin/application',
      title: 'Application',
    },
  ]

  const settingMenuList: IMenuListConfig[] = [
    {
      key: '4',
      iconName: 'cog',
      linkTo: '/system-admin/configuration',
      title: 'Configuration',
    },
  ]

  return (
    <Menu
      theme="light"
      className="organization-menu-container"
      mode="inline"
      defaultSelectedKeys={['0']}
      selectedKeys={[currentRoute]}
    >
      <Menu.Item disabled>Overview</Menu.Item>
      <Menu.Divider />
      {overviewMenuList.map((menu) => (
        <Menu.Item key={menu.key} icon={<FontawesomeIcon iconName={menu.iconName} />}>
          <span>{menu.title}</span>
          <Link to={menu.linkTo} />
        </Menu.Item>
      ))}

      <Menu.Item disabled>Management</Menu.Item>
      <Menu.Divider />
      {managementMenuList.map((menu) => (
        <Menu.Item key={menu.key} icon={<FontawesomeIcon iconName={menu.iconName} />}>
          <span>{menu.title}</span>
          <Link to={menu.linkTo} />
        </Menu.Item>
      ))}

      <Menu.Item disabled>Setting</Menu.Item>
      <Menu.Divider />
      {settingMenuList.map((menu) => (
        <Menu.Item key={menu.key} icon={<FontawesomeIcon iconName={menu.iconName} />}>
          <span>{menu.title}</span>
          <Link to={menu.linkTo} />
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default SystemAdmin
