import type { FC } from 'react'
import type { ColumnsType } from 'antd/lib/table'

import { Checkbox, Table } from 'antd'

import type { GetPermissionRoleData } from 'graphQL/queryResponseTypes'
import type { PermissionCode, PermissionFormInput } from './utils'

interface ApplicationRolePermissionTableProps {
  loading?: boolean
  value?: PermissionFormInput
  listPermissionsRole: GetPermissionRoleData[]
  onChange?: (value: PermissionFormInput) => void
}

const options = [
  { label: 'Edit', value: 'EDIT' },
  { label: 'Read', value: 'READ' },
]

const ApplicationRolePermissionTable: FC<ApplicationRolePermissionTableProps> = ({
  loading,
  listPermissionsRole,
  value,
  onChange,
}) => {
  const columns: ColumnsType<GetPermissionRoleData> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Key',
      dataIndex: 'permissionKey',
      key: 'permissionKey',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (_text, record) => {
        const key = record.permissionKey
        const codes = value?.[key]

        return (
          <Checkbox.Group
            options={options}
            value={codes}
            onChange={(codes) =>
              onChange?.({
                ...value,
                [key]: codes as PermissionCode[],
              })
            }
          />
        )
      },
    },
  ]

  return <Table rowKey="permissionKey" loading={loading} dataSource={listPermissionsRole} columns={columns} />
}

export default ApplicationRolePermissionTable
