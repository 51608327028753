import { Table } from 'antd'
import { Role } from 'graphQL/schemaType/role'

import type { ITableProps } from '../types'

function AppRole({ columns, dataSource, rowKey, loading }: ITableProps<Role>): JSX.Element {
  return (
    <Table
      className="organization-table"
      dataSource={dataSource}
      columns={columns}
      rowKey={rowKey}
      pagination={{ defaultPageSize: 8 }}
      loading={loading}
    />
  )
}

export default AppRole
