import type { FC } from 'react'

import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import querystring from 'querystring'

import InnerOrganizationLayout from 'layouts/InnerOrganizationLayout'

import FullScreenLoading from 'components/FullScreenLoading'

import useQueryParams from 'hooks/useQueryParams'
import useServiceMenuQuery from 'hooks/useServiceMenuQuery'
import useQueryString from 'hooks/useQueryString'

import useGenerateOrgTokenRef from 'graphQL/useGenerateOrgTokenRef'
import useGenerateAuthCode from 'graphQL/useGenerateAuthCode'

import { getDefaultServiceKeyWithOtherQueryString, routeTo } from 'helpers/utils'

import { replacePath } from 'utils/mapText'

type OrganizationIFramePageParams = {
  orgKey: string
  slug: string
}

const OrganizationIFramePage: FC = () => {
  const serviceKey = useQueryParams('serviceKey')

  const { i18n } = useTranslation()
  const params = useParams<OrganizationIFramePageParams>() as OrganizationIFramePageParams

  const { loading, organizationMenuList = [] } = useServiceMenuQuery({
    orgKey: params.orgKey,
  })

  const query = useQueryString()

  const navigate = useNavigate()
  const location = useLocation()

  if ((!serviceKey || serviceKey === 'undefined') && organizationMenuList.length > 0) {
    navigate(
      routeTo(window.location.pathname, {
        query: getDefaultServiceKeyWithOtherQueryString(query, organizationMenuList),
      }),
      {
        replace: true,
      }
    )
  }

  const generateTokenRefResp = useGenerateOrgTokenRef({
    variables: {
      orgKey: params.orgKey,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [generateAuthCode, generateAuthCodeResp] = useGenerateAuthCode({
    variables: {
      orgKey: params.orgKey,
    },
    onError(err) {
      console.log(err)
    },
    notifyOnNetworkStatusChange: true,
  })

  const generateTokenRefData = generateTokenRefResp.data?.generateOrgTokenRef.payload
  const authCodeData = generateAuthCodeResp.data?.generateAuthCode.payload

  const isLoading =
    generateAuthCodeResp.loading || generateTokenRefResp.loading || loading || generateTokenRefData == null

  /**
   * Temporary fixed about to query when route changed
   */
  useEffect(() => {
    generateTokenRefResp.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    generateAuthCode()
  }, [generateAuthCode])

  return (
    <InnerOrganizationLayout>
      {isLoading ? (
        <FullScreenLoading />
      ) : (
        <iframe
          title="Configuration"
          src={replacePath(
            organizationMenuList.find((service) => service.service.serviceKey === serviceKey)?.pattern || '',
            {
              endpoint: organizationMenuList.find((service) => service.service?.serviceKey === serviceKey)?.service
                ?.adminPanelEndpoint,
              locale: i18n.language === 'enUS' ? 'en' : 'th',
              authorization_code: generateTokenRefData.ref,
              path: params.slug,
              credentialKey: authCodeData?.credentialKey,
            },
            querystring.parse(window.location.search)
          )}
          style={{ width: '100%', height: 'calc(100vh - 68px)' }}
          frameBorder="0"
        />
      )}
    </InnerOrganizationLayout>
  )
}

export default OrganizationIFramePage
