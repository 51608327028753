import type { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { createContext, useState, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { changeAntdTheme } from 'dynamic-antd-theme'

import FullScreenLoading from 'components/FullScreenLoading'

import useGetCredentialByHost from 'graphQL/useGetCredentialByHost'
import {
  Enum_Theme_Default_Status,
  Enum_Theme_Status,
  Enum_Theme_Type,
  useGetAppThemeLazyQuery,
} from 'graphQL/generated/operations'

import { getAppDataFromStorage } from 'utils/app'

import API from 'constants/api'

import type { ThemeColor } from 'graphQL/useGetAppTheme/interface'
import type { GetAppThemeData } from 'graphQL/queryResponseTypes'

export interface AppThemeContextData {
  admin: GetAppThemeData
  collapsed: boolean
  collapseHandler: (isCollapsed: boolean) => void
}

const defaultThemeContextData: AppThemeContextData = {
  admin: {
    appKey: '',
    themeKey: '',
    themeType: Enum_Theme_Type.Admin,
    status: Enum_Theme_Status.Active,
    isDefault: Enum_Theme_Default_Status.Yes,
    name: 'theme',
    color: null,
    text: null,
    image: null,
    custom: null,
  },
  collapsed: false,
  collapseHandler() {},
}

export const ThemeContext = createContext<AppThemeContextData>(defaultThemeContextData)

const ThemeProvider: FC = ({ children }) => {
  const { t } = useTranslation('theme')

  const [admin, setAdminTheme] = useState<GetAppThemeData>(defaultThemeContextData.admin)
  const [collapsed, setCollapsed] = useState(defaultThemeContextData.collapsed)

  const credentialKey = getAppDataFromStorage()?.credential.credentialKey

  const [appThemeQuery, appThemeQueryResp] = useGetAppThemeLazyQuery({
    onCompleted(resp) {
      onThemeHandler(resp.getAppTheme.payload[0])
    },
    onError() {
      setupThemeColor()
    },
  })

  const credentialByHostQuery = useGetCredentialByHost({
    variables: {
      host: window.location.host,
    },
    onCompleted({ getCredentialByHost }) {
      const data = getCredentialByHost.payload

      appThemeQuery({
        context: {
          uri: API.CORE.SYSTEM_ADMIN,
          headers: {
            credentialKey: credentialKey || data.credential.credentialKey,
          },
        },
      })
    },
  })

  function onThemeHandler(theme: GetAppThemeData) {
    setupTheme(theme)
    setAdminTheme(theme)
  }

  function collapseHandler(isCollapsed: boolean) {
    setCollapsed(isCollapsed)
  }

  function setupTheme(theme?: GetAppThemeData) {
    setupThemeColor(theme?.color)

    if (theme?.image) {
      const { favIcon } = theme.image
      const favEle = document.getElementById('favicon') as HTMLLinkElement
      if (favIcon && favEle) {
        favEle.href = favIcon
      }
    }
  }

  function setupThemeColor(color?: ThemeColor | null) {
    const fallbackColor = {
      primary: '#1890ff',
      secondary: '#bfbfbf',
      text: '#000',
      navText: '#fff',
    }

    const primary = color?.primary || fallbackColor.primary
    const secondary = color?.secondary || fallbackColor.primary
    const text = color?.titleText || fallbackColor.text

    changeAntdTheme(primary)

    document.documentElement.style.setProperty('--ant-primary-color', primary)
    document.documentElement.style.setProperty('--app-secondaryColor', secondary)
    document.documentElement.style.setProperty('--app-titleTextColor', text)
    document.documentElement.style.setProperty('--app-navBgColor', color?.navBg || primary)
    document.documentElement.style.setProperty('--app-navTextColor', color?.navText || fallbackColor.navText)
    document.documentElement.style.setProperty('--app-navAuthBgColor', color?.navAuthBg || '#fff')
    document.documentElement.style.setProperty('--app-navAuthTextColor', color?.navAuthText || primary)
  }

  return (
    <ThemeContext.Provider
      value={{
        admin,
        collapsed,
        collapseHandler,
      }}
    >
      <Helmet>
        <title>{admin.text?.webTitle || 'Admin Panel'}</title>
        <meta name="description" content={admin.text?.description || ''} />
        <meta property="og:image" content={admin.image?.favIcon} />
        <meta name="twitter:image" content={admin.image?.favIcon} />
      </Helmet>

      {appThemeQueryResp.loading || credentialByHostQuery.loading ? (
        <FullScreenLoading>{t('loading')}</FullScreenLoading>
      ) : (
        children
      )}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider

export const useThemeContext = () => useContext(ThemeContext)
