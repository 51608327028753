import type { FC } from 'react'

import { Result, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { paths } from 'setup/PageRouter'

const NotFoundPage: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('global')

  return (
    <Result
      status="404"
      title="404"
      subTitle={t('notFound')}
      extra={
        <Button key="backToHomeButton" type="primary" onClick={() => navigate(paths.root)}>
          {t('backHomeButton')}
        </Button>
      }
    />
  )
}

export default NotFoundPage
