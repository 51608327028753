import type { FC } from 'react'

import { Navigate } from 'react-router-dom'

import { usePermissionContext } from 'contexts/PermissionContext'

import { useGetMyPermissionQuery } from 'graphQL/generated/operations'

import { paths } from 'setup/PageRouter'

import type { AuthenticateUserProps } from 'setup/PageRouter/PageRouter'

const withAuthV2 =
  <T extends object>(WrappedComponent: React.ComponentType<T>): FC<T & AuthenticateUserProps> =>
  (props) => {
    const { user } = props

    const notLoggedIn = user == null

    const { setPermissions } = usePermissionContext()

    const skip = notLoggedIn || window.location.pathname === paths.signOut

    useGetMyPermissionQuery({
      skip,
      onCompleted(resp) {
        setPermissions(resp.getMyPermission.payload)
      },
    })

    if (notLoggedIn) {
      return <Navigate to={paths.signIn} />
    }

    return <WrappedComponent {...props} />
  }

export default withAuthV2
