import { SaveOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Space } from 'antd'
import { FormInstance } from 'antd'
// import { useState } from 'react'
// import { IRoleForm } from './types'
type UserFieldFormProps = {
  data?: any[]
  form?: FormInstance<any>
  onFinish: (params: any) => void
  loading?: boolean
}
const { Option } = Select

function UserFieldFrom({ onFinish, loading, data, form }: UserFieldFormProps): JSX.Element {
  return (
    <Form
      name="userField"
      onFinish={onFinish}
      labelAlign="left"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 12 }}
      initialValues={data}
      form={form}
    >
      <Space direction="vertical" size={18}>
        <Form.Item
          label="Title"
          help="User field title"
          name="title"
          rules={[{ required: true, message: 'Title is required!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Key"
          help="User field key reference"
          name="key"
          rules={[{ required: true, message: 'Key is required!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Placeholder"
          help="User field placeholder"
          name="placeholder"
          rules={[{ required: true, message: 'Placeholder is required!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Type"
          help="User field Type"
          name="type"
          rules={[{ required: true, message: 'Type is required!' }]}
        >
          <Select>
            <Option value="TEXT">
              <Space size={4}>
                <span>Text</span>
              </Space>
            </Option>
            {/* <Option value="NUMBER">
              <Space size={4}>
                <span>Number</span>
              </Space>
            </Option> */}
            <Option value="OPTION">
              <Space size={4}>
                <span>Option</span>
              </Space>
            </Option>
            <Option value="PHONE_NUMBER">
              <Space size={4}>
                <span>Phone number</span>
              </Space>
            </Option>
            <Option value="DATE">
              <Space size={4}>
                <span>Date</span>
              </Space>
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Required"
          help="User field required"
          name="required"
          rules={[{ required: true, message: 'Required field is required!' }]}
        >
          <Select>
            <Option value="YES">
              <Space size={4}>
                <span>Yes</span>
              </Space>
            </Option>
            <Option value="NO">
              <Space size={4}>
                <span>No</span>
              </Space>
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Unique"
          help="User field unique"
          name="isUnique"
          rules={[{ required: true, message: 'Unique field is required!' }]}
        >
          <Select>
            <Option value="YES">
              <Space size={4}>
                <span>Yes</span>
              </Space>
            </Option>
            <Option value="NO">
              <Space size={4}>
                <span>No</span>
              </Space>
            </Option>
          </Select>
        </Form.Item>
        {/* <Form.Item label="Status" help="Credential status" name="status" initialValue="ENABLED">
          <Select>
            <Option value="ENABLED">
              <Space size={4}>
                <CheckCircleOutlined className="success-color" /> <span className="success-color">Enable</span>
              </Space>
            </Option>
            <Option value="DISABLED">
              <Space size={4}>
                <CloseCircleOutlined className="danger-color" /> <span className="danger-color">Disable</span>
              </Space>
            </Option>
          </Select>
        </Form.Item> */}

        <Form.Item className="mb-0" wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" loading={loading} icon={<SaveOutlined />}>
            Submit
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}

export default UserFieldFrom
