import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import setupAppLocal from 'utils/localService/app'

import setupI18n from 'i18n'

import 'styles/style.scss'

/**
 * Dayjs config
 */

dayjs.extend(relativeTime)

/**
 * Local storage config
 */

setupAppLocal()

/**
 * Multiple languages config
 */

setupI18n()
