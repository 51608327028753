import { useMutation } from '@apollo/client'

import { onDefaultErrorMessage } from 'utils/apollo'

import CREATE_ROLE from './createRole'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import { RoleAPIPayload, RoleStatusType } from 'graphQL/schemaType/role'

interface ReleData {
  createRole: APIPayloadResponse<RoleAPIPayload>
}
type RoleType = 'RESOURCE_ADMIN' | 'RESOURCE_OWNER'
interface CreateRoleVars {
  roleInput: {
    name?: string
    roleKey?: string
    attribute?: any
    userField?: any
    roleType?: RoleType
    status?: RoleStatusType
    regisable?: RoleStatusType
    isDefault?: RoleStatusType
    needApproval?: RoleStatusType
  }
}

const useCreateRole: GraphQLServiceMutationHook<ReleData, CreateRoleVars> = (options) => {
  return useMutation(CREATE_ROLE, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useCreateRole
