import { Tabs, Typography } from 'antd'
import DataSecurityService from './DataSecurityService'
import useGetAppService from 'graphQL/useGetAppService'
import type { Service } from 'graphQL/graphQL-service-hook'
import api from 'constants/api'

import imgNone from 'assets/images/data_security/none.png'
import imgOrganization from 'assets/images/data_security/organization.png'
import imgParent from 'assets/images/data_security/parent.png'
import imgSystem from 'assets/images/data_security/system.png'
import imgUser from 'assets/images/data_security/user.png'

const { Text } = Typography
const { TabPane } = Tabs

const imgList = [
  { img: imgNone, text: 'None selected' },
  { img: imgUser, text: 'User' },
  { img: imgOrganization, text: 'Organization' },
  { img: imgParent, text: 'Parent:Child (Org)' },
  { img: imgSystem, text: 'System' },
]
const dataSecurityTypeList = (): JSX.Element => {
  return (
    <div style={{ textAlign: 'right' }}>
      {imgList.map((ojb: any) => {
        return (
          <>
            <img src={ojb.img} alt="" style={{ verticalAlign: 'middle', marginLeft: 15 }}></img>
            <Text type="secondary" style={{ marginLeft: 10 }}>
              {ojb.text}
            </Text>
          </>
        )
      })}
    </div>
  )
}
interface Prop {
  roleKey: string
}
function DataSecurityRolePage({ roleKey }: Prop): JSX.Element {
  const appServiceQuery = useGetAppService({
    fetchPolicy: 'network-only',
  })
  const appServiceQueryData = appServiceQuery.data?.getAppService?.payload
  const serviceList = appServiceQueryData?.serviceList || []
  console.log('serviceList...', serviceList)

  function callback(key: any) {
    console.log(key)
  }

  return (
    <>
      {dataSecurityTypeList()}
      <Tabs onChange={callback} type="card">
        <TabPane tab="Core Service" key="1">
          <DataSecurityService roleKey={roleKey} serviceEnpoint={api.CORE.ADMIN} />
        </TabPane>
        {appServiceQuery.data &&
          serviceList.length > 0 &&
          serviceList.map((service: Service, index: number) => {
            return (
              <TabPane tab={service.name} key={`${index + 2}`}>
                <DataSecurityService roleKey={roleKey} serviceEnpoint={service.endpoint.gql.resourceAdmin} />
              </TabPane>
            )
          })}
        {/* <TabPane tab="CMS Service" key="2">
          <DataSecurityService />
        </TabPane> */}
      </Tabs>
    </>
  )
}

export default DataSecurityRolePage
