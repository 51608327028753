import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Button, Col, List, Row, Space, Typography } from 'antd'
import { RedoOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller'
import { generatePath, useNavigate } from 'react-router-dom'

import LoadingIcon from 'components/LoadingIcon'
import ContentBlock from 'components/ContentBlock'
import FullWidthSpace from 'components/FullWidthSpace'
import NotificationIcon from './NotificationIcon'

import { paths } from 'setup/PageRouter'

import { useGetMyNotificationQuery } from 'graphQL/useGetMyNotification'
import { useReadNotificationMutation } from 'graphQL/useReadNotification'
import { MyNotificationAPIPayload } from 'graphQL/useGetMyNotification/interface'
import { useReadAllNotification } from 'graphQL/useReadAllNotification'

import type { NotificationDataSource } from './interface'
import type { Pagination } from 'graphQL/graphQL-service-hook'

const NotificationList: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [notificactions, setNotifications] = useState<NotificationDataSource[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    limit: 10,
    page: 1,
  })
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  const myNotificationResp = useGetMyNotificationQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pagination: {
          limit: pagination.limit,
          page: pagination.page,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted(resp) {
      const data = resp.getMyNotification
      setPagination({ ...pagination, totalPages: data.pagination?.totalPages })
      if (data.pagination?.page === 1) {
        setNotifications(data.payload)
      } else {
        setNotifications(notificactions?.concat(data.payload))
      }
      setLoading(false)
    },
  })
  const [readNotification] = useReadNotificationMutation({
    fetchPolicy: 'network-only',
    onCompleted(resp) {
      const data = resp.readNotification
      navigate(
        generatePath(paths.notificationDetail, {
          id: data.payload[0]._id,
        })
      )
    },
  })

  const [readAllNotification, readAllResp] = useReadAllNotification({
    fetchPolicy: 'network-only',
    onCompleted(resp) {
      myNotificationResp.refetch()
    },
  })

  const handleInfiniteOnLoad = () => {
    setLoading(true)
    if (notificactions.length === pagination.totalItems) {
      setLoading(false)
      setHasMore(false)
      return
    }
    setPagination({ ...pagination, page: Number(pagination.page) + 1 })
  }

  const handleReadNotificaiton = (notification: MyNotificationAPIPayload) => {
    if (notification.read === 'NO') {
      readNotification({
        variables: {
          notificationIdList: [notification._id],
        },
      })
    } else {
      navigate(
        generatePath(paths.notificationDetail, {
          id: notification._id,
        })
      )
    }
  }

  const handleRefresh = () => {
    setLoading(true)
    setPagination({ ...pagination, page: 1 })
    myNotificationResp.refetch()
  }
  const handleReadAll = () => {
    setLoading(true)
    setPagination({ ...pagination, page: 1 })
    readAllNotification()
  }

  const readStatus = (notification: MyNotificationAPIPayload): string => {
    let textDate = dayjs(notification.updatedAt).fromNow()
    textDate = notification.read === 'YES' ? t('notification:read') + ' ' + textDate : textDate
    return textDate
  }

  return (
    <ContentBlock>
      <Row gutter={32}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <EndContentSpace>
            <Button type="primary" loading={readAllResp.loading || myNotificationResp.loading} onClick={handleReadAll}>
              {t('button:readAll')}
            </Button>
            <Button type="primary" icon={<RedoOutlined />} loading={myNotificationResp.loading} onClick={handleRefresh}>
              {t('button:refresh')}
            </Button>
          </EndContentSpace>
        </Col>
        <Col span={24} style={{ marginTop: '5px' }}>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={handleInfiniteOnLoad}
            hasMore={!loading && hasMore}
          >
            <List
              loading={myNotificationResp.loading}
              dataSource={notificactions}
              renderItem={(item) => (
                <ListItemStyle key={item._id} onClick={() => handleReadNotificaiton(item)}>
                  <List.Item.Meta
                    avatar={
                      <NotificationIcon
                        type={item.attribute?.icon?.type || 'info'}
                        iconName={item.attribute?.icon?.iconName || 'info-circle'}
                      />
                    }
                    title={
                      <Typography.Text strong type={item.read === 'YES' ? 'secondary' : undefined} ellipsis>
                        {item.title}
                      </Typography.Text>
                    }
                    description={
                      <FullWidthSpace direction="vertical">
                        <Typography.Paragraph
                          type={item.read === 'YES' ? 'secondary' : undefined}
                          ellipsis={{ rows: 2 }}
                        >
                          {/* {item.content} */}
                          <div dangerouslySetInnerHTML={{ __html: item.content }} />
                        </Typography.Paragraph>
                        <DateTimeTitle>{readStatus(item)}</DateTimeTitle>
                      </FullWidthSpace>
                    }
                  />
                  {item.read === 'NO' && <Badge color="blue" />}
                </ListItemStyle>
              )}
            >
              {loading && hasMore && <LoadingIcon />}
            </List>
          </InfiniteScroll>
        </Col>
      </Row>
    </ContentBlock>
  )
}

export default NotificationList

const DateTimeTitle = styled.span`
  color: #2699fb;
`

const ListItemStyle = styled(List.Item)`
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  &:hover {
    box-shadow: 0 0 4px #c1b7b7;
  }
`
const EndContentSpace = styled(Space)`
  width: auto;
`
