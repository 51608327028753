import type { FC } from 'react'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CloseCircleFilled, EditOutlined, RocketOutlined } from '@ant-design/icons'
import { Button, Form, message, Popconfirm, Space } from 'antd'
import styled from 'styled-components'

import ContentCard from 'components/Card/Content'
import CreateOrUpdateOrganizationForm from 'components/CreateOrUpdateOrganizationForm'
import FullWidthSpace from 'components/FullWidthSpace'
import DangerZone from 'components/Organization/DangerZone'
import PageTitle from 'components/PageTitle'
import AccountStatus from './OrganizationSettingPage/OrganizationSettingStatus'

import useGetOrganization from 'graphQL/useGetOrganization'
import useUpdateOrganization from 'graphQL/useUpdateOrganization'
import useUpdateOrgStatus from 'graphQL/useUpdateOrgStatus'

import usePermission from 'hooks/usePermission'

import InnerOrganizationLayout from 'layouts/InnerOrganizationLayout'

import { commonFormProps } from 'utils/antdUtils'
import { checkPermissionCanEditData } from 'utils/permission'

import type {
  OrganizationSettingFormInput,
  OrganizationSettingSaveActionName,
} from './OrganizationSettingPage/interface'

type OrganizationSettingParams = {
  orgKey: string
}

const OrganizationSettingPage: FC = () => {
  const { t } = useTranslation('organization')
  const organizationSetting = 'setting'
  const organizationSettingForm = `${organizationSetting}.form`

  const [organizationSetttingSaveActionName, setOrganizationSetttingSaveActionName] =
    useState<OrganizationSettingSaveActionName>('SAVE')

  const { orgKey } = useParams<OrganizationSettingParams>() as OrganizationSettingParams

  const [form] = Form.useForm<OrganizationSettingFormInput>()

  const permission = usePermission({
    code: ['EDIT'],
    permissionKey: 'ORG_DELETE',
  })

  const permissionOrgManagement = usePermission({
    permissionKey: 'ORG_MANAGEMENT',
    code: ['READ', 'EDIT'],
  })

  const isPermissionOrgManagement = checkPermissionCanEditData({
    code: 'EDIT',
    permissionKey: 'ORG_MANAGEMENT',
    permissionsList: permissionOrgManagement?.myPermissions,
  })

  const organizationQuery = useGetOrganization({
    variables: {
      input: {
        query: {
          orgKey,
        },
      },
    },
  })

  const organizationData = organizationQuery.data?.getOrganization.payload[0]
  const shouldBeEdit = organizationData?.status === 'PREPARING' || organizationData?.status === 'NEED_MORE_INFORMATION'
  const shouldBeCancel = organizationData?.status === 'REVIEWING' || organizationData?.status === 'APPROVED'

  const [updateOrgStatus, updateOrgStatusResp] = useUpdateOrgStatus({
    onCompleted() {
      message.success(t(`${organizationSetting}.updateOrgStatus`))
      organizationQuery.refetch()
    },
  })

  const [updateOrganization, updateOrganizationResp] = useUpdateOrganization({
    onCompleted() {
      updateOrgStatus({
        variables: {
          orgKey,
          status: shouldBeEdit ? 'REVIEWING' : organizationData?.status,
        },
      })
    },
  })

  const parentOrgKey = organizationData?.path?.split(',').filter(Boolean).slice(-1)[0] || undefined

  const initialValues = prepareOrganizationSettingInitialValues()

  return (
    <InnerOrganizationLayout>
      <div className="admin-content-container">
        <PageTitle title={t('page.setting')} />
        <ContentCard title={t(`${organizationSetting}.title`)} loading={organizationQuery.loading}>
          {!organizationQuery.loading && (
            <Form
              {...commonFormProps}
              form={form}
              labelAlign="left"
              initialValues={initialValues}
              onFinish={(values) => {
                const {
                  parentOrgKey: _parentOrgKey,
                  contactName,
                  email,
                  name,
                  callCenter,
                  position,
                  province,
                  district,
                  subDistrict,
                  postCode,
                  ...rest
                } = values

                const coordinates = position?.lat && position?.lng && [position.lng, position.lat]
                const address = {
                  province,
                  district,
                  subDistrict,
                  postCode,
                }
                if (isPermissionOrgManagement) {
                  updateOrganization({
                    variables: {
                      orgKey,
                      parentOrgKey: _parentOrgKey || organizationData?.path,
                      input: {
                        attribute: {
                          ...organizationData?.attribute,
                          ...rest,
                          callCenter: callCenter ? 'YES' : 'NO',
                        },
                        location: coordinates
                          ? {
                              coordinates,
                            }
                          : undefined,
                        contactEmailList: email ? [email] : undefined,
                        contactName,
                        name,
                        address,
                      },
                    },
                  })
                }
              }}
            >
              <Form.Item label={t(`${organizationSettingForm}.status`)}>
                <AccountStatus status={organizationData?.status} approver="Komphet Meesab" date={Date.now()} />
              </Form.Item>

              <CreateOrUpdateOrganizationForm isEdit shouldBeEdit={shouldBeEdit} form={form} />

              <FullWidthSpace direction="vertical" size="large">
                <Space>
                  {shouldBeEdit && (
                    <Button
                      type="primary"
                      icon={<RocketOutlined />}
                      onClick={form.submit}
                      loading={updateOrganizationResp.loading || updateOrgStatusResp.loading}
                    >
                      {t('button:saveAndSubmitRequest')}
                    </Button>
                  )}

                  {shouldBeCancel && isPermissionOrgManagement > 0 && (
                    <Popconfirm
                      title={t(`${organizationSetting}.confirmCancelMessage`)}
                      style={{ whiteSpace: 'pre-line' }}
                      onConfirm={() => {
                        if (isPermissionOrgManagement) {
                          setOrganizationSetttingSaveActionName('CANCEL')
                          updateOrgStatus({
                            variables: {
                              orgKey,
                              status: 'PREPARING',
                            },
                          })
                        }
                      }}
                    >
                      <Button
                        danger
                        loading={organizationSetttingSaveActionName === 'CANCEL' && updateOrgStatusResp.loading}
                        icon={organizationData?.status === 'REVIEWING' ? <CloseCircleFilled /> : <EditOutlined />}
                      >
                        {t(
                          `button:${
                            organizationData?.status === 'REVIEWING' ? 'cancelRequest' : 'backToEditOrganization'
                          }`
                        )}
                      </Button>
                    </Popconfirm>
                  )}
                </Space>

                {organizationData?.status === 'REVIEWING' && (
                  <WhileWaitingApprovalMessage>
                    {t(`${organizationSetting}.whileWaitingToApprove`)}
                  </WhileWaitingApprovalMessage>
                )}

                {permission.canAccess && isPermissionOrgManagement > 0 && <DangerZone orgKey={orgKey} />}
              </FullWidthSpace>
            </Form>
          )}
        </ContentCard>
      </div>
    </InnerOrganizationLayout>
  )

  function prepareOrganizationSettingInitialValues(): OrganizationSettingFormInput {
    const orgAttribute = organizationData?.attribute
    const orgPhone = orgAttribute?.phoneNumber
    const organizationAddress = organizationData?.address

    return {
      ...orgAttribute,
      ...organizationAddress,
      parentOrgKey,
      contactName: organizationData?.contactName,
      name: organizationData?.name || '',
      email: organizationData?.contactEmailList[0] || '',
      number: orgPhone?.number,
      countryCode: orgPhone?.countryCode,
      callCenter: orgAttribute?.callCenter === 'YES',
      contactLanguage: orgAttribute?.contactLanguage,
      position: {
        lat: organizationData?.location?.coordinates[1],
        lng: organizationData?.location?.coordinates[0],
      },
    }
  }
}

export default OrganizationSettingPage

const WhileWaitingApprovalMessage = styled.span`
  padding: 16px 0;
  color: #999;
`
