import useAppServiceWidget from 'hooks/useAppServiceWidget'

import FullScreenLoading from 'components/FullScreenLoading'
import AppDashboard from 'components/AppDashboard'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

const DashboardPage: React.FC = () => {
  const appWidget = useAppServiceWidget()

  return (
    <AuthenticatedLayout>
      <div className="admin-content-container">{appWidget.loading ? <FullScreenLoading /> : <AppDashboard />}</div>
    </AuthenticatedLayout>
  )
}

export default DashboardPage
