import { useTranslation } from 'react-i18next'
import { DatePicker, Form, Input, Select, Checkbox } from 'antd'

import { localeCode } from 'utils/localService'

import type { Rule } from 'antd/lib/form'
import type { MasterDataList } from 'hooks/useMasterData/interface'
import type { OrgField } from 'graphQL/graphQL-service-hook'

interface OrganizationFieldProps {
  fieldList: OrgField[]
  masterDataList: MasterDataList
  disabled?: boolean
}

interface Option {
  label: string
  value: string | number
}

const OrganizationField: React.FC<OrganizationFieldProps> = ({ fieldList, masterDataList, disabled }) => {
  const { t, i18n } = useTranslation('global')
  const localeIsEn = i18n.language === localeCode.enUS

  const ruleRequired: Rule = {
    required: true,
    message: t('global:requiredText'),
  }

  const translateKey = localeIsEn ? 'en' : 'th'

  return (
    <>
      {fieldList?.map((field, index) => {
        switch (field.type) {
          case 'TEXT':
            return (
              <Form.Item
                key={`${field.key}-${index}`}
                name={field.key}
                label={field.title?.[translateKey]}
                rules={[
                  {
                    ...ruleRequired,
                    required: field.required,
                  },
                ]}
              >
                <Input disabled={disabled} placeholder={field.placeholder?.[translateKey]} />
              </Form.Item>
            )

          case 'DATE':
            return (
              <Form.Item
                key={`${field.key}-${index}`}
                name={field.key}
                label={field.title?.[translateKey]}
                rules={[
                  {
                    ...ruleRequired,
                    required: field.required,
                  },
                ]}
              >
                <DatePicker
                  disabled={disabled}
                  style={{ width: '100%' }}
                  placeholder={field.placeholder?.[translateKey]}
                />
              </Form.Item>
            )
          case 'PHONE_NUMBER':
            return (
              <Form.Item
                key={`${field.key}-${index}`}
                name={field.key}
                label={field.title?.[translateKey]}
                rules={[
                  {
                    ...ruleRequired,
                    required: field.required,
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  type="number"
                  maxLength={10}
                  placeholder={field.placeholder?.[translateKey]}
                />
              </Form.Item>
            )
          case 'OPTION':
            let options: Option[] = field.optionList || []

            if (field.dataKey && masterDataList[field.dataKey]) {
              const dataKey = Object.values(masterDataList[field.dataKey])

              options = dataKey.map((item) => ({
                label: item.text,
                value: item.dataKey,
              }))
            }

            return (
              <Form.Item
                key={`${field.key}-${index}`}
                name={field.key}
                label={field.title?.[translateKey]}
                rules={[
                  {
                    ...ruleRequired,
                    required: field.required,
                  },
                ]}
              >
                <Select disabled={disabled} placeholder={field.placeholder?.[translateKey]} options={options} />
              </Form.Item>
            )
          case 'CHECKBOX':
            return (
              <Form.Item
                key={`${field.key}-${index}`}
                name={field.key}
                valuePropName="checked"
                rules={[
                  {
                    ...ruleRequired,
                    required: field.required,
                  },
                ]}
              >
                <Checkbox>{field.title?.[translateKey]}</Checkbox>
              </Form.Item>
            )
          default:
            return null
        }
      })}
    </>
  )
}

export default OrganizationField
