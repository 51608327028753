import { useState } from 'react'
import { Pagination, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

import ContentCard from 'components/Card/Content'
import SearchInput from 'components/Input/Search'
import OrganizationFieldDirectory from './OrganizationFieldDirectory'

import useGetOrganization from 'graphQL/useGetOrganization'
import { defaultPagination } from 'graphQL/config'

import type { Pagination as PaginationType } from 'graphQL/graphQL-service-hook'

function OrganizationDirectory(): JSX.Element {
  const { t } = useTranslation()

  const [pathOrg, setPathOrg] = useState(null)
  const [, setOrgName] = useState()
  const [searchVal, setSearchVal] = useState('')
  const [pagination, setPagination] = useState<PaginationType | undefined>(defaultPagination)

  const organizationQuery = useGetOrganization({
    variables: {
      input: {
        query: {
          path: pathOrg ? `,${pathOrg},` : null,
        },
        pagination: {
          limit: pagination?.limit,
          page: pagination?.page,
        },
        search: {
          name: searchVal,
        },
        sort: {
          name: 1,
        },
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted(resp) {
      setPagination(resp.getOrganization.pagination)
    },
    onError(error) {
      console.log(error)
    },
  })

  const isLoading = organizationQuery.loading
  const organizationData = organizationQuery.data?.getOrganization

  const handleChangePage = (page: number, pageSize?: number | undefined) => {
    setPagination({
      ...pagination,
      page,
      limit: pageSize || defaultPagination.limit,
    })
  }

  const onShowSizeChange = (page: number, pageSize: number) => {
    setPagination({
      ...pagination,
      page,
      limit: pageSize,
    })
  }

  return (
    <>
      <ContentCard
        loading={isLoading}
        title={`${t('organization:contentTitle.dashboard')}`}
        rightComponent={[
          <SearchInput
            onSearch={(value) => {
              setPagination({
                ...pagination,
                page: 1,
              })
              setSearchVal(value)
            }}
            placeholder={t('organization:search:placeholder')}
          />,
        ]}
      >
        {!isLoading && organizationData ? (
          <>
            <OrganizationFieldDirectory
              organizations={organizationData?.payload}
              setPathOrg={setPathOrg}
              setOrgName={setOrgName}
            />
            <div>
              <Pagination
                className="admin-content-container-pagination"
                defaultCurrent={1}
                current={pagination?.page}
                total={pagination?.totalItems}
                showTotal={(total) => `${t('organization:table.record')}: ${total}`}
                defaultPageSize={pagination?.limit}
                onChange={handleChangePage}
                onShowSizeChange={onShowSizeChange}
                pageSizeOptions={['5', '10', '20', '50', '100']}
              />
            </div>
          </>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Spin size="large" className="w-100 text-center" />
          </div>
        )}
      </ContentCard>
    </>
  )
}

export default OrganizationDirectory
