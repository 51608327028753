import React, { useState } from 'react'
import { Button, message, Popconfirm, Space, Table } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import useGetInviteUserOrganization from 'graphQL/useGetInviteUserOrganization'
import useDeleteInvite from 'graphQL/useDeleteInvite'
import useInviteUserOrganization from 'graphQL/useInviteUserOrganization'

import usePermission from 'hooks/usePermission'

import ContentCard from 'components/Card/Content'
import SearchInput from 'components/Input/Search'
import PageTitle from 'components/PageTitle'
import TotalDataSourceText from 'components/Table/TotalDataSourceText'
import InvitationMemberModal from 'components/InvitationMemberModal'
import FullWidthSpace from 'components/FullWidthSpace'

import InnerOrganizationLayout from 'layouts/InnerOrganizationLayout'

import { onDefaultErrorMessage } from 'utils/apollo'
import { checkPermissionCanEditData } from 'utils/permission'

import type { ColumnType } from 'antd/lib/table'
import type { InviteUserOrganizationAPIPayload } from 'graphQL/useGetInviteUserOrganization/interface'

type InvitationParams = {
  orgKey: string
}

const Invitation: React.FC = () => {
  const { t } = useTranslation()

  const params = useParams<InvitationParams>() as InvitationParams

  const orgKey = params.orgKey

  const [searchValue, setSearchValue] = useState('')
  const [visibleInvitationMember, setVisibleInvitationMember] = useState(false)

  const permissionOrgTree = usePermission({
    permissionKey: 'TREE_ORGANIZATION',
    code: ['READ'],
  })

  const isPermissionInviteUserOrg = checkPermissionCanEditData({
    code: 'READ',
    permissionKey: 'INVITE_USER_ORGANIZATION',
    permissionsList: permissionOrgTree?.myPermissions,
  })
  const isPermissionInviteUserOrgEdit = checkPermissionCanEditData({
    code: 'EDIT',
    permissionKey: 'INVITE_USER_ORGANIZATION',
    permissionsList: permissionOrgTree?.myPermissions,
  })

  const inviteUserOrganizationQuery = useGetInviteUserOrganization({
    variables: {
      orgKey,
      input: {
        search: {
          email: searchValue,
        },
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [inviteUserOrganization, inviteUserOrganizationResp] = useInviteUserOrganization({
    onCompleted(resp) {
      message.success(
        t('invitationModal:inviteSuccess', {
          email: resp.inviteUserOrganization.payload.email,
        })
      )

      inviteUserOrganizationQuery.refetch()

      setVisibleInvitationMember(false)
    },
    onError(error) {
      onDefaultErrorMessage(error)

      setVisibleInvitationMember(false)
    },
  })

  const [deleteInvite, deleteInviteResp] = useDeleteInvite({
    onCompleted() {
      inviteUserOrganizationQuery.refetch()
    },
  })

  const invitationUserOrganizationData: InviteUserOrganizationAPIPayload[] =
    inviteUserOrganizationQuery.data?.getInviteUserOrganization.payload || []

  const columns: ColumnType<InviteUserOrganizationAPIPayload>[] = [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('invitationModal:label:email'),
    },
    {
      key: 'action',
      align: 'right',
      width: 200,
      render: (_text, { _id }) => {
        return (
          <Popconfirm
            title="คุณแน่ใจที่จะลบไหม?"
            onConfirm={() =>
              deleteInvite({
                variables: {
                  inviteId: _id,
                  orgKey,
                },
              })
            }
            okButtonProps={{ loading: deleteInviteResp.loading }}
          >
            <Button type="primary" danger>
              {t('invitationModal:buttonInvite')}
            </Button>
          </Popconfirm>
        )
      },
    },
  ]

  return (
    <InnerOrganizationLayout>
      <div className="admin-content-container">
        <PageTitle
          title={t('sidebar:invitation')}
          extra={
            isPermissionInviteUserOrgEdit > 0
              ? [
                  <Button type="primary" key="create" onClick={() => setVisibleInvitationMember(true)}>
                    <Space size={12}>
                      <PlusCircleOutlined />
                      {t('invitationModal:InviteMember')}
                    </Space>
                  </Button>,
                ]
              : undefined
          }
        />
        {isPermissionInviteUserOrg > 0 ? (
          <ContentCard
            title={t('invitationModal:title')}
            rightComponent={<SearchInput onSearch={(value) => setSearchValue(value)} />}
          >
            <Table
              rowKey="_id"
              dataSource={invitationUserOrganizationData}
              loading={inviteUserOrganizationQuery.loading}
              columns={columns}
              pagination={{
                pageSize: 5,
              }}
            />
            <TotalDataSourceText total={invitationUserOrganizationData.length} />
          </ContentCard>
        ) : (
          <FullWidthSpace style={{ justifyContent: 'center' }}>
            <h1>Permission Required!</h1>
          </FullWidthSpace>
        )}
      </div>

      <InvitationMemberModal
        orgKey={orgKey}
        visible={visibleInvitationMember && isPermissionInviteUserOrgEdit > 0}
        loading={inviteUserOrganizationResp.loading}
        onCancel={() => setVisibleInvitationMember(false)}
        onInviteMember={({ members }) => {
          inviteUserOrganization({
            variables: {
              emailList: members,
              orgKey,
            },
          })
        }}
      />
    </InnerOrganizationLayout>
  )
}

export default Invitation
