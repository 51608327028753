import numeral from 'numeral'
import { useTranslation } from 'react-i18next'

import type { ITotalDataSourceTextProps } from './types'

function TotalDataSourceText({ total }: ITotalDataSourceTextProps): JSX.Element {
  const { t } = useTranslation()

  const totalWithFormat = numeral(total).format('0,0')

  return (
    <div className="total-data-source-text-container">
      <span>
        {t('organization:table.record')}: {totalWithFormat}
      </span>
    </div>
  )
}

export default TotalDataSourceText
