import { Checkbox, Form, FormInstance, Input } from 'antd'
import { useTranslation } from 'react-i18next'

import Map from 'components/Map'
import OrganizationField from 'components/OrganizationField'
import LoadingIcon from 'components/LoadingIcon'
import ProvinceInput from 'components/ProvinceInput'
import ParentOrganizationSelect from 'components/ParentOrganizationSelect'

import useCheckFeatureKeyListEnable from 'hooks/useCheckFeatureKeyListEnable'
import useOrganizationFields from 'hooks/useOrganizationFields'
import usePermission from 'hooks/usePermission'

interface CreateOrUpdateOrganizationFormProps {
  form?: FormInstance<any>
  isEdit?: boolean
  shouldBeEdit?: boolean
}

const CreateOrUpdateOrganizationForm: React.FC<CreateOrUpdateOrganizationFormProps> = ({
  form,
  isEdit,
  shouldBeEdit,
}) => {
  const { t } = useTranslation('organization')
  const organizationFormTranslateKey = 'form'

  const checkFeatureKeyEnable = useCheckFeatureKeyListEnable({
    featureKeyList: ['PARENT_ORGANIZATION', 'CALL_CENTER_ORGANIZATION', 'ORG_LOCATION_BASE', 'ORGANIZATION_ADDRESS'],
  })

  const organizationField = useOrganizationFields()

  const permission = usePermission({
    permissionKey: 'SET_ORG_IS_CALLCENTER',
    code: ['EDIT', 'READ'],
  })

  const permissionOrgParent = usePermission({
    permissionKey: 'ORG_PARENT_REQUIRED',
    code: ['EDIT', 'READ'],
  })

  const requiredRule = {
    required: true,
    message: t('global:requiredText'),
  }

  return organizationField.loading ? (
    <LoadingIcon />
  ) : (
    <>
      {checkFeatureKeyEnable.PARENT_ORGANIZATION && (
        <Form.Item
          label={t(`${organizationFormTranslateKey}.parentOrganization`)}
          name="parentOrgKey"
          rules={permissionOrgParent.canAccess ? [requiredRule] : []}
        >
          <ParentOrganizationSelect isEdit={isEdit} disabled={!shouldBeEdit} />
        </Form.Item>
      )}

      <Form.Item label={t(`${organizationFormTranslateKey}.name`)} name="name" rules={[requiredRule]}>
        <Input disabled={!shouldBeEdit} />
      </Form.Item>

      {checkFeatureKeyEnable.ORG_LOCATION_BASE && (
        <Form.Item label={t(`${organizationFormTranslateKey}.locationCoordinates`)}>
          <Map form={form} shouldBeEdit={shouldBeEdit} />
        </Form.Item>
      )}

      <OrganizationField
        fieldList={organizationField.fieldList}
        masterDataList={organizationField.masterDataList}
        disabled={!shouldBeEdit}
      />

      {checkFeatureKeyEnable.ORGANIZATION_ADDRESS && <ProvinceInput disabled={!shouldBeEdit} />}

      <Form.Item hidden={!permission.canAccess}>
        {checkFeatureKeyEnable.CALL_CENTER_ORGANIZATION && (
          <Form.Item noStyle name="callCenter" valuePropName="checked">
            <Checkbox disabled={!shouldBeEdit}>{t(`${organizationFormTranslateKey}.callCenter`)}</Checkbox>
          </Form.Item>
        )}
      </Form.Item>
    </>
  )
}

export default CreateOrUpdateOrganizationForm
