import { Table } from 'antd'

import type { ITableProps } from '../Table/types'
import type { IOrganizationData } from '../Table/Organization/types'

function OrganizationDirectoryDataTable({ columns, dataSource, rowKey }: ITableProps<IOrganizationData>): JSX.Element {
  return (
    <Table
      className="organization-table"
      dataSource={dataSource}
      columns={columns}
      rowKey={rowKey}
      pagination={false}
    />
  )
}

export default OrganizationDirectoryDataTable
