import React, { useState } from 'react'
import { Form, Input, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

import { localeCode } from 'utils/localService'
import { commonFormProps } from 'utils/antdUtils'

interface ConfirmationDeleteOrgModalProps {
  loading?: boolean
  visible?: boolean
  onClose?: () => void
  onOk?: () => void
}

const ConfirmationDeleteOrgModalContent: React.FC<ConfirmationDeleteOrgModalProps> = ({
  loading,
  visible,
  onClose,
  onOk,
}) => {
  const { t, i18n } = useTranslation('organization')
  const localeIsEn = i18n.language === localeCode.enUS

  const confirmationDeleteAccountText = localeIsEn ? 'DELETE' : 'ลบหน่วยงาน'

  const [confirmationDeleteInput, setConfirmationDeleteInput] = useState('')

  return (
    <Modal
      centered
      title={t('setting.deleteOrg.title')}
      visible={visible}
      onCancel={onClose}
      confirmLoading={loading}
      onOk={onOk}
      okButtonProps={{
        danger: true,
        disabled: confirmationDeleteInput !== confirmationDeleteAccountText,
      }}
    >
      <Form {...commonFormProps}>
        <Form.Item
          label={<span style={{ color: '#ff4d4f', fontWeight: 'bold' }}>{t('setting.deleteOrg.description')}</span>}
          help={t('setting.deleteOrg.confirmationText')}
        >
          <Input value={confirmationDeleteInput} onChange={(e) => setConfirmationDeleteInput(e.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ConfirmationDeleteOrgModalContent
