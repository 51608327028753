import { Layout } from 'antd'
import { useParams } from 'react-router-dom'

import { InnerOrganizationSidebar } from 'components/Sidebar/Organization'
import AuthenticatedHeaderMenu from 'components/HeaderMenu/AuthenticatedHeaderMenu'

import { useThemeContext } from 'contexts/ThemeContext'

import { noOrganizationName } from 'constants/fallback'

import useGetOrganization from 'graphQL/useGetOrganization'
import useGetMyOrgRole from 'graphQL/useGetMyOrgRole/useGetMyOrgRole'

import { toRoleName } from 'helpers/utils'

import type { ILayoutProps } from '../types'

const { Sider, Header, Content } = Layout

type InnerOrganizationLayoutParams = {
  orgKey: string
}

function InnerOrganizationLayout({ children }: ILayoutProps): JSX.Element {
  const theme = useThemeContext()

  const params = useParams<InnerOrganizationLayoutParams>() as InnerOrganizationLayoutParams

  const organizationQuery = useGetOrganization({
    variables: {
      input: {
        query: {
          orgKey: params.orgKey,
        },
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const myListOrgRolesQuery = useGetMyOrgRole({
    variables: {
      orgKey: params.orgKey,
    },
    fetchPolicy: 'network-only',
  })

  const organizationData = organizationQuery.data?.getOrganization.payload[0]
  const myListOrgRoles = myListOrgRolesQuery.data?.getMyOrgRole.payload

  const roleName = toRoleName(myListOrgRoles)

  function toggle() {
    theme.collapseHandler(!theme.collapsed)
  }

  function getNavLogo() {
    if (theme.collapsed && theme.admin.image?.mainNavLogoCollapsed) {
      return <img className="logo" alt={theme.admin.text?.webTitle} src={theme.admin.image?.mainNavLogoCollapsed} />
    }

    if (!theme.collapsed && theme.admin.image?.navAuthLogo) {
      return <img className="logo" alt={theme.admin.text?.webTitle} src={theme.admin.image.navAuthLogo} />
    }

    return (
      <div
        style={{
          padding: 10,
          height: 64,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {theme.admin.text?.navTitle}
      </div>
    )
  }

  return (
    <Layout style={{ minHeight: '100vh' }} className="authenticated-layout">
      <Sider
        trigger={null}
        collapsible
        collapsed={theme.collapsed}
        theme="light"
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
      >
        {getNavLogo()}

        <InnerOrganizationSidebar isCollapsed={theme.collapsed} orgKey={params.orgKey} />
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: theme.collapsed ? 80 : 200 }}>
        <Header
          className="authenticated-header"
          style={{ position: 'fixed', zIndex: 1, width: `calc(100% - ${theme.collapsed ? 80 : 200}px)` }}
        >
          <AuthenticatedHeaderMenu
            isInnerOrganization
            collapsed={theme.collapsed}
            toggle={toggle}
            roleName={roleName}
            organizationName={organizationData?.name || noOrganizationName}
          />
        </Header>
        <Content style={{ marginTop: 64 }}>
          <div className="authenticated-content-container">{children}</div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default InnerOrganizationLayout
