import type { FC } from 'react'

import { SystemServiceDashBoard } from 'components/Dashboard/Service/index'

const SystemAdminServicePage: FC = () => {
  const dataSource = [
    {
      key: 'core',
      name: 'Hooray Core Service',
      description: 'Core API & Admin Pannel',
      status: 'Active',
    },
    {
      key: 'elearning',
      name: 'Hooray e-Learning Service',
      description: 'e-Learning Platform',
      status: 'Active',
    },
  ]

  return <SystemServiceDashBoard dataSource={dataSource} />
}

export default SystemAdminServicePage
