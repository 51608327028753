import { gql } from '@apollo/client'

export const READ_ALL_NOTIFICATION = gql`
  mutation ReadAllNotification {
    readAllNotification {
      message
      code
      unread
      pagination {
        limit
        page
        totalItems
        totalPages
      }
      payload {
        _id
        title
        content
        attribute
        icon
        type
        from
        to
        read
        createdAt
        updatedAt
      }
    }
  }
`
