import { gql } from '@apollo/client'

const GET_MY_ORG_ROLE = gql`
  query GetMyOrgRole($orgKey: String!) {
    getMyOrgRole(orgKey: $orgKey) {
      message
      code
      payload {
        name
        roleKey
      }
    }
  }
`

export default GET_MY_ORG_ROLE
