import { useMutation } from '@apollo/client'
import { getUserTypeEndpoint } from 'utils/user'
import { appLocalAccessToken } from 'utils/localService/app'
import { READ_ALL_NOTIFICATION } from './readAllNotification'
import { ReadNotificationAPIData } from './interface'
import type { GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'

export const useReadAllNotification: GraphQLServiceMutationHook<ReadNotificationAPIData> = (options) => {
  return useMutation(READ_ALL_NOTIFICATION, {
    context: {
      uri: getUserTypeEndpoint(appLocalAccessToken.get()),
    },
    ...options,
  })
}
