import { checkUserPermission } from 'utils/permission'
import { useEffect, useState } from 'react'

import { usePermissionContext } from 'contexts/PermissionContext'

import type { CustomGetMyPermissionData } from 'graphQL/queryResponseTypes'

const usePermission = (permissions: CustomGetMyPermissionData | CustomGetMyPermissionData[]) => {
  const [canAccess, setCanAccess] = useState(false)

  const permission = usePermissionContext()

  useEffect(() => {
    setCanAccess(checkUserPermission(permissions, permission.myPermissions))
  }, [permission.myPermissions, permissions])

  return {
    canAccess,
    myPermissions: permission.myPermissions,
    permissions,
  }
}

export default usePermission
