import type { FC } from 'react'

import { useTranslation } from 'react-i18next'

import useGenerateOAuthLink from 'hooks/useGenerateOAuthLink'

import FullScreenLoading from 'components/FullScreenLoading'

import { goToSignInPage } from 'config'

const SignInPage: FC = () => {
  const { t } = useTranslation()

  const { loading, url } = useGenerateOAuthLink()

  if (!loading) {
    goToSignInPage(url)
  }

  return <FullScreenLoading>{t('auth:whileRedirectToSignInPage')}</FullScreenLoading>
}

export default SignInPage
