import { gql } from '@apollo/client'
import { ROLE } from 'graphQL/schemas/role'

const UPDATE_ROLE = gql`
  mutation updateRole($roleKey: String!, $roleInput: INPUT_ROLE_FROM){
      updateRole(roleKey: $roleKey, roleInput: $roleInput) {
        message
        code
        payload {${ROLE}}
      }
    }
`

export default UPDATE_ROLE
