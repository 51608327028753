import type { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { DownOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu, Space, Typography } from 'antd'

import useAuth from 'contexts/useAuthContext'

import useGetMyAppRole from 'graphQL/useGetMyAppRole/useGetMyAppRole'
import useLogOut from 'graphQL/useLogOut'

import FontawesomeIcon from 'components/FontawesomeIcon'

import { paths } from 'setup/PageRouter'

const UserMenuDropdown: FC = () => {
  const { t } = useTranslation('userMenu')
  const navigate = useNavigate()

  const { data } = useGetMyAppRole()

  const auth = useAuth()

  const user = auth.user
  const userAttribute = user?.attribute
  const image = userAttribute?.profilePictureFileKey || []

  

  const displayName =
    userAttribute?.firstName && userAttribute?.lastName
      ? `${userAttribute.firstName} ${userAttribute.lastName}`
      : user?.email[0].value
  const roleName = data?.getMyAppRole.payload.map((item) => item.name).join(',')

  const [logOutUser] = useLogOut({
    onCompleted() {
      navigate(paths.signOut)
    },
  })

  return (
    <>
      <GlobalStyle />

      <Dropdown overlayClassName="user-dropdown-menu" overlay={renderMemu()} trigger={['click']}>
        <a href="#open-user-menu" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <Space size={8}>
            {image.length > 0 ?  <Avatar src={process.env.REACT_APP_API_S3_HOST+image[0]} /> :
            <Avatar icon={<UserOutlined />} /> }
            <Space size={2}>
              <DisplayName ellipsis>{displayName}</DisplayName>
              <DownOutlined
                style={{
                  color: 'var(--app-navAuthTextColor)',
                }}
              />
            </Space>
          </Space>
        </a>
      </Dropdown>
    </>
  )

  function signOutSystem() {
    logOutUser({
      variables: {
        userId: user?._id || '',
      },
    })
  }

  function renderMemu() {
    return (
      <Menu>
        {roleName && (
          <Menu.Item key="role-name" className="modified-item primary-color">
            {roleName}
          </Menu.Item>
        )}

        <Menu.Item
          key="profile-management"
          onClick={() => window.open('/profile')}
          icon={<FontawesomeIcon iconName="user" style={{ marginRight: 4 }} />}
        >
          {t('button:profile')}
        </Menu.Item>
        <Menu.Item
          key="logout-menu"
          onClick={() => signOutSystem()}
          icon={<FontawesomeIcon iconName="sign-out-alt" style={{ marginRight: 4 }} />}
        >
          {t('button:logout')}
        </Menu.Item>
      </Menu>
    )
  }
}

export default UserMenuDropdown

const GlobalStyle = createGlobalStyle`
  .user-dropdown-menu {
    position: fixed;
    top: 50px !important;
    > .ant-dropdown-menu {
      margin-top: 8px;
    }
  }

  .display-name-tooltip {
    top: 50px !important;
  }
`

const DisplayName = styled(Typography.Text)<{ color?: string }>`
  width: 150px;
  text-align: right;
  font-size: 14px;
  color: var(--app-navAuthTextColor);
`
