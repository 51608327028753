import { useState } from 'react'
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Dropdown, Form, Menu } from 'antd'

import UserField from 'components/Table/AppRole/UserField'
import UserFieldModel from 'components/Modal/AppRole/UserField'

import { useTranslation } from 'react-i18next'

import useUpdateRole from 'graphQL/useUpdateRole/useUpdateRole'

import type { IUserFieldData } from 'components/Table/AppRole'
import type { ColumnsType } from 'antd/lib/table'

function UserFieldPage({ role, refetch }: { role: any; refetch(): void }): JSX.Element {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [posationField, setPosationField] = useState<number>(-1)

  const [isShowDialog, setIsShowDialog] = useState(false)
  const onCancelModel = () => {
    form.resetFields()
    setIsShowDialog(false)
  }
  const [updateRole] = useUpdateRole({
    onCompleted() {
      onCancelModel()
      refetch()
    },
  })

  const onFinish = (values: any) => {
    const newValues = {
      ...values,
      required: values.required === 'YES' ? true : false,
      isUnique: values.isUnique === 'YES' ? true : false,
    }
    let userField = [...role.userField]
    userField[posationField] = newValues

    updateRole({
      variables: {
        roleKey: role.roleKey,
        roleInput: {
          userField: userField,
        },
      },
    })
  }

  const editMenuOnClick = (value: any, index: number) => {
    const newValue = { ...value, required: value.required ? 'YES' : 'NO', isUnique: value.isUnique ? 'YES' : 'NO' }
    form.setFieldsValue(newValue)
    setPosationField(index)
    setIsShowDialog(true)
  }
  const menu = (value: any, index: number) => {
    return (
      <Menu>
        <Menu.Item onClick={() => editMenuOnClick(value, index)}>
          <EditOutlined className="primary-color" />{' '}
          <span className="primary-color">{t('appRole:userField:table:action:edit')}</span>
        </Menu.Item>
        <Menu.Item>
          <CloseCircleOutlined className="reviewing-color" />{' '}
          <span className="reviewing-color">{t('appRole:userField:table:action:disable')}</span>
        </Menu.Item>
      </Menu>
    )
  }
  const columns: ColumnsType<IUserFieldData> = [
    {
      title: t('appRole:userField:table:title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('appRole:userField:table:key'),
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: t('appRole:userField:table:type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('appRole:userField:table:placeholder'),
      dataIndex: 'placeholder',
      key: 'placeholder',
    },
    {
      dataIndex: '',
      render: (_text, rander, index) => {
        return (
          <Dropdown overlay={menu(rander, index)}>
            <Button shape="round" type="primary">
              Action
            </Button>
          </Dropdown>
        )
      },
    },
  ]
  return (
    <>
      <UserFieldModel isShowDialog={isShowDialog} onCancel={onCancelModel} onFinish={onFinish} form={form} />
      <UserField dataSource={role.userField} rowKey={'user-filed'} columns={columns} />
    </>
  )
}

export default UserFieldPage
