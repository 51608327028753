import { gql } from '@apollo/client'

export const GENERATE_AUTH_CODE = gql`
  mutation {
    generateAuthCode {
      payload {
        profile {
          _id
          email {
            value
          }
        }
        token {
          accessToken
        }
        code
        credentialKey
      }
    }
  }
`

export default GENERATE_AUTH_CODE
