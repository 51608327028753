import { Select, Spin } from 'antd'

import useOrganizationAutoCompleteV2 from 'hooks/useOrganizationAutoCompleteV2'

import { FC, useState } from 'react'

interface ParentOrganizationSelectProps {
  isEdit?: boolean
  disabled?: boolean
  value?: string
  onChange?: (value: string) => void
}

const ParentOrganizationSelect: FC<ParentOrganizationSelectProps> = ({ isEdit, disabled, value, onChange }) => {
  const [firstParentOrganizationQueryLoading, setFirstParentOrganizationQueryLoading] = useState(true)

  const { loading, options, searchKeyword, searchLength, resetSearch, onSearch } = useOrganizationAutoCompleteV2({
    orgKey: isEdit ? value : undefined,
    onQueryComplete() {
      setFirstParentOrganizationQueryLoading(false)
    },
  })

  const notFoundMessage =
    searchKeyword.length < searchLength ? `กรุณาระบุชื่อหน่วยงานอย่างน้อย ${searchLength} ตัว` : 'ไม่พบหน่วยงานที่ระบุ'

  if (firstParentOrganizationQueryLoading) {
    return <Spin size="small" />
  }

  return (
    <Select
      allowClear
      showSearch
      onSearch={onSearch}
      onClear={resetSearch}
      disabled={disabled}
      filterOption={false}
      notFoundContent={loading ? <Spin size="small" /> : notFoundMessage}
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

export default ParentOrganizationSelect
