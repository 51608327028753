import { ApolloProvider } from '@apollo/client'
import { ConfigProvider } from 'antd'
import thTH from 'antd/lib/locale/th_TH'
import enUS from 'antd/lib/locale/en_US'

import { AuthProvider } from 'contexts/useAuthContext'
import PermissionProvider from 'contexts/PermissionContext'
import ThemeProvider from 'contexts/ThemeContext'

import apolloClient from 'setup/apolloClient'
import PageRouter from 'setup/PageRouter'

import { appLocalLocale } from 'utils/localService'

import 'configuration'

import type { FC } from 'react'

const App: FC = () => {
  const localeIsEn = appLocalLocale.get() === 'enUS'

  return (
    <ConfigProvider locale={localeIsEn ? enUS : thTH} componentSize="large">
      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <PermissionProvider>
            <ThemeProvider>
              <PageRouter />
            </ThemeProvider>
          </PermissionProvider>
        </AuthProvider>
      </ApolloProvider>
    </ConfigProvider>
  )
}

export default App
