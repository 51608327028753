import type { FC } from 'react'

import { Pagination, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

import ContentCard from 'components/Card/Content'
import PageTitle from 'components/PageTitle'
import FullScreenLoading from 'components/FullScreenLoading'

import useGetLog from 'graphQL/useGetLog'
import useAntdPagination from 'hooks/useAntdPagination'
import LogAndHistory from 'components/Dashboard/LogAndHistory'
import InnerOrganizationLayout from 'layouts/InnerOrganizationLayout'

const OrganizationHistory: FC = () => {
  const { t } = useTranslation()

  const loading = false
  const { pagination, pageSizeOptions, onChange } = useAntdPagination()

  const orgLogResp = useGetLog({
    variables: {
      find: {
        query: {
          action: { $in: ['LOGOUT', 'LOGIN'] },
        },
        pagination: {
          limit: pagination.limit,
          page: pagination.page,
        },
        sort: {
          updatedAt: -1,
        },
      },
    },
  })

  const isLoading = orgLogResp.loading
  const orgLogData = orgLogResp.data?.getLog

  return (
    <InnerOrganizationLayout>
      {loading ? (
        <FullScreenLoading />
      ) : (
        <>
          <div className="admin-content-container">
            <PageTitle isRootPage title={t('organization:page.home')} />

            <ContentCard loading={isLoading} title="" rightComponent="">
              {!isLoading && orgLogData ? (
                <>
                  <LogAndHistory logData={orgLogData.payload} />
                  <div>
                    <Pagination
                      className="admin-content-container-pagination"
                      pageSize={pagination.limit}
                      current={pagination.page}
                      total={pagination?.totalItems}
                      showTotal={(total) => `${t('organization:table.record')}: ${total}`}
                      onChange={onChange}
                      hideOnSinglePage
                      pageSizeOptions={pageSizeOptions}
                    />
                  </div>
                </>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <Spin size="large" className="w-100 text-center" />
                </div>
              )}
            </ContentCard>
          </div>
        </>
      )}
    </InnerOrganizationLayout>
  )
}

export default OrganizationHistory
