import { Space, Table } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'

function AdministratorDashboard() {
  const columns = [
    {
      // eslint-disable-next-line no-undef
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'action',
      render: (_text: any, render: any) => (
        <span key="status-render" className="service-status">
          <Space size={5} style={{ color: 'green', fontSize: '15px' }}>
            <CheckCircleOutlined />
            {render.status}
          </Space>
        </span>
      ),
    },
  ]

  const data = [
    {
      key: '1',
      name: 'Khomphet Meesub',
      email: 'komphet.me@lattesoft.co.th',
      status: 'Active',
    },
    {
      key: '2',
      name: 'Jim Green',
      email: 'komphet.me@lattesoft.co.th',
      status: 'Active',
    },
    {
      key: '3',
      name: 'Joe Black',
      email: 'komphet.me@lattesoft.co.th',
      status: 'Active',
    },
  ]
  return <Table dataSource={data} columns={columns} />
}

export default AdministratorDashboard
