import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import dayjs from 'dayjs'
import dateEN from 'dayjs/locale/en'
import dateTH from 'dayjs/locale/th'

import resources from 'locales'

import { localeCode, appLocalLocale } from 'utils/localService'

const setupI18n = () => {
  let locale = appLocalLocale.get()

  if (!locale) {
    locale = localeCode.thTH
    appLocalLocale.set(locale)
  }

  const isThai = locale === localeCode.thTH

  dayjs.locale(isThai ? dateTH : dateEN)

  i18n.use(initReactI18next).init({
    resources,
    lng: locale,
    fallbackLng: localeCode.enUS,
    interpolation: {
      escapeValue: false,
    },
    debug: process.env.REACT_APP_I18N_DEBUG === 'true',
  })
}

export default setupI18n
