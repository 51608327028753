import { SaveOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import useCheckFeatureKeyListEnable from 'hooks/useCheckFeatureKeyListEnable'

import { GET_ORG_CONFIRMATIONDETAIL } from 'graphQL/schemas/organization'

import CreateOrUpdateOrganizationForm from 'components/CreateOrUpdateOrganizationForm'

import { commonFormProps } from 'utils/antdUtils'

import type { IOrganizationFormProps } from './types'
import type { OrganizationInput } from './types'

function NewOrganizationForm({ onFinish, loading }: IOrganizationFormProps): JSX.Element {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const checkFeatureKeyEnable = useCheckFeatureKeyListEnable({
    featureKeyList: [
      'PARENT_ORGANIZATION',
      'CALL_CENTER_ORGANIZATION',
      'ORGANIZATION_CATEGORY',
      'ORG_LOCATION_BASE',
      'ORG_CONFIRMATIONDETAIL',
    ],
  })

  const [OrgConfirmationDetail] = useMutation(GET_ORG_CONFIRMATIONDETAIL, {
    onCompleted(resp) {
      const data = resp.orgConfirmationDetail.payload
      message.success('Load data success.')
      if (data) {
        form.setFieldsValue({
          contactEmail: data.contactEmailList[0],
          contactName: data.contactName,
          organizationName: data.name,
          position: {
            lng: data.location[0],
            lat: data.location[1],
          },
        })
      }
    },
    onError(error) {
      console.log('error', error)
    },
  })
  const getOrgConfirmationDetail = () => {
    let schoolID: String = form.getFieldValue('schoolID')
    OrgConfirmationDetail({
      variables: {
        schoolId: schoolID,
      },
    })
  }

  return (
    <Form
      {...commonFormProps}
      form={form}
      name="organization"
      onFinish={handleOnFinish}
      labelAlign="left"
      className="new-organization-form"
    >
      <Space direction="vertical" size={16}>
        {checkFeatureKeyEnable.ORG_CONFIRMATIONDETAIL && (
          <Form.Item name="schoolID" label={t('organization:new.schoolID')}>
            <Input />
          </Form.Item>
        )}

        {checkFeatureKeyEnable.ORG_CONFIRMATIONDETAIL && (
          <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
            <Button type="primary" onClick={getOrgConfirmationDetail} icon={<VerticalAlignBottomOutlined />}>
              {t('organization:new.importBigData')}
            </Button>
          </Form.Item>
        )}

        <CreateOrUpdateOrganizationForm form={form} shouldBeEdit />

        <Button type="primary" htmlType="submit" loading={loading} icon={<SaveOutlined />}>
          {t('organization:new.saveOrganization')}
        </Button>
      </Space>
    </Form>
  )

  function handleOnFinish(values: OrganizationInput) {
    const {
      callCenter,
      email,
      contactLanguage,
      contactName,
      name,
      parentOrgKey,
      countryCode,
      number,
      position,
      schoolID,
      province,
      district,
      subDistrict,
      postCode,
      ...rest
    } = values

    const coordinates = position?.lat && position?.lng && [position?.lng, position?.lat]
    const address = {
      province,
      district,
      subDistrict,
      postCode,
    }

    const result = {
      parentOrgKey,
      name,
      contactName,
      contactEmailList: email ? [email] : undefined,
      address,
      attribute: {
        ...rest,
        contactLanguage,
        callCenter: callCenter ? 'YES' : 'NO',
        schoolID: schoolID,
      },
      location: coordinates
        ? {
            coordinates,
          }
        : undefined,
    }

    onFinish(result)
  }
}

export default NewOrganizationForm
