import { getAppDataFromStorage } from 'utils/app'
import { ServiceHook } from './interface'

interface CheckFeatureKeyListEnableOptions {
  featureKeyList: string[]
}

interface CheckFeatureKeyListEnableResponse {
  [featureKey: string]: boolean
}

const useCheckFeatureKeyListEnable: ServiceHook<CheckFeatureKeyListEnableResponse, CheckFeatureKeyListEnableOptions> = (
  options
) => {
  const app = getAppDataFromStorage()
  const requiredFeatureKeysList = options?.featureKeyList || []
  const featureKeysList = app?.app.featureList || []

  const featureKeyListToObject = featureKeysList
    ?.filter((item) => requiredFeatureKeysList.includes(item.featureKey))
    .reduce((a, v) => ({ ...a, [v.featureKey]: v.enable }), {})

  return featureKeyListToObject
}

export default useCheckFeatureKeyListEnable
