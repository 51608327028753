import { FC } from 'react'

import { sentenceCase } from 'change-case'

import AppRoleLayout from 'layouts/AppRole'
import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import ContentCard from 'components/Card/Content'
import PageTitle from 'components/PageTitle'
import ApplicationRolePermission from 'components/ApplicationRolePermission'

import useQueryString from 'hooks/useQueryString'

import { useGetAppServiceQuery } from 'graphQL/generated/operations'
let roleKeyData = ''

const ApplicationRolePermissionPageV2: FC = () => {
  const query = useQueryString()

  const roleKey = query.get('role_key') || ''
  if (roleKey) {
    roleKeyData = roleKey
  }
  const appServicesQuery = useGetAppServiceQuery()

  const listAppServices = appServicesQuery.data?.getAppService.payload
  const listServices = listAppServices?.serviceList || []

  return (
    <AuthenticatedLayout>
      <AppRoleLayout roleKey={roleKeyData}>
        <div className="admin-content-container">
          <PageTitle title="" />
          <ContentCard title={`Permission of ${sentenceCase(roleKeyData)}`}>
            <ApplicationRolePermission roleKey={roleKeyData} listServices={listServices} />
          </ContentCard>
        </div>
      </AppRoleLayout>
    </AuthenticatedLayout>
  )
}

export default ApplicationRolePermissionPageV2
