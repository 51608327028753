import { gql } from '@apollo/client'

const SYSTEM_ADMIN_PROFILE_LIST = gql`
  query getSystemAdminProfile {
    getSystemAdminProfile {
      payload {
        email {
          value
          verifyStatus
        }
        attribute
        _id
      }
    }
  }
`

export default SYSTEM_ADMIN_PROFILE_LIST
