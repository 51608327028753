import type { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import ContentCard from 'components/Card/Content'
import { AdministratorDetailForm } from 'components/Form/Administrator'
import PageTitle from 'components/PageTitle'

import InnerOrganizationLayout from 'layouts/InnerOrganizationLayout'

const OrganizationMemberDetail: FC = () => {
  const navigate = useNavigate()

  return (
    <InnerOrganizationLayout>
      <div className="admin-content-container">
        <PageTitle title="New Administrator" onBack={() => navigate(-1)} />
        <ContentCard title="Create new Administrator">
          <AdministratorDetailForm onFinish={() => {}} loading={false} />
        </ContentCard>
      </div>
    </InnerOrganizationLayout>
  )
}

export default OrganizationMemberDetail
