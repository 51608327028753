import { Col, Row, Space } from 'antd'
import type { ILogJSONDataProps } from '../types'

function LogJSONData({ currentData, prevData }: ILogJSONDataProps): JSX.Element {
  function renderData(data: any, tabWidth = 2) {
    return <pre style={{ color: '#999' }}>{JSON.stringify(data, null, tabWidth)}</pre>
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <Space direction="vertical">
          <span className="font-bold">Previous Data</span>
          {renderData(prevData)}
        </Space>
      </Col>
      <Col span={12}>
        <Space direction="vertical">
          <span className="font-bold">Current Data</span>
          {renderData(currentData)}
        </Space>
      </Col>
    </Row>
  )
}

export default LogJSONData
