import { useMutation } from '@apollo/client'

import { onDefaultErrorMessage } from 'utils/apollo'

import MUTATION_DOCUMENT from './generateAuthCode'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'

export interface GenerateAuthCodeAPIPayload {
  code: string
  credentialKey: string
}
interface GenerateAuthCodeData {
  generateAuthCode: APIPayloadResponse<GenerateAuthCodeAPIPayload>
}

interface GenerateAuthCodeVars {
  orgKey: string
}

const useGenerateAuthCode: GraphQLServiceMutationHook<GenerateAuthCodeData, GenerateAuthCodeVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGenerateAuthCode
