import type { FC } from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import PageTitle from 'components/PageTitle'
import { Notification } from 'components/Notification'

import { paths } from 'setup/PageRouter'

const NotificationDetailPage: FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <AuthenticatedLayout>
      <div className="admin-content-container">
        <PageTitle title={t('notificationDetail:title')} onBack={() => navigate(paths.notification)} />

        <Notification />
      </div>
    </AuthenticatedLayout>
  )
}

export default NotificationDetailPage
