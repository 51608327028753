import { Status } from 'graphQL/graphQL-service-hook'
import numeral from 'numeral'

export const DATE_FORMAT = 'DD MMM YYYY'
export const TIME_FORMAT = 'hh:mm A'
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`
export const EVENT_DATE_FORMAT = 'ddd, DD MMM'

export function isVideoSource(src: string) {
  return /^data:.+\/mp4|.\.mp4$/.test(src)
}

export function numberWithComma(numberValue: number | string) {
  return numeral(numberValue).format('0,0')
}

export function toEllipsisText(text = '', sliceLength = 20) {
  if (text.length < sliceLength) {
    return text
  }

  return text.slice(0, sliceLength).concat('...')
}

export const convertStatusToBoolean = (status: Status = 'INACTIVE') => {
  return status === 'ACTIVE'
}

export const convertBooleanToStatus = (booleanValue: boolean = false) => {
  return booleanValue ? 'ACTIVE' : 'INACTIVE'
}
