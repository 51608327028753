import { Col, Row, Card, Space, DatePicker } from 'antd'
import Widget from 'components/Widget'
import dayjs from 'dayjs'
import useAppServiceWidget from 'hooks/useAppServiceWidget'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
const { RangePicker } = DatePicker

type AppDashboardParams = {
  orgKey: string
}

const AppDashboard: React.FC = () => {
  const appWidget = useAppServiceWidget()

  const params = useParams<AppDashboardParams>() as AppDashboardParams

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const getStartDate = () => {
    let dateString: any
    const newDate = new Date()
    const dateNumber = newDate.getDate() - 6
    const setDateData = newDate.setDate(dateNumber)
    dateString = dayjs(setDateData).format('YYYY-MM-DD').toString()

    return dateString
  }

  const getEndDate = () => {
    let dateString: any
    const newDate = new Date()
    const dateNumber = newDate.getDate()
    const setDateData = newDate.setDate(dateNumber)
    dateString = dayjs(setDateData).format('YYYY-MM-DD').toString()

    return dateString
  }

  if (startDate === '') setStartDate(getStartDate())
  if (endDate === '') setEndDate(getEndDate())

  const onChange = (value: any, dateString: string[]) => {
    if (dateString.length) {
      setStartDate(dateString[0])
      setEndDate(dateString[1])
    }
  }

  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Card style={{ textAlign: 'center' }}>
            <Space direction="vertical" size={12}>
              <RangePicker defaultValue={[moment(startDate), moment(endDate)]} onChange={onChange} />
            </Space>
          </Card>
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        {appWidget.listAppWidget.map((item) => {
          return item.widgetList.map((widget) => {
            return (
              <Col key={widget.path} span={widget.display.grid}>
                <Widget
                  orgKey={params.orgKey}
                  widget={widget}
                  pattern={item.pattern}
                  endpoint={item.endpoint}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Col>
            )
          })
        })}
      </Row>
    </>
  )
}

export default AppDashboard
