import { useQuery } from '@apollo/client'

import QUERY_DOCUMENT from './getAppByCredential'

import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { AppByCredentialAPIPayload } from './interface'

interface AppByCredentialData {
  getAppByCredential: APIPayloadResponse<AppByCredentialAPIPayload>
}

interface AppByCredentialVars {
  credentialKey: string
}

const useGetAppByCredential: GraphQLServiceQueryHook<AppByCredentialData, AppByCredentialVars> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    ...options,
  })
}

export default useGetAppByCredential
