import { appLocalApp } from './localService'

import type { Application, Credential } from 'graphQL/graphQL-service-hook'

type S3UrlEndpointType = 'PRIVATE' | 'PUBLIC'

export interface AppDataStorage {
  app: Application
  credential: Credential
}

export const getAppDataFromStorage = (): AppDataStorage | null => {
  const app = appLocalApp.get()

  return app == null ? app : JSON.parse(app)
}

export const getS3UrlByType = (type: S3UrlEndpointType = 'PUBLIC'): string => {
  const app = getAppDataFromStorage()

  let s3Url = ''

  if (!app?.app?.configurationList) {
    return s3Url
  }

  app.app.configurationList.forEach((configuration) => {
    if (configuration.configKey === 'S3_PUBLIC_CONFIG' && type === 'PUBLIC') {
      s3Url = configuration.value.publicEndpoint
    }
  })

  return s3Url
}

export const getFeatureParentOrganization = (): boolean => {
  let parent = false
  const app = getAppDataFromStorage()
  const featureList = app?.app?.featureList
  if (!featureList) {
    return parent
  }
  const parentOrganization = featureList.find((e) => e.featureKey === 'PARENT_ORGANIZATION')
  parent = parentOrganization?.enable || false
  return parent
}
