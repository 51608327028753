import { Pagination } from 'graphQL/graphQL-service-hook'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface TableSummaryProps {
  pagination?: Pagination
}

const TableSummary: FC<TableSummaryProps> = ({ pagination }) => {
  const { t } = useTranslation()

  const total = pagination?.totalItems || 0

  return (
    <div>
      {t('summary:table:all', {
        total,
      })}
    </div>
  )
}

export default TableSummary
