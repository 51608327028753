import type { RouteProps } from 'react-router'

import OAuthPage from 'pages/oauth/OAuthPage'
import SignInPage from 'pages/signIn/SignInPage'
import ApplicationRolePermissionPageV2 from 'pages/appRole/ApplicationRolePermissionPageV2'
import AppRoleSettingPage from 'pages/appRole/AppRoleSettingPage'
import CreateOrganizationPage from 'pages/organization/CreateOrganizationPage'
import OrganizationSettingPage from 'pages/organization/OrganizationSettingPage'
import OrganizationInvitationPage from 'pages/organization/OrganizationInvitationPage'
import OrganizationMemberLog from 'pages/organization/OrganizationMemberLog'
import OrganizationMemberDetail from 'pages/organization/OrganizationMemberDetail'
import OrganizationMemberPage from 'pages/organization/OrganizationMemberPage'
import OrganizationHistory from 'pages/organization/OrganizationHistory'
import OrganizationIFramePage from 'pages/organization/OrganizationIFramePage'
import OrganizationPage from 'pages/organization/OrganizationPage'
import IFrameApplicationPage from 'pages/iframeApplication/IFrameApplicationPage'
import OrgApprovalPage from 'pages/orgApproval/OrgApprovalPage'
import NotificationDetailPage from 'pages/notification/NotificationDetailPage'
import NotificationPage from 'pages/notification/NotificationPage'
import MasterDataPage from 'pages/masterData/MasterDataPage'
import DashboardPage from 'pages/dashboard/DashboardPage'
import SignOutPage from 'pages/signOut/SignOutPage'
import ProfilePage from 'pages/ProfilePage'
import AppRolePage from 'pages/appRole/AppRolePage'
import CreateSystemAdminServicePage from 'pages/systemAdmin/CreateSystemAdminServicePage'
import SystemAdminCoreServicePage from 'pages/systemAdmin/SystemAdminCoreServicePage'
import CreateSystemAdminAdministratorPage from 'pages/systemAdmin/CreateSystemAdminAdministratorPage'
import SystemAdminAdministratorDetailPage from 'pages/systemAdmin/SystemAdminAdministratorDetailPage'
import CreateSystemAdminApplicationPage from 'pages/systemAdmin/CreateSystemAdminApplicationPage'
import ManageApplication from 'components/Dashboard/Application/ManageApplication'
import SystemAdminPage from 'pages/systemAdmin/SystemAdminPage'

import { checkUserPermission } from 'utils/permission'

import type { GetMyPermissionData } from 'graphQL/queryResponseTypes'

export type RouteKey =
  | 'root'
  | 'signIn'
  | 'oauth'
  | 'appRole'
  | 'appRolePermissionPage'
  | 'appRoleSettingPage'
  | 'organization'
  | 'organizationCreate'
  | 'organizationMember'
  | 'organizationMemberDetail'
  | 'organizationMemberLog'
  | 'organizationInvitation'
  | 'organizationSetting'
  | 'organizationHistory'
  | 'organizationDetail'
  | 'applicationDetail'
  | 'systemAdmin'
  | 'systemAdminServiceCreate'
  | 'systemAdminApplicationCreate'
  | 'systemAdminApplicationManage'
  | 'systemAdminServiceDetail'
  | 'systemAdminAdministratorCreate'
  | 'systemAdminAdministratorDetail'
  | 'orgApproval'
  | 'signOut'
  | 'notification'
  | 'notificationDetail'
  | 'masterData'
  | 'dashboard'
  | 'profile'

interface Middleware {
  requiredAuth?: boolean
  permissionList?: GetMyPermissionData[]
}

interface HoorayRoute extends RouteProps {
  key: RouteKey
  middleware?: Middleware
}

export const paths: Record<RouteKey, string> = {
  root: '/',
  signIn: '/sign-in',
  oauth: '/oauth',
  appRole: '/app-role',
  appRolePermissionPage: '/app-role/permission',
  appRoleSettingPage: '/app-role/:page',
  organization: '/organization',
  organizationCreate: '/organization/create',
  organizationMember: '/organization/:orgKey/permissions/member',
  organizationMemberDetail: '/organization/:orgKey/permissions/member/:id',
  organizationMemberLog: '/organization/:orgKey/permissions/member/:id/log',
  organizationInvitation: '/organization/:orgKey/permissions/invitation',
  organizationSetting: '/organization/:orgKey/organization-setting',
  organizationHistory: '/organization/:orgKey/history-log',
  organizationDetail: '/organization/:orgKey/:slug',
  applicationDetail: '/app/:slug',
  systemAdmin: '/system-admin/:page',
  systemAdminServiceCreate: '/system-admin/service/create',
  systemAdminApplicationCreate: '/system-admin/application/create',
  systemAdminApplicationManage: '/system-admin/application/manage',
  systemAdminServiceDetail: '/system-admin/service/:key/coreservice',
  systemAdminAdministratorCreate: '/system-admin/administrator/create',
  systemAdminAdministratorDetail: '/system-admin/administrator/detail',
  orgApproval: '/org-approval',
  signOut: '/sign-out',
  notification: '/notification',
  notificationDetail: '/notification/:id',
  masterData: '/master-data',
  dashboard: '/dashboard',
  profile: '/profile',
}

export const publicRoutes: HoorayRoute[] = [
  {
    key: 'oauth',
    path: paths.oauth,
    element: <OAuthPage />,
  },
  {
    key: 'signIn',
    path: paths.signIn,
    element: <SignInPage />,
  },
]

export const adminPrivateRoutes: HoorayRoute[] = [
  {
    key: 'appRolePermissionPage',
    path: paths.appRolePermissionPage,
    element: <ApplicationRolePermissionPageV2 />,
  },
  {
    key: 'appRoleSettingPage',
    path: paths.appRoleSettingPage,
    element: <AppRoleSettingPage />,
  },
  {
    key: 'appRole',
    path: paths.appRole,
    element: <AppRolePage />,
  },
  {
    key: 'organizationCreate',
    path: paths.organizationCreate,
    element: <CreateOrganizationPage />,
  },
  {
    key: 'organizationSetting',
    path: paths.organizationSetting,
    element: <OrganizationSettingPage />,
  },
  {
    key: 'organizationInvitation',
    path: paths.organizationInvitation,
    element: <OrganizationInvitationPage />,
  },
  {
    key: 'organizationMemberLog',
    path: paths.organizationMemberLog,
    element: <OrganizationMemberLog />,
  },
  {
    key: 'organizationMemberDetail',
    path: paths.organizationMemberDetail,
    element: <OrganizationMemberDetail />,
  },
  {
    key: 'organizationMember',
    path: paths.organizationMember,
    element: <OrganizationMemberPage />,
  },
  {
    key: 'organizationHistory',
    path: paths.organizationHistory,
    element: <OrganizationHistory />,
  },
  {
    key: 'organizationDetail',
    path: paths.organizationDetail,
    element: <OrganizationIFramePage />,
  },
  {
    key: 'organization',
    path: paths.organization,
    element: <OrganizationPage />,
  },
  {
    key: 'applicationDetail',
    path: paths.applicationDetail,
    element: <IFrameApplicationPage />,
  },
  {
    key: 'orgApproval',
    path: paths.orgApproval,
    element: <OrgApprovalPage />,
  },
  {
    key: 'notificationDetail',
    path: paths.notificationDetail,
    element: <NotificationDetailPage />,
  },
  {
    key: 'notification',
    path: paths.notification,
    element: <NotificationPage />,
  },
  {
    key: 'masterData',
    path: paths.masterData,
    element: <MasterDataPage />,
  },
  {
    key: 'dashboard',
    path: paths.dashboard,
    element: <DashboardPage />,
  },
  {
    key: 'signOut',
    path: paths.signOut,
    element: <SignOutPage />,
  },
  {
    key: 'profile',
    path: paths.profile,
    element: <ProfilePage />,
  },
]

export const systemAdminPrivateRoutes: HoorayRoute[] = [
  {
    key: 'systemAdminServiceCreate',
    path: paths.systemAdminServiceCreate,
    element: <CreateSystemAdminServicePage />,
  },
  {
    key: 'systemAdminServiceDetail',
    path: paths.systemAdminServiceDetail,
    element: <SystemAdminCoreServicePage />,
  },
  {
    key: 'systemAdminAdministratorCreate',
    path: paths.systemAdminAdministratorCreate,
    element: <CreateSystemAdminAdministratorPage />,
  },
  {
    key: 'systemAdminAdministratorDetail',
    path: paths.systemAdminAdministratorDetail,
    element: <SystemAdminAdministratorDetailPage />,
  },
  {
    key: 'systemAdminApplicationCreate',
    path: paths.systemAdminApplicationCreate,
    element: <CreateSystemAdminApplicationPage />,
  },
  {
    key: 'systemAdminApplicationManage',
    path: paths.systemAdminApplicationManage,
    element: <ManageApplication />,
  },
  {
    key: 'systemAdmin',
    path: paths.systemAdmin,
    element: <SystemAdminPage />,
  },
]

export const filterRoutePermission = (route: HoorayRoute, userPermissions: GetMyPermissionData[] = []) => {
  const routePermissionList = route.middleware?.permissionList || []

  if (routePermissionList.length === 0) {
    return true
  }

  return checkUserPermission(routePermissionList, userPermissions)
}
