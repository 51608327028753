import { useMutation } from '@apollo/client'
import { getUserTypeEndpoint } from 'utils/user'
import { appLocalAccessToken } from 'utils/localService/app'
import { READ_NOTIFICATION } from './readNotification'
import { ReadNotificactionVars, ReadNotificationAPIData } from './interface'
import type { GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'

export const useReadNotificationMutation: GraphQLServiceMutationHook<ReadNotificationAPIData, ReadNotificactionVars> = (
  options
) => {
  return useMutation(READ_NOTIFICATION, {
    context: {
      uri: getUserTypeEndpoint(appLocalAccessToken.get()),
    },
    ...options,
  })
}
