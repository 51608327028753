import { gql } from '@apollo/client'
import { orgChildren } from 'graphQL/query'

const ORG_CHILDREN = gql`
  query {
    getOrgChildren {
      message
      code
      payload {
        ${orgChildren}
      }
    }
  }
`

export default ORG_CHILDREN
