import type { FC } from 'react'

import { Tabs } from 'antd'
import { useSearchParams } from 'react-router-dom'

import ApplicationRolePermissionServiceTab from './ApplicationRolePermissionServiceTab'

import type { Service } from 'graphQL/queryResponseTypes'

interface ApplicationRolePermissionProps {
  roleKey: string
  listServices: Service[]
}

const ApplicationRolePermission: FC<ApplicationRolePermissionProps> = ({ roleKey, listServices }) => {
  const [searchParams, setSearchParams] = useSearchParams({
    tab: 'CORE',
  })

  const serviceTab = searchParams.get('tab')

  return (
    <Tabs
      activeKey={serviceTab || ''}
      onChange={(activeKey) =>
        setSearchParams({
          tab: activeKey,
        })
      }
    >
      <Tabs.TabPane tab="Core service" key="CORE">
        <ApplicationRolePermissionServiceTab roleKey={roleKey} />
      </Tabs.TabPane>

      {listServices.map((item) => (
        <Tabs.TabPane tab={item.name} key={item.serviceKey}>
          <ApplicationRolePermissionServiceTab roleKey={roleKey} service={item} />
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}

export default ApplicationRolePermission
