import { Space, Form, Button, List } from 'antd'
import Status from 'components/Status'
import { ImportOutlined } from '@ant-design/icons'

function ApplicationDetailsInfo(): JSX.Element {
  const pic = [
    {
      key: '1',
      data: (
        <img
          alt="example"
          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
          width="auto"
          height="auto"
          margin-left="auto"
          margin-right="auto"
        />
      ),
    },
  ]
  const data = [
    {
      key: '1',
      name: 'ID',
      data: 'serailId0-9',
    },
    {
      key: '2',
      name: 'Name',
      data: 'Hooray Application',
    },
    {
      key: '4',
      name: 'Application Key',
      data: '51krur5o3gFf8niEydhP7EZihbjgtHUC',
    },
    {
      key: '5',
      name: 'Application URL',
      data: 'http://hooray.site.com',
    },
    {
      key: '6',
      name: 'Status',
      data: <Status key="status" status="VERIFIED" />,
    },
  ]
  return (
    <Form
      name="coreservicedetails"
      labelAlign="left"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 12 }}
      className="core-servicedetails-form"
    >
      <Space direction="vertical" size={16}>
        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={pic}
          renderItem={(render) => (
            <List.Item>
              <div />
              <div>{render.data}</div>
              <div />
            </List.Item>
          )}
        />
        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(render) => (
            <List.Item>
              <div>{render.name}</div>
              <div>{render.data}</div>
            </List.Item>
          )}
        />

        <Form.Item name="btnupdate">
          <Button icon={<ImportOutlined rotate={270} style={{ fontSize: '18px' }} />}>
            UPDATE APPLICATION TO SERVICE
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}

export default ApplicationDetailsInfo
