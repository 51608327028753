export const paginationQuery = `
  limit
  page
  totalItems
  totalPages
`

export const profileQuery = `
  email {
    value
  }
  phone {
    code
    value
  }
  attribute
`

export const appQuery = `
  name
  appKey
  attribute
  status
  contactEmailList
`

export const serviceQuery = `
  name
  serviceKey
  permissionList {
    name
    permissionKey
    userType
    defaultCode
    scopeList
  }
  adminPanelMetaDataUrl
  adminPanelEndpoint
  adminWidgetMetaDataUrl
  endpoint {
    gql {
      system
      resourceAdmin
      resourceOwner
    }
    rest {
      system
      resourceAdmin
      resourceOwner
    }
  }
`

export const orgChildren = `
  orgKey
  name
  path
  children
  title
  key
`
