import React, { useRef } from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Modal, Row, Select, Tooltip } from 'antd'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'

import useGetInviteRole from 'graphQL/useGetInviteRole'
import { InviteRoleAPIPayload, Role } from 'graphQL/useGetInviteRole/interface'
import styled from 'styled-components'

interface InviteForm {
  members: [{ email: string; roleKey: string }]
}
interface InvitationMemberModalProps {
  visible: boolean
  orgKey: string
  loading?: boolean
  onCancel?: () => void
  onInviteMember?: (values: InviteForm) => void
}

const maximumInvitationMembers = 10

const InvitationMemberModal: React.FC<InvitationMemberModalProps> = ({
  visible,
  orgKey,
  loading,
  onInviteMember,
  onCancel,
}) => {
  const { t } = useTranslation('invitationModal')

  const memberTotalRef = useRef(0)

  const inviteRoleQuery = useGetInviteRole({
    variables: {
      orgKey,
    },
  })

  const inviteRoleData: InviteRoleAPIPayload | null = get(inviteRoleQuery, 'data.getInviteRole.payload', null)
  const roleListData: Role[] = inviteRoleData?.roleList || []

  const [form] = Form.useForm<InviteForm>()

  const selectOptions = roleListData.map((item) => ({
    label: item.name,
    value: item.roleKey,
  }))

  const ruleRequired = {
    required: true,
    message: t('global:requiredText'),
  }

  return (
    <Modal
      title={t('modalTitle')}
      visible={visible}
      onCancel={() => {
        onCancel?.()
        memberTotalRef.current = 0
      }}
      footer={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          const { members } = values

          form.resetFields()

          onInviteMember?.({ members })
        }}
      >
        <Form.List name="members">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <Row key={key} gutter={32}>
                  <Col span={10}>
                    <Form.Item
                      label={t('label.email')}
                      name={[name, 'email']}
                      rules={[ruleRequired]}
                      style={{ marginBottom: 0 }}
                    >
                      <Input placeholder="อีเมล" />
                    </Form.Item>
                  </Col>

                  <Col span={10}>
                    <Form.Item label={t('label.roleKey')} name={[name, 'roleKey']} rules={[ruleRequired]}>
                      <Select placeholder={t('placeholder.roleKey')} options={selectOptions} />
                    </Form.Item>
                  </Col>

                  <Col span={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Tooltip title={t('deleteTooltip')}>
                      <MinusCircleOutlined
                        style={{ fontSize: 24 }}
                        onClick={() => {
                          remove(name)
                          memberTotalRef.current -= 1
                        }}
                      />
                    </Tooltip>
                  </Col>

                  <RowSeparator />
                </Row>
              ))}
              <Form.Item hidden={memberTotalRef.current === maximumInvitationMembers}>
                <Button
                  type="dashed"
                  onClick={() => {
                    add()
                    memberTotalRef.current += 1
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  เพิ่มอีเมล
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" block htmlType="submit" loading={loading}>
            {t('button')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default InvitationMemberModal

const RowSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: #eee;
  margin: 12px 18px;
`
