import { useState } from 'react'

import useGetAppByCredential from 'graphQL/useGetAppByCredential'
import useGetCredentialByHost from 'graphQL/useGetCredentialByHost'

import useQueryString from './useQueryString'

import { authorizationKey, credentialApplicationSignIn, signIn } from 'config'

import { getLocationSearch } from 'helpers/utils'
import { getAppDataFromStorage } from 'utils/app'
import { appLocalApp } from 'utils/localService'

import type { CredentialByHostAPIPayload } from 'graphQL/useGetCredentialByHost/interface'

const useGenerateOAuthLink = () => {
  const query = useQueryString()
  const credential = query.get(authorizationKey.credential) || getAppDataFromStorage()?.credential?.credentialKey

  const shouldSkip = credential == null

  const [isPrepareApplicationInfo, setIsPrepareApplicationInfo] = useState(!shouldSkip)
  const [oauthLink, setOAuthLink] = useState<string | undefined>('')

  const credentialByHostQuery = useGetCredentialByHost({
    variables: {
      host: window.location.host,
    },
  })

  const credentialKey = credential || credentialByHostQuery.data?.getCredentialByHost.payload.credential.credentialKey

  const generateOAuthLink = (applicationInfo?: CredentialByHostAPIPayload) => {
    const redirectUrl = `${window.location.protocol}//${window.location.host}/oauth`
    if (applicationInfo) {
      setOAuthLink(
        `${credentialApplicationSignIn}?${authorizationKey.clientID}=${applicationInfo.app.appKey}&${authorizationKey.credential}=${applicationInfo.credential.credentialKey}&${authorizationKey.authType}=AUTH_CODE&${authorizationKey.redirectUrl}=${redirectUrl}`
      )
    } else {
      setOAuthLink(
        `${signIn}?auth_type=AUTH_CODE&redirect_url=${redirectUrl}&${authorizationKey.credential}=${credentialKey}`
      )
    }

    setIsPrepareApplicationInfo(false)
  }

  useGetAppByCredential({
    skip: credentialByHostQuery.data == null,
    variables: {
      credentialKey: String(credentialKey),
    },
    onCompleted(resp) {
      appLocalApp.set(JSON.stringify(resp.getAppByCredential.payload))
      generateOAuthLink(resp.getAppByCredential.payload)
    },
    onError() {
      generateOAuthLink()
    },
  })

  return {
    loading: isPrepareApplicationInfo,
    url: oauthLink,
    query: getQueryString(),
  }

  function getQueryString() {
    const toLocationSearch = getLocationSearch(oauthLink)

    const query = new URLSearchParams(toLocationSearch)

    return Object.fromEntries(query.entries())
  }
}

export default useGenerateOAuthLink
