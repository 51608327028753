import type { FC } from 'react'

import SystemAdminLayout from 'layouts/SystemAdminLayout'

import TabLayout from 'components/TabLayout'
import ApplicationDetails from './ApplicationDetails'
import ManageCredentials from '../Credentials/ManageCredentials'
import ManageLogs from '../Logs/ManageLogs'
import ManageSetting from '../Setting/ManageSetting'
import ManageServices from '../ServicesSystemAdmin/ManageServices'

import type { Tab } from 'components/interface'

const tabs: Tab[] = [
  {
    title: 'Details',
    key: '1',
    component: <ApplicationDetails />,
  },
  {
    title: 'Services',
    key: '3',
    component: <ManageServices />,
  },
  {
    title: 'Credentials',
    key: '4',
    component: <ManageCredentials />,
  },
  {
    title: 'Settings',
    key: '6',
    component: <ManageSetting />,
  },
  {
    title: 'Logs',
    key: '7',
    component: <ManageLogs />,
  },
]

const ManageApplication: FC = () => {
  return (
    <SystemAdminLayout>
      <div className="admin-content-container">
        <TabLayout tabs={tabs} title="Manage Application" />
      </div>
    </SystemAdminLayout>
  )
}

export default ManageApplication
