import { useMutation } from '@apollo/client'
import { message } from 'antd'

import MUTATION_DOCUMENT from './deleteOrg'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'

interface DeleteOrg {
  // TODO: add response type
  deleteOrg: APIPayloadResponse<{ _id: string }>
}

interface DeleteOrgVars {
  orgKey: string
}

const useDeleteOrg: GraphQLServiceMutationHook<DeleteOrg, DeleteOrgVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      message.error(error.message)
      console.log('[UseDeleteOrg]:', error.message)
    },
    ...options,
  })
}

export default useDeleteOrg
