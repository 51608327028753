import { CoreServiceDashBoard } from 'components/Dashboard/Service/index'

function ServicePermissionForm(): JSX.Element {
  const dataSource = [
    {
      key: 'usecanlogin',
      permissionkey: 'USER_CAN_LOGIN',
      description: 'User can login',
      status: 'Update',
    },
    {
      key: 'userisadmin',
      permissionkey: 'USER_IS_ADMIN',
      description: 'User is type admin, can use admin function',
      status: 'Update',
    },
  ]
  return <CoreServiceDashBoard dataSource={dataSource} dataIndex="permissionkey" title="Permission Key" />
}

export default ServicePermissionForm
