import { gql } from '@apollo/client'

const ALL_ORGANIZATION = gql`
  query getAllOrganization($input: INPUT_FIND_DATA) {
    getAllOrganization(input: $input) {
      pagination {
        limit
        page
        totalItems
        totalPages
      }
      message
      code
      payload {
        name
        orgKey
      }
    }
  }
`

export default ALL_ORGANIZATION
