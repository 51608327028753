import { gql } from '@apollo/client'

const GET_CUSTOM_MENU = gql`
  query {
    getCustomMenu {
      message
      code
      payload {
        _id
        appKey
        menuKey
        permissionList {
          permissionKey
          code
        }
        status
        title
        icon
        url
        target
        actionColor
      }
    }
  }
`

export default GET_CUSTOM_MENU
