import jwtDecode from 'jwt-decode'

import type { ITokenPayload, TokenParam, UserType } from './interface'

export function decodeToken<T = ITokenPayload>(accessToken: TokenParam): T {
  if (accessToken == null) {
    return {} as T
  }

  const decode = jwtDecode<T>(accessToken)

  return decode
}

export function checkTokenExpired(token: TokenParam): boolean {
  let isExpired = true

  if (token == null) {
    return isExpired
  }

  try {
    const tokenPayload = decodeToken<ITokenPayload>(token)

    if (!tokenPayload.exp || Date.now() < tokenPayload.exp * 1000) isExpired = false

    return isExpired
  } catch {
    return isExpired
  }
}

export function getUserType(token: TokenParam): UserType | null {
  if (token == null) {
    return null
  }

  const tokenPayload = decodeToken(token)

  return tokenPayload.userType
}
