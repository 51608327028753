import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Space } from 'antd'

import type { IForm } from '../types'

const { Option } = Select

function NewAdministratorForm({ onFinish, loading }: IForm): JSX.Element {
  return (
    <Form
      name="login"
      onFinish={onFinish}
      labelAlign="left"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 12 }}
      requiredMark={false}
    >
      <Space direction="vertical" size={16}>
        <Form.Item
          label="Email"
          hasFeedback
          help="Display email"
          name="email"
          rules={[{ required: true, message: 'Email is required!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Select" help="Administrator Role">
          <Form.Item name="role" initialValue="role1" noStyle>
            <Select>
              <Option value="role1">Role1</Option>
              <Option value="role2">Role2</Option>
              <Option value="role3">Role3</Option>
              <Option value="role4">Role4</Option>
            </Select>
          </Form.Item>
          <Space size={8} direction="vertical">
            <span className="gray-color">Manage booking: EDIT</span>
            <span className="gray-color">Lock unit: READ</span>
            <span className="gray-color">Manage unit: READ</span>
            <span className="gray-color">Manage admin: NO</span>
            <span className="gray-color">Setting: READ</span>
          </Space>
        </Form.Item>

        <Form.Item label="Select" name="status" initialValue="active" help="Administrator Status">
          <Select>
            <Option value="active">
              <Space size={4}>
                <CheckCircleOutlined className="primary-color" /> <span className="primary-color">Enable</span>
              </Space>
            </Option>
            <Option value="deactive">
              <Space size={4}>
                <CloseCircleOutlined className="danger-color" /> <span className="danger-color">Disable</span>
              </Space>
            </Option>
          </Select>
        </Form.Item>

        <Form.Item className="mb-0" wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Invite administrator
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}

export default NewAdministratorForm
