import { FormProps } from 'antd'
import { DropDownFitlerType, getColumnSearchProps } from 'hooray-table'

export const commonFormProps: FormProps = {
  layout: 'vertical',
  colon: true,
}

export const onFilterSelect = (input: string, option: any) =>
  (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0

export const getColumnSearchPropsByDataIndex = (
  dataIndex: string,
  setDropDownFilterType: (filterType: DropDownFitlerType) => void
) =>
  getColumnSearchProps({
    dataIndex,
    locale: 'thTH',
    setDropDownFilterType,
  })
