import { ApolloClient, InMemoryCache } from '@apollo/client'
import { loader } from 'graphql.macro'

import { appLocalAccessToken, appLocalLocale, appLocalRefreshToken, clearAppLocal } from 'utils/localService'

import { getAppDataFromStorage } from 'utils/app'

import { apiPathV2 } from 'constants/api'

import { checkTokenExpired } from 'utils/token'

import apolloClient from './apolloClient'

import type { AuthToken } from 'contexts/useAuthContext'
import type { RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables } from 'graphQL/generated/operations'

const MUTATION_DOCUMENT = loader('../../graphQL/documents/refreshAccessToken.graphql')

const client = new ApolloClient({
  uri: apiPathV2.core.admin,
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

export const getToken = () =>
  new Promise<AuthToken | undefined>((resolve) => {
    const refreshToken = appLocalRefreshToken.get()
    const accessToken = appLocalAccessToken.get()

    if (refreshToken == null || accessToken == null) {
      resolve(undefined)
      return
    }

    const defaultToken: AuthToken = {
      accessToken,
      refreshToken: '',
    }

    if (!checkTokenExpired(accessToken)) {
      resolve(defaultToken)
      return
    }

    return client
      .mutate<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>({
        mutation: MUTATION_DOCUMENT,
        variables: {
          refreshToken,
        },
      })
      .then(({ data }) => {
        const newToken = data?.refreshAccessToken.payload

        if (newToken == null) {
          resolve(undefined)
          return
        }

        appLocalAccessToken.set(newToken.accessToken)
        appLocalRefreshToken.set(newToken.refreshToken)

        resolve(newToken)
      })
      .catch(() => {
        clearAppLocal()
        apolloClient.resetStore()

        window.location.reload()
      })
  })

export const getHeaders = (defaultHeaders: any, newAccessToken?: string) => {
  const credentialKey = getAppDataFromStorage()?.credential.credentialKey
  const localeCode = appLocalLocale.get()
  const accessToken = appLocalAccessToken.get()

  const resultHeaders = {
    locale: localeCode === 'enUS' ? 'en' : 'th',
    authorization: newAccessToken != null ? `Bearer ${newAccessToken}` : accessToken,
    ...defaultHeaders,
  }

  if (credentialKey == null) {
    return resultHeaders
  }

  return {
    credentialKey,
    ...resultHeaders,
  }
}
