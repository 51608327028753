import { useRef, useState } from 'react'
import unionBy from 'lodash/unionBy'
import debounce from 'lodash/debounce'

import useGetAllOrganizationForSearch, {
  fetchAllOrganizations,
  AllOrganizationForSearchInput,
} from 'graphQL/useGetAllOrganizationForSearch'

import type { ServiceHook } from './interface'

interface AutoCompleteOption {
  label: string
  value: string
}

interface OrganizationAutoCompleteV2Options {
  searchLength?: number
  orgKey?: string
  onQueryComplete?: VoidFunction
}

interface OrganizationAutoCompleteV2Response {
  loading?: boolean
  options: AutoCompleteOption[]
  searchKeyword: string
  searchLength: number
  resetSearch: VoidFunction
  onSearch: (value: string) => void
}

const resultLimit = 5

const defaultOptions = {
  searchLength: 3,
}

const useOrganizationAutoCompleteV2: ServiceHook<
  OrganizationAutoCompleteV2Response,
  OrganizationAutoCompleteV2Options
> = (options) => {
  const op = {
    ...defaultOptions,
    ...(options ?? {}),
  }

  const [loading, setLoading] = useState(false)
  const [selectOptions, setSelectOptions] = useState<AutoCompleteOption[]>([])
  const [searchKeyword, setSearchKeyword] = useState('')

  const fetchRef = useRef(0)

  const organizationQuery = useGetAllOrganizationForSearch({
    variables: {
      input: {
        query: {
          orgKey: op.orgKey,
        },
      },
    },
    onCompleted() {
      if (typeof op.onQueryComplete === 'function') {
        op.onQueryComplete()
      }
    },
  })

  const onSearch = debounce((value: string) => {
    setSearchKeyword(value)

    if (value.length < op.searchLength) {
      return
    }

    fetchRef.current += 1
    const fetchId = fetchRef.current

    setSelectOptions([])
    setLoading(true)

    const fetchVariables: AllOrganizationForSearchInput = {
      input: {
        pagination: {
          limit: resultLimit,
        },
        search: {
          name: value,
        },
      },
    }

    fetchAllOrganizations(fetchVariables).then(({ data }) => {
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return
      }

      const listOrganizations = data.getAllOrganization.payload

      const selectOptions: AutoCompleteOption[] = listOrganizations.map((item) => ({
        label: item.name,
        value: item.orgKey,
      }))

      setSelectOptions(selectOptions)
      setLoading(false)
    })
  }, 500)

  const organization = organizationQuery.data?.getAllOrganization.payload[0]

  const isLoading = loading || organizationQuery.loading

  return {
    loading: isLoading,
    options: mappedSelectOptions(),
    searchKeyword,
    searchLength: op.searchLength,
    resetSearch: () => setSearchKeyword(''),
    onSearch,
  }

  function mappedSelectOptions(): AutoCompleteOption[] {
    if (isLoading) {
      return []
    }

    const existedProductOptions =
      op?.orgKey && searchKeyword === ''
        ? [
            {
              label: organization?.name as string,
              value: organization?.orgKey as string,
            },
          ]
        : []

    return unionBy(selectOptions, existedProductOptions, 'value')
  }
}

export default useOrganizationAutoCompleteV2
