import type { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import ContentCard from 'components/Card/Content'
import { MemberLogDashboard } from 'components/Dashboard/Member'
import SearchInput from 'components/Input/Search'
import PageTitle from 'components/PageTitle'

import InnerOrganizationLayout from 'layouts/InnerOrganizationLayout'

const OrganizationMemberLog: FC = () => {
  const navigate = useNavigate()

  function onSearch(key: string) {
    console.log(key)
  }

  return (
    <InnerOrganizationLayout>
      <div className="admin-content-container">
        <PageTitle title="Administrator Log" onBack={() => navigate(-1)} />
        <ContentCard title="Administrator Log Overview" rightComponent={<SearchInput onSearch={onSearch} />}>
          <MemberLogDashboard />
        </ContentCard>
      </div>
    </InnerOrganizationLayout>
  )
}

export default OrganizationMemberLog
