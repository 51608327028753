import { CheckCircleOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Space } from 'antd'
// import { useState } from 'react'

import { IRoleForm } from './types'

const { Option } = Select

function RoleFrom({ onFinish, loading, data, form }: IRoleForm): JSX.Element {
  return (
    <Form
      name="login"
      onFinish={onFinish}
      labelAlign="left"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      initialValues={data}
      form={form}
    >
      <Space direction="vertical" size={16}>
        <Form.Item label="Name" help="Role name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Role Key" help="Role key reference" name="roleKey">
          <Input />
        </Form.Item>
        <Form.Item label="Default Role" help="Yes or No" name="isDefault">
          <Select>
            <Option value="YES">
              <Space size={4}>
                <CheckCircleOutlined className="success-color" /> <span className="success-color">Yes</span>
              </Space>
            </Option>
            <Option value="NO">
              <Space size={4}>
                <CloseCircleOutlined className="danger-color" /> <span className="danger-color">No</span>
              </Space>
            </Option>
          </Select>
        </Form.Item>
        <Form.Item label="Replaceable" help="Yes or No" name="isReplaceable">
          <Select>
            <Option value="YES">
              <Space size={4}>
                <CheckCircleOutlined className="success-color" /> <span className="success-color">Yes</span>
              </Space>
            </Option>
            <Option value="NO">
              <Space size={4}>
                <CloseCircleOutlined className="danger-color" /> <span className="danger-color">No</span>
              </Space>
            </Option>
          </Select>
        </Form.Item>
        <Form.Item label="Regisable" help="Yes or No" name="regisable">
          <Select>
            <Option value="YES">
              <Space size={4}>
                <CheckCircleOutlined className="success-color" /> <span className="success-color">Yes</span>
              </Space>
            </Option>
            <Option value="NO">
              <Space size={4}>
                <CloseCircleOutlined className="danger-color" /> <span className="danger-color">No</span>
              </Space>
            </Option>
          </Select>
        </Form.Item>
        <Form.Item label="Status" help="Credential status" name="status">
          <Select>
            <Option value="ACTIVE">
              <Space size={4}>
                <CheckCircleOutlined className="success-color" /> <span className="success-color">Enable</span>
              </Space>
            </Option>
            <Option value="INACTIVE">
              <Space size={4}>
                <CloseCircleOutlined className="danger-color" /> <span className="danger-color">Disable</span>
              </Space>
            </Option>
          </Select>
        </Form.Item>
        <Form.Item label="Role type" help="Role Type" name="roleType">
          <Select>
            <Option value="RESOURCE_ADMIN">
              <Space size={4}>Resource Admin</Space>
            </Option>
            <Option value="RESOURCE_OWNER">
              <Space size={4}>Resource Owner</Space>
            </Option>
          </Select>
        </Form.Item>
        <Form.Item label="Is invite" help="Yes or No" name="isInvite">
          <Select>
            <Option value="YES">
              <Space size={4}>
                <CheckCircleOutlined className="success-color" /> <span className="success-color">Yes</span>
              </Space>
            </Option>
            <Option value="NO">
              <Space size={4}>
                <CloseCircleOutlined className="danger-color" /> <span className="danger-color">No</span>
              </Space>
            </Option>
          </Select>
        </Form.Item>
        <Form.Item className="mb-0" wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" loading={loading} icon={<SaveOutlined />}>
            Submit
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}

export default RoleFrom
