import { gql } from '@apollo/client'

const MEMBER_ORGANIZATION = gql`
  query getMemberOrganization($orgKey: String!, $input: INPUT_FIND_DATA) {
    getMemberOrganization(orgKey: $orgKey, input: $input) {
      payload {
        _id
        email {
          value
          verifyStatus
        }
        phone {
          code
          value
          verifyStatus
        }
        username
        facebookId
        googleId
        lineId
        appleId
        attribute
        setting
        role {
          name
          roleType
          roleKey
          status
          isDefault
          isReplaceable
        }
      }
    }
  }
`

export default MEMBER_ORGANIZATION
