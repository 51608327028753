import { useMutation } from '@apollo/client'
import { onDefaultErrorMessage } from 'utils/apollo'
import LOGOUT_SYSTEM from './logOutUser'
import { LogOutAPIPayload } from './interface'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'

interface LogOutData {
  createRole: APIPayloadResponse<LogOutAPIPayload>
}

interface LogOutVars {
  userId: string
}

const useLogOut: GraphQLServiceMutationHook<LogOutData, LogOutVars> = (options) => {
  return useMutation(LOGOUT_SYSTEM, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useLogOut
