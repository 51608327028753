// import { gql } from '@apollo/client'
export const ROLE = `
    app {
        name
    }
  roleList {
    name
    attribute
    userField
    regisable
    roleType
    roleKey
    status
    isDefault
    isReplaceable
    isInvite
  }
`
export const PERMISSION = `
  name
  permissionKey
  code
`
