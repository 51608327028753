import { Col, Divider, Modal, Row } from 'antd'

import { ILogDetailProps } from '../types'
import LogJSONData from './LogJSONData'

const prevData = {
  attributes: [
    {
      key: 'logo',
      value: 'https://www.s3.com',
    },
  ],
}

const currentData = {
  attributes: [
    {
      key: 'logo',
      value: 'https://www.s3.com',
    },
  ],
}

function LogDetail({ isVisible, onCancel }: ILogDetailProps): JSX.Element {
  return (
    <Modal
      width="50%"
      title={<span className="font-bold">Log Detail</span>}
      visible={isVisible}
      onOk={() => null}
      onCancel={onCancel}
      footer={false}
    >
      <div>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <span className="font-bold">Service:</span> Hooray Core Service
          </Col>
          <Col span={12}>
            <span className="font-bold">Application:</span> cpdthai.com
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <span className="font-bold">Function:</span> getApplication (Version 1.1)
          </Col>
          <Col span={12}>
            <span className="font-bold">Action:</span> Update
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <span className="font-bold">IP Address:</span> 192.168.1.1
          </Col>
          <Col span={12}>
            <span className="font-bold">User:</span> Komphet Meesab
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <span className="font-bold">Timestamp:</span> 16/09/2020 | 22:34:12
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <span className="font-bold">User Agent:</span> Mozilla/5.0 (Windows NT 6.1; Win64; x64; rv:47.0)
            Gecko/20100101
          </Col>
        </Row>
      </div>
      <Divider />
      <LogJSONData prevData={prevData} currentData={currentData} />
    </Modal>
  )
}

export default LogDetail
