import { gql } from '@apollo/client'

const INVITE_USER_ORGANIZATION = gql`
  query getInviteUserOrganization($orgKey: String!, $input: INPUT_FIND_DATA) {
    getInviteUserOrganization(orgKey: $orgKey, input: $input) {
      payload {
        _id
        userId
        status
        email
        registered
      }
    }
  }
`

export default INVITE_USER_ORGANIZATION
