import type { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import SystemAdminLayout from 'layouts/SystemAdminLayout'

import ContentCard from 'components/Card/Content'
import NewSystemAdministratorForm from 'components/Form/Administrator/NewSystemAdministratorForm'
import PageTitle from 'components/PageTitle'
import { NewAdminDetails } from 'components/Form/Administrator'
import { useTranslation } from 'react-i18next'

const CreateSystemAdminAdministratorPage: FC = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  function onFinish(values: any) {
    console.log(values)
  }

  return (
    <SystemAdminLayout>
      <div className="admin-content-container">
        <>
          <PageTitle title={t('administrator:newAdmin')} onBack={() => navigate(-1)} />
          <ContentCard title={t('administrator:newAdmin')}>
            <NewSystemAdministratorForm onFinish={onFinish} />
            <NewAdminDetails />
          </ContentCard>
        </>
      </div>
    </SystemAdminLayout>
  )
}

export default CreateSystemAdminAdministratorPage
