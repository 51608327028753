import { FC } from 'react'
import { FormOutlined } from '@ant-design/icons'
import { Card, Col, Row } from 'antd'

import ApplicationDetailsAction from './ApplicationDetailsAction'
import ApplicationDetailsInfo from './ApplicationDetailsInfo'

const ApplicationDetails: FC = () => {
  return (
    <Row gutter={20}>
      <Col span={10}>
        <Card title="Information" extra={<FormOutlined />}>
          <ApplicationDetailsInfo />
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Other actions">
          <ApplicationDetailsAction />
        </Card>
      </Col>
    </Row>
  )
}

export default ApplicationDetails
