import { LogDetailModal } from 'components/Modal/Administrator'
import AdministratorTable from 'components/Table/Administrator'
import TotalDataSourceText from 'components/Table/TotalDataSourceText'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { useState } from 'react'

function SystemAdministratorLog(): JSX.Element {
  const [isLogVisible, setLogVisible] = useState(false)
  const [logKey, setLogKey] = useState('')

  function onShowLog(key: string) {
    setLogVisible(!isLogVisible)
    setLogKey(key)
  }

  function onCancel() {
    setLogVisible(false)
  }

  const dataSource: any[] = [
    {
      _id: '1',
      action: 'Update',
      date: '2021-06-08T15:11:43.181Z',
      function: 'getApplication (Version 1.1)',
      ip: '192.168.1.1',
      service: 'Hooray Core Service',
    },
    {
      _id: '2',
      action: 'Update',
      date: '2021-06-02T10:01:25.181Z',
      function: 'getApplication (Version 1.1)',
      ip: '192.168.1.1',
      service: 'Hooray Core Service',
    },
    {
      _id: '3',
      action: 'Update',
      date: '2021-06-01T01:10:13.581Z',
      function: 'getApplication (Version 1.1)',
      ip: '192.168.1.1',
      service: 'Hooray Core Service',
    },
  ]

  const columns: ColumnsType<any> = [
    {
      title: () => <strong>Function</strong>,
      dataIndex: 'function',
    },
    {
      title: () => <strong>Action</strong>,
      dataIndex: 'action',
    },
    {
      title: () => <strong>IP Address</strong>,
      dataIndex: 'ip',
    },
    {
      title: () => <strong>Timestamp</strong>,
      dataIndex: 'date',
      render: (date) => <span>{dayjs(date).format('DD/MM/YYYY | HH:mm:ss')}</span>,
    },
    {
      title: () => <strong>Service</strong>,
      dataIndex: 'service',
      render: (serviceName) => <a href="#system-admin-service">{serviceName}</a>,
    },
    {
      dataIndex: 'administratorLogAction',
      render: (_text, record) => {
        const isLogDetailShow = logKey === record._id && isLogVisible
        return (
          <Space size={12} direction="vertical">
            <Button type="link" icon={<ArrowRightOutlined />} onClick={() => onShowLog(record._id)}>
              Detail
            </Button>
            <LogDetailModal isVisible={isLogDetailShow} onCancel={onCancel} />
          </Space>
        )
      },
    },
  ]

  return (
    <>
      <AdministratorTable<any> dataSource={dataSource} columns={columns} rowKey="_id" />
      <TotalDataSourceText total={dataSource.length} />
    </>
  )
}

export default SystemAdministratorLog
