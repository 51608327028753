import { useState } from 'react'
import { Button, Space, Table } from 'antd'
import { CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import SearchInput from 'components/Input/Search'
import ManageCredentialModal from './ManageCredentialModal'

function ManageCredentials() {
  const [openManage, setOpenManage] = useState(false)

  const onCloseManage = () => {
    setOpenManage(false)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Credential Key',
      dataIndex: 'credentialKey',
      key: 'credentialKey',
    },
    {
      title: 'Request',
      dataIndex: 'request',
      key: 'request',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_text: any, render: any) => (
        <span key="status-render" className="service-status">
          <Space size={5} style={{ color: 'green', fontSize: '15px' }}>
            <CheckCircleOutlined />
            {render.status}
          </Space>
        </span>
      ),
    },
  ]

  const data = [
    {
      key: '1',
      name: 'Development',
      status: 'Active',
      credentialKey: 'GMI7iBbvQ9BNXRST4fZOMVOIR2lBadUu',
      request: 367,
    },
    {
      key: '2',
      name: 'Production',
      status: 'Active',
      credentialKey: 'GRAh2xOmCL4o8obsFDHTXvJ8byTksZPk',
      request: 20,
    },
  ]
  return (
    <>
      <TopTableWrapper>
        <SearchWrapper>
          <SearchInput onSearch={() => {}} placeholder="Search" />
        </SearchWrapper>
        <Button
          type="primary"
          key="create"
          onClick={() => {
            setOpenManage(true)
          }}
        >
          <Space size={12}>
            <PlusCircleOutlined /> New Credentails
          </Space>
        </Button>
      </TopTableWrapper>
      <Table dataSource={data} columns={columns} />
      <ManageCredentialModal visible={openManage} onClose={onCloseManage} />
    </>
  )
}

const TopTableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const SearchWrapper = styled.div`
  width: 40%;
`

export default ManageCredentials
